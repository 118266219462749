/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum StatutRevatua {
    saisie = 'saisie',
    officialise = 'officialise',
    embarque = 'embarque',
    embarquemenTRefuse = 'embarquemenT_REFUSE',
    annule = 'annule',
    modification = 'modification',
    transfert = 'transfert',
    brouillon = 'brouillon',
    demande = 'demande',
    demandERefusee = 'demandE_REFUSEE',
    officialisESousReserve = 'officialisE_SOUS_RESERVE',
    priSEnCharge = 'priS_EN_CHARGE'
};

