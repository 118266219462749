<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
    >
    </dxi-item>

    <dxi-item *ngIf="anyPartenaires; else titrePartenaireSeul;" location="before">
      <div *dxTemplate>
        <dx-select-box
          [width]="300"
          [dataSource]="partenaires"
          [value]="selectedPartenaireId"
          valueExpr="id"
          displayExpr="intitule"
          (onValueChanged)="changerPartenaire($event)"
        ></dx-select-box>
      </div>
    </dxi-item>

    <ng-template #titrePartenaireSeul>
      <dxi-item
        location="before"
        cssClass="header-title"
        [text]="currentClient?.intitule"
      ></dxi-item>
    </ng-template>

    <dxi-item
      *ngFor="let option of options"
      location="before"
      widget="dxButton"
      [options]="option"
    >
    </dxi-item>

    <dxi-item
      location="center"
      cssClass="header-title"
      [text]="title"
    ></dxi-item>

    <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button
          class="user-button authorization"
          width="210px"
          height="100%"
          stylingMode="text"
        >
          <div *dxTemplate="let data of 'content'">
            <app-user-panel
              [user]="user"
              [menuItems]="userMenuItems"
              menuMode="context"
            ></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>

    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel
        [user]="user"
        [menuItems]="userMenuItems"
        menuMode="list"
      ></app-user-panel>
    </div>

    <dxi-item
      location="after"
      locateInMenu="auto"
      cssClass="error-warning"
      template="contactsEnAttentes"
      *ngIf="contactsEnAttentes.length > 0"
    >
      <div *dxTemplate="let data of 'contactsEnAttentes'">
        <dx-button icon="dx-icon-warning" (onClick)='togglePopupContactsEnAttentes()'>
          <i class="dx-icon-warning" style="color: rgb(251, 178, 7)"></i></dx-button>
      </div>
    </dxi-item>

  </dx-toolbar>
</header>


<!-- Popup contacts en attentes -->
<dx-popup
  [(visible)]="showPopupContactsEnAttentes"
  [width]="500"
  [height]="500"
  [showTitle]="true"
  [dragEnabled]="false"
  [showCloseButton]="true"
  title="Accepter la demande d'association ?"
  container=".dx-viewport"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <dx-data-grid
        id="gridContainer"
        [dataSource]="contactsEnAttentes"
        [allowColumnReordering]="false"
        [columnAutoWidth]="true"
        [allowColumnResizing]="false"
        [columnHidingEnabled]="false"
      >
        <dxi-column dataField="partenaireIntitule" caption="Partenaire"></dxi-column>

        <dxi-column cellTemplate="validerTemplate">
          <div *dxTemplate="let cell of 'validerTemplate'" (click)="onValidate(cell)">
            <dx-button icon="check" hint="Valider"></dx-button>
          </div>
        </dxi-column>

        <dxi-column cellTemplate="deleteTemplate">
          <div *dxTemplate="let cell of 'deleteTemplate'" (click)="onDelete(cell)">
            <dx-button icon="close" hint="Supprimer"></dx-button>
          </div>
        </dxi-column>
      </dx-data-grid>
    </dx-scroll-view>
  </div>
</dx-popup>