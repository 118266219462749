import { Component, OnInit, ViewChild } from '@angular/core';
import { EvenementTypesLexiClient, EvenementNature, FluxStatut } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-evenement-type-list',
  templateUrl: './evenement-type-list.component.html',
  styleUrls: ['./evenement-type-list.component.scss'],
})
export class EvenementTypeListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  datasource: any;
  natures: any;
  etats: any;
  constructor(private evtService: EvenementTypesLexiClient) {
    this.etats = Object.values(FluxStatut);
    this.natures = Object.values(EvenementNature);
    this.datasource = new CustomStore( {
      load: () => lastValueFrom(this.evtService.getAll()),
      insert: (values) => {
        return lastValueFrom(this.evtService.creer(values));
      },
      update: (key, values) => {
        for (const field of Object.keys(values)) {
          key[field] = values[field];
        }
        return lastValueFrom(this.evtService.update(key.id, key));
      },
      remove: (key) => lastValueFrom(this.evtService._delete(key.id)) as any
    });
  }

  ngOnInit() {
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.datagrid.instance.addRow();
    }
  }
}
