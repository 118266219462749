import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassementDetailDto, DocumentFilterDto, DocumentLexiClient, DocumentResultDto, FileToAddDto, GedDocumentType, ObjectType } from '@lexi-clients/lexi';
import { ClassementDto, ClassementsLexiClient } from '@lexi-clients/lexi';
import { DxFormComponent, DxTreeListComponent } from 'devextreme-angular';
import { Subscription, lastValueFrom } from 'rxjs';
import { DownloadService } from '../../services/download.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ValidationResult } from 'devextreme/ui/validation_group';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../../modules/shared/references/references';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
  private subscriptions = new Subscription();

  @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;
  @ViewChild('form') form: DxFormComponent;

  readonly gedDocumentTypeDataSource: Array<{ id: GedDocumentType, libelle: string }> = [
    { id: GedDocumentType.document , libelle: "Document" },
    { id: GedDocumentType.image , libelle: "Image" },
    { id: GedDocumentType.webMedia , libelle: "Media Web" },
  ];

  // Drag
  allowDropInsideItem = true;
  allowReordering = true;
  showDragIcons = false;

  // file data of the document file
  files: File[] = [];

  // Inputs
  private _objectType: ObjectType = ObjectType.autre;
  @Input() set objectType(value: ObjectType) {
    this._objectType = value;
    this.checkInputs();
  }
  get objectType(): ObjectType {
    return this._objectType;
  }

  private _objectId: number;
  get objectId(): number {
    return this._objectId;
  }
  @Input() set objectId(value: number) {
    if (value != null && value != undefined) {
      this._objectId = value;
      this.checkInputs();
    }
  }

  // classements
  classements: ClassementDto [];
  selectedClassement: ClassementDto;

  // classementDetails
  classementDetails: ClassementDetailDto [];

  // documents
  documentFiltres: DocumentFilterDto;
  documents: DocumentResultDto [];
  selectedDocument: DocumentResultDto;

  // add document
  addFileFormData: FileToAddDto = {} as FileToAddDto;
  isAddFile: boolean = false;

  // editPopup
  updatePopupVisible: boolean = false;
  updateFileFormData: DocumentResultDto;

  // routerlink urls
  previousUrl: string;
  isQuery: boolean = false;

  constructor(
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private classementService: ClassementsLexiClient,
    private documentService: DocumentLexiClient,
    private readonly downloadService: DownloadService,
    ) { 

      // bind method to get context
      this.onReorder = this.onReorder.bind(this);

    // On conserve l'url précédent pour le bouton retour
    const nav = this.router.getCurrentNavigation();
    if (nav !== null)
    {
      this.previousUrl = nav.previousNavigation.finalUrl.toString();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(async params => {
        const id = params['id'];
        const objectType = params['objectType'];

        // Vérifier si des queryParams sont présents
        if (id && objectType) {
          // Si les queryParams sont présents, mettre à jour les valeurs
          this.isQuery = true;
          this.objectId = id;
          this.objectType = objectType;

          // Mettre à jour les documents en fonction des valeurs des paramètres
          await this.setDocuments();
          this.treeList.instance.refresh();
        } 
        else {
          // Si les queryParams ne sont pas présents, utiliser les valeurs des @Input
          this.isQuery = false;
        }
      })
    );
  }

  /**************/
  /* Drag ligne */
  /**************/
  onDragChange(e) {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.id);
    let targetNode = visibleRows[e.toIndex].node;

    while (targetNode && targetNode.data) {
      // si on n'a pas bougé
      if (targetNode.data.id === sourceNode.data.id) {
        e.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  }

  async onReorder(e) {
    const visibleRows = e.component.getVisibleRows();

    if (e.dropInsideItem)
    {
      // si la destination est aussi un document on applique le classement du parent
      if(visibleRows[e.toIndex].data.hasOwnProperty('objectId')) {
        e.itemData.classementId = visibleRows[e.toIndex].node.parent.key;
      }
      else {
        e.itemData.classementId = visibleRows[e.toIndex].key;
      }

      await lastValueFrom(this.documentService.update(e.itemData, e.itemData.id.toString()));
      notify({closeOnClick: true, message: "Classement modifié avec succès"}, NotificationType.Success);
      await this.setDocuments();
      e.component.refresh();
    } 
    else
    {
      const sourceData = e.itemData;
      const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
      let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

      if (targetData === null) {
        e.cancel = true;
      }
      else if (targetData && targetData.classementId === 0) {
        targetData = null;
        e.cancel = true;
      }
      else if (targetData && e.component.isRowExpanded(targetData.id)) {
        sourceData.classementId = Number(targetData.id);
        targetData = null;
      } 
      else {
        sourceData.classementId = Number(targetData.classementId);
      }

      await lastValueFrom(this.documentService.update(sourceData.itemData, sourceData.id.toString()));
      notify({closeOnClick: true, message: "Classement modifié avec succès"}, NotificationType.Success);
      await this.setDocuments();

    }
  }

  onDragStart = (event: any) => {
    const draggedRowData = event.itemData; // Données de la ligne en cours de drag
  
    // Vérifiez les propriétés de la ligne pour déterminer si elle peut être draguée ou non
    if (draggedRowData && draggedRowData.classementIntitule === 'treeListRootNode') {
      event.cancel = true; // Empêche le drag de la ligne
    }
  }


  /*************************************/
  /* Get Classements/Documents methods */
  /*************************************/
  async setDocuments() {
    // Get classements
    await this.setClassements();

    // Set Filters
    this.setDocumentFilters();

    // Get Documents
    this.documents = await lastValueFrom(this.documentService.rechercher(this.documentFiltres));
    this.setTreeListRootNodes();
    this.documents.sort((a, b) => a.fileName.localeCompare(b.fileName));
  }

  async setClassements () {
    this.classements = await lastValueFrom(this.classementService.getClassementForObjectType(this.objectType));
    if (this.classements.length > 0) {
      this.selectedClassement = this.classements.find(classement => classement !== null && classement !== undefined); // 1 seul classement ?
      await this.setClassementDetails();
    }
  }

  async setClassementDetails () {
    this.classementDetails = await lastValueFrom(this.classementService.getClassementDetailByClassementId(this.selectedClassement.id));
  }

  private setDocumentFilters() {
    this.documentFiltres = {
      classementId: this.selectedClassement?.id,
      objectId: this.objectId?.toString(),
      objectType: this.objectType,
    };
  }

  private setTreeListRootNodes() {
    if (typeof this.classementDetails != "undefined") {
      this.classementDetails.forEach(element => {
        let item: DocumentResultDto = {
          intitule: element.intitule,
          classementIntitule: 'treeListRootNode',
          id: element.id.toString(), // unique ID of classementDetail
          classementId: 0,
          fileName: element.intitule,
        };
        this.documents.push(item);
      });
    }

    let defaultItem: DocumentResultDto = {
      intitule: 'default',
      classementIntitule: 'treeListRootNode',
      id: "-1", // unique ID of classementDetail
      classementId: 0,
      fileName: 'Non classés',
    };

    this.documents.push(defaultItem);

    this.documents.forEach(element => {
      if(element.classementId === null) {
        element.classementId = -1;
      }
    });
  }


  /******************/
  /* Events methods */
  /******************/
  // fonction fléchée obligatoire pour avoir le contexte !!
  /* download a file */
  onDownloadDocument = async (event: any) => {

    if (event.row.key) {
      this.documentService.getContentById(event.row.key).subscribe((response: string) => {

        // response string is base64 encoded => decode to uint8Array
        const byteArray = new Uint8Array(atob(response).split('').map((char) => char.charCodeAt(0)));

        // Extract the contentType and filename with default values
        const { contentType = 'application/octet-stream', fileName = 'yourfilename' } = event.row.data;

        // Construct the fileName for the header
        const filenameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
        
        // Add http headers for download service
        const headers = new HttpHeaders({
          'Content-Disposition': `attachment; filename="${filenameWithoutExtension}"`,
        });

        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const httpResponse = new HttpResponse<Blob>({ body: blob,  headers: headers});
        this.downloadService.directDownloadFile(httpResponse, `${contentType}`);
      });
    }
  }


  /************/
  /* add file */
  onConfirmAddDocument = async (event: any) => {
    const formValidationResult: ValidationResult = this.form.instance.validate();
    if (!formValidationResult.isValid) return;
  
    const file = this.files[0];
    
    // get data as utf8Array
    const byteArray = await this.readFileAsByteArray(file);
    const uint8Array = new Uint8Array(byteArray);
    const binaryString = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
    const base64Content = btoa(binaryString);

    // set dto
    const fileToAddDto: FileToAddDto = {
      contentType: this.addFileFormData.contentType, // select BOxpdf...
      isLink: this.addFileFormData.isLink,
      officiel: this.addFileFormData.officiel,
      fileName: this.addFileFormData.fileName,
      objectId: this.objectId.toString(), // !visible
      objectType: this.objectType, // !visible
      documentType: GedDocumentType.document, // selectBox image,document
      path: this.addFileFormData.path, // !visible
      name: this.addFileFormData.name, // !visible
      classementDetailId: this.addFileFormData.classementDetailId, // selectBox 
      classementId: this.addFileFormData.classementId, // classementId de l'objectType ? = classementDetailId ?
      content: base64Content,
    };

    await lastValueFrom(this.documentService.insertFile(fileToAddDto));
    notify({closeOnClick: true, message: "Document ajouté avec succès"}, NotificationType.Success);
    await this.setDocuments();

    this.files = [];
    // empty formData
    for (let key in this.addFileFormData) {
      // Vérification si le champ est une propriété directe (et non une méthode ou une autre propriété)
      if (this.addFileFormData.hasOwnProperty(key)) {
        // Réinitialisation de la valeur du champ
        this.addFileFormData[key] = null;
      }
    }
    this.isAddFile = false;
  };
  
  // addFile has changed
  onAddFileChanged(event) {
    this.isAddFile = true;
    this.addFileFormData.fileName = this.files[0].name;
    if(this.files[0].name.includes('.')) {
      this.addFileFormData.contentType = `.${this.files[0].name.split('.').pop()}`;
    }
    else {
      this.files = [];
      this.addFileFormData.fileName = null;
      this.isAddFile = false;
      notify({closeOnClick: true, message: "Le fichier doit avoir une extension valide."}, NotificationType.Error);
    }
  }

  onAddCancel(event) {
    this.files = [];
    // empty formData
    for (let key in this.addFileFormData) {
      // Vérification si le champ est une propriété directe (et non une méthode ou une autre propriété)
      if (this.addFileFormData.hasOwnProperty(key)) {
        // Réinitialisation de la valeur du champ
        this.addFileFormData[key] = null;
      }
    }
    this.isAddFile = false;
  }


  /***************/
  /* remove file */
  onRowRemoved = async (event) => {
    await lastValueFrom(this.documentService.supprimer(event.data.id));
    notify({closeOnClick: true, message: "Document supprimé avec succès"}, NotificationType.Success);
  }


  /***************/
  /* update file */
  onConfirmUpdateDocument = async (event) => {
    const documentResultDto: DocumentResultDto = {
      id: this.updateFileFormData.id,
      contentType: this.updateFileFormData.contentType, // select BOxpdf...
      isLink: this.updateFileFormData.isLink,
      officiel: this.updateFileFormData.officiel,
      fileName: this.updateFileFormData.fileName,
      objectId: this.objectId.toString(), // !visible
      objectType: this.objectType, // !visible
      documentType: GedDocumentType.document, // selectBox
      path: this.updateFileFormData.path, // !visible
      name: this.updateFileFormData.name, // !visible
      classementId: this.updateFileFormData.classementId,
    };

    await lastValueFrom(this.documentService.update(documentResultDto, this.updateFileFormData.id.toString()));
    notify({closeOnClick: true, message: "Document modifié avec succès"}, NotificationType.Success);
    await this.setDocuments();
    this.hideUpdatePopup();
  }

  // set form popup data source
  setUpdatePopup = async (event) => {
    this.updateFileFormData = Object.assign({}, event.row.data); // copy data not reference
    // documents non-classés
    if (this.updateFileFormData.classementId < 0) {
      this.updateFileFormData.classementId = null;
    }
    this.updatePopupVisible = true;
  }
  
  // update file has changed
  onUpdateFileChanged(event) {
    this.updateFileFormData.fileName = this.files[0].name;
    if(this.files[0].name.includes('.')) {
      this.addFileFormData.contentType = `.${this.files[0].name.split('.').pop()}`;
    }
    else {
      this.files = [];
      this.addFileFormData.fileName = null;
      this.isAddFile = false;
      notify({closeOnClick: true, message: "Le fichier doit avoir une extension valide."}, NotificationType.Error);
    }
  }

  hideUpdatePopup = async () => { 
    this.updatePopupVisible = false;
    // empty file
    this.files = [];
    // empty formData
    for (let key in this.updateFileFormData) {
      // Vérification si le champ est une propriété directe (et non une méthode ou une autre propriété)
      if (this.updateFileFormData.hasOwnProperty(key)) {
        // Réinitialisation de la valeur du champ
        this.updateFileFormData[key] = null;
      }
    }
  };


  /******************/
  /* Helper methods */
  /******************/
  private async checkInputs() {
    if ((this._objectType !== ObjectType.autre) && this._objectId && this.isQuery === false ) {
      await this.setDocuments();
    }
  }

  readFileAsByteArray(file: File): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event: any) => {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      };
  
      reader.onerror = (event: any) => {
        reject(event.target.error);
      };
  
      reader.readAsArrayBuffer(file);
    });
  }

  isIconVisible(e) {
    if(e.row.data.classementIntitule === 'treeListRootNode') {
      return false;
    }
    
    return true;
  }
}

