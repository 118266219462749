import { NgModule } from '@angular/core';
import { NgxBarcode6Component } from './ngx-barcode6.component';



@NgModule({
  declarations: [
    NgxBarcode6Component
  ],
  imports: [
  ],
  exports: [
    NgxBarcode6Component
  ]
})
export class NgxBarcode6Module { }
