import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Configuration, SecurityLexiClient } from '@lexi-clients/lexi';
import { AuthModule, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map, of } from 'rxjs';
import { AuthorizationHeaderHttpInterceptor } from './authorization-header.http-interceptor';
import { OidcUaaConfig } from './oidc-uaa.config';

export function configureAuth(uaaConfig: OidcUaaConfig, securityLexiClient: SecurityLexiClient, confLexiClient: Configuration,) {
  console.log("configureAuth");
  const openIDConfiguration: OpenIdConfiguration = {};
  confLexiClient.basePath = uaaConfig.baseServiceUrl;

  // Pour application mobile avec gestion offline, l'application spécifie elle-même sa configuration openID
  if (uaaConfig.openIDConfiguration) {
    return new StsConfigHttpLoader(of(uaaConfig.openIDConfiguration));
  }

  // On récupère la configuration Open Id du serveur
  let a = securityLexiClient.getClientOpenIdConfiguration().pipe(
    map(o => {
      console.log("Configuration Open Id récupérée : ", o);
      openIDConfiguration.autoUserInfo = o.autoUserinfo;
      openIDConfiguration.clientId = o.clientId;
      if (o.customParams && o.customParams.resource) {
        openIDConfiguration.customParamsAuthRequest = {
          "resource": o.customParams.resource
        };
      }
      openIDConfiguration.forbiddenRoute = o.forbiddenRoute;
      openIDConfiguration.issValidationOff = o.issValidationOff;

      openIDConfiguration.logLevel = o.logLevel;
      openIDConfiguration.renewTimeBeforeTokenExpiresInSeconds = o.renewTimeBeforeTokenExpiresInSeconds;
      openIDConfiguration.responseType = o.responseType;
      openIDConfiguration.scope = o.scope;
      openIDConfiguration.silentRenew = o.silentRenew;
      openIDConfiguration.unauthorizedRoute = o.unauthorizedRoute;
      openIDConfiguration.useRefreshToken = o.useRefreshToken;
      openIDConfiguration.postLogoutRedirectUri = window.location.origin;
      openIDConfiguration.redirectUrl = window.location.origin;
      openIDConfiguration.authority = o.authority;
      // Point d'attention : on ne valide pas les nonces
      // https://nice-hill-002425310.azurestaticapps.net/docs/documentation/configuration#ignorenonceafterrefresh
      openIDConfiguration.ignoreNonceAfterRefresh = true;
      // Permet d'éviter le rejet de l'authentification si l'heure de l'ordinateur du client à plusieurs minutes d'avance
      // https://nice-hill-002425310.azurestaticapps.net/docs/documentation/configuration#disableiatoffsetvalidation
      openIDConfiguration.disableIatOffsetValidation = o.disableIatOffsetValidation ?? true;
      // https://nice-hill-002425310.azurestaticapps.net/docs/documentation/configuration#maxidtokeniatoffsetallowedinseconds
      openIDConfiguration.maxIdTokenIatOffsetAllowedInSeconds = o.maxIdTokenIatOffsetAllowedInSeconds ?? 300;

      // Dans le cas où le client ne peut pas faire de CORS, on passe par le service LEXI
      // pour récupérer les well known endpoints. Le service LEXI fait office de Proxy
      // A noter que dans ce cas, on surcharge l'URL de récupération des clés (jwksUri) pour passer
      // là aussi par LEXI (toujours pour le souci de CORS)
      if (o.getWellKnownOpenIdConfigurationServerUsingLexi) {
        openIDConfiguration.authWellknownEndpointUrl = confLexiClient.basePath + '/api' ;
        openIDConfiguration.silentRenewUrl = confLexiClient.basePath + '/auth/silent-renew.html';
      }
      else {
        openIDConfiguration.authWellknownEndpointUrl = o.wellKnown ?? o.authority + '/.well-known/openid-configuration';
      }
      return openIDConfiguration;
    }));

  return new StsConfigHttpLoader(a);

}

@NgModule({
  imports: [AuthModule.forRoot({
    loader: {
      // cf https://nice-hill-002425310.azurestaticapps.net/docs/documentation/configuration#load-config-from-http-async
      provide: StsConfigLoader,
      useFactory: configureAuth,
      deps: [OidcUaaConfig, SecurityLexiClient, Configuration],
    },
  })],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderHttpInterceptor,
      multi: true,
    }
  ],
})
export class OidcUaaModule {
  static forRoot(oidcUaa: OidcUaaConfig): ModuleWithProviders<OidcUaaModule> {
    return {
      ngModule: OidcUaaModule,
      providers: [
        {
          provide: OidcUaaConfig,
          useValue: oidcUaa,
        },
      ],
    };
  }
}
