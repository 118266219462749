<h2 class="content-block">Gestion des classifications</h2>

<dx-tab-panel [dataSource]="tabs" [selectedIndex]="0" [deferRendering]="false">

  <div *dxTemplate="let item of 'templateClassification'">
    <app-classification-values-list></app-classification-values-list>
  </div>

  <div *dxTemplate="let item of 'templateClassificationType'">
    <div>
      <app-classification-type-list [objectTypeKey]="objectTypeKey"></app-classification-type-list>
    </div>
  </div>

</dx-tab-panel>
