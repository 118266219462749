import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { AuthService } from '../settings/auth.service';
import { UaaService } from '@lexi/oidc-uaa';
import { OpenIdConfiguration } from "angular-auth-oidc-client";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private config: OpenIdConfiguration;

  constructor(private authService: AuthService, private uaa: UaaService) {
    this.uaa.openIdConfiguration.subscribe(x => this.config = x);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let idRequete: number = null;
    const authDomain = this.config?.authority;
    if (authDomain && req.url.startsWith(authDomain)) {
      idRequete = this.authService.addAuthRequetes();
    }

    return next.handle(req).pipe(finalize(() => {
      if (idRequete != null) {
        this.authService.removeAuthRequetes(idRequete)
      }
    }));
  }
}
