<div style="margin-bottom: 6px;">
  <dx-button *ngIf="!siteReadOnly" text="Créer" (onClick)="dataGrid?.instance?.addRow()" style="margin-right: 12px;"></dx-button>
  <dx-button text="Rafraîchir" (onClick)="setDataSource()"></dx-button>
</div>

<dx-data-grid id="magasinPieceNatureDataGrid"
  #dataGrid
  [dataSource]="magasinPieceNatureStore"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" [storageKey]="dataGridStorageKey" appNoFilterStorage></dxo-state-storing>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-remote-operations [sorting]="true" [grouping]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
  <dxo-editing mode="row" [allowAdding]="!siteReadOnly" [allowUpdating]="!siteReadOnly"></dxo-editing>

  <!-- Colonnes -->
  <dxi-column [allowGrouping]="true" dataField="siteId" caption="Site" [visible]="societeId != null" sortOrder="asc">
    <dxo-lookup [dataSource]="siteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    <dxi-validation-rule type="required" *ngIf="societeId != null"></dxi-validation-rule>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pieceNatureId" caption="Nature pièce">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="pieceNatureDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="libelle" caption="Intitulé">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="stringLength" [max]="100"></dxi-validation-rule>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="defaultMouvementTypeId" caption="Type de mouvement par défaut">
    <dxo-lookup [dataSource]="mouvementTypeDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column [allowGrouping]="false" dataField="validitePiece" caption="Validité (en jours)">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column [allowGrouping]="false" [allowSearch]="false" dataField="actif" caption="Actif" dataType="boolean" [width]="100"></dxi-column>
</dx-data-grid>