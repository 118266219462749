import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { PaysListDto } from '@lexi-clients/lexi';
import { PaysListResult } from '../models/pays-list';

@Injectable({
  providedIn: 'root'
})
export class PaysListService implements IGridService<PaysListDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<PaysListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Pays/recherche-devexpress`, { params: params });
  }

  getById(paysId: number): Observable<PaysListDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Pays/${paysId}`);
  }

  post(valeur: PaysListDto): Observable<any> {
    return null;
  }

  put(valeur: PaysListDto): Observable<any> {
    return null;
  }

  delete(valeur: PaysListDto): Observable<any> {
    return null;
  }
}
