<dx-form [formData]="fretLocalSettings" [colCount]="colCount" [readOnly]="formIsReadOnly">
    
  <dxi-item *ngIf="typeUtilisateur != TypeUtilisateurRuunui.armateur" itemType="group" [colSpan]="3" colCount="3">
     <dxi-item dataField="isMarchandiseAssuree" [label]="{ text: 'Marchandise assurée' }" editorType="dxSwitch"></dxi-item>
  </dxi-item>
  
  <dxi-item *ngIf="typeUtilisateur == TypeUtilisateurRuunui.armateur" itemType="group" [colSpan]="3" colCount="3">
    <dxi-item  itemType="group" [colSpan]="3">
      <div style="font-size: 13px;">
        L'intégration automatique des demandes est conditionnée par le voyage (poids max, volume max, etc...).<br />
        Pour savoir dans quel statut on intègre une demande, on se pose les questions suivantes :<br />
        - Est-ce qu'on force un certain statut au niveau du voyage ?<br />
        - Si non, est-ce qu'on force un certain statut au niveau du partenaire qui paie le connaissement ?<br />
        - Si non, est-ce qu'on force un certain statut au niveau du partenaire qui ne paie pas le connaissement ?<br />
        - Si non, est-ce qu'on a un statut renseigné au niveau du partenaire qui paie le connaissement ?<br />
        - Si non, est-ce qu'on a un statut renseigné au niveau du voyage ?<br />
        - Si la réponse est non à toutes les questions précédentes, il n'y aura pas d'intégration automatique pour la demande en question
      </div>
    </dxi-item>

    <dxi-item itemType="group" caption="Intégration automatique dans REVATUA" [colSpan]="3" colCount="3">
      <!-- StatutPrincipal -->
      <dxi-item
        dataField="statutPrincipal"
        [label]="{text: 'Toujours valider si expéditeur'}"
        [colSpan]="colSpan"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: statutPrincipalOptions,
          searchEnabled: true,
          displayExpr: 'intitule',
          valueExpr: 'id',
          searchExpr: ['intitule'],
          showDataBeforeSearch: true,
          placeholder: 'Par défaut'
        }"
      ></dxi-item>

      <!-- Toujours refuser : Motif de refus -->
      <dxi-item
        *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.toujoursRefuser"
        dataField="statutPrincipalIdMotifRefus"
        [label]="{text: 'Motif de refus'}"
        [colSpan]="colSpan"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: motifRefusDemande,
          searchEnabled: true,
          displayExpr: 'libelle',
          valueExpr: 'idRevatua',
          searchExpr: ['libelle'],
          showDataBeforeSearch: true
        }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    
      <!-- Toujours refuser : Commentaire en cas de refus -->
      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.toujoursRefuser"
        dataField="statutPrincipalCommentaireEnCasDeRefus" [label]="{ text: 'Commentaire' }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    
      <!-- Personnaliser : Acceptation par défaut -->
      <dxi-item
        *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.personnaliser"
        dataField="statutAcceptationParDefaut"
        [label]="{text: 'Acceptation par défaut'}"
        [colSpan]="colSpan"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: statutsRevatuaAcceptationParDefaut,
          searchEnabled: true,
          displayExpr: 'intitule',
          valueExpr: 'id',
          searchExpr: ['intitule'],
          showDataBeforeSearch: true,
          placeholder: 'Par défaut'
        }"
      ></dxi-item>

      <!-- Personnaliser : Statut si bloqué -->
      <dxi-item
        *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.personnaliser"
        dataField="statutSiBloque"
        [label]="{text: 'Statut si bloqué'}"
        [colSpan]="colSpan"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: statutsRevatuaClientBloque,
          searchEnabled: true,
          displayExpr: 'intitule',
          valueExpr: 'id',
          searchExpr: ['intitule'],
          showDataBeforeSearch: true,
          placeholder: 'Par défaut'
        }"
      ></dxi-item>

      <!-- Statut si bloqué : Motif de refus -->
      <dxi-item
        *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.personnaliser
          && fretLocalSettings?.statutSiBloque == PartenaireFretLocalSettingsStatutRevatua.demandeRefusee"
        dataField="statutPrincipalIdMotifRefus"
        [label]="{text: 'Motif de refus'}"
        [colSpan]="colSpan"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: motifRefusDemande,
          searchEnabled: true,
          displayExpr: 'libelle',
          valueExpr: 'idRevatua',
          searchExpr: ['libelle'],
          showDataBeforeSearch: true
        }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    
      <!-- Statut si bloqué : Commentaire en cas de refus -->
      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.personnaliser
        && fretLocalSettings?.statutSiBloque == PartenaireFretLocalSettingsStatutRevatua.demandeRefusee"
        dataField="statutSiBloqueCommentaireEnCasDeRefus" [label]="{ text: 'Commentaire en cas de refus' }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
    </dxi-item>   

    <dxi-item itemType="group" [colSpan]="3" >
      <dxi-item *ngIf="fretLocalSettings !=null && fretLocalSettings.statutPrincipal == null">
        Pour réaliser l'intégration automatique, se base sur le <b>paramétrage du voyage</b> par défaut.<br />
        Note : si le voyage ne possède pas de paramétrage spécifique, se base sur le paramétrage du site ou de la société.
      </dxi-item>

      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.parametreVoyage">
        Pour réaliser l'intégration automatique, se base sur le <b>paramétrage du voyage.</b><br />
        Note : si le voyage ne possède pas de paramétrage spécifique, se base sur le paramétrage du site ou de la société.
      </dxi-item>

      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.personnaliser" [innerHtml]="getDescriptionPersonnaliser()"></dxi-item>

      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.toujoursOfficialiser">
        Si le voyage le permet, les demandes de ce partenaire seront intégrées automatiquement à l'état '<b>Officialisé</b>' dans REVATUA si il est le payeur.
      </dxi-item>

      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.toujoursOfficialiserSousReserve">
        Si le voyage le permet, les demandes de ce partenaire seront intégrées automatiquement à l'état '<b>Officialisé sous réserve</b>' dans REVATUA si il est le payeur.
      </dxi-item>

      <dxi-item *ngIf="fretLocalSettings?.statutPrincipal == StatutIntegrationAutomatiqueDemandeChargeur.toujoursRefuser">
        Si le voyage le permet, les demandes de ce partenaire seront intégrées automatiquement à l'état '<b>Demande refusé</b>' dans REVATUA si il est le payeur.
      </dxi-item>
    </dxi-item>
  </dxi-item>
</dx-form>