<i *ngIf="hasDateCreationDocument" id="tooltipDocument" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showTooltipDateCreationDocument = true" (mouseleave)="showTooltipDateCreationDocument = false"></i>
<i *ngIf="hasDateCreationBon" id="tooltipBon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showTooltipDateCreationBon = true" (mouseleave)="showTooltipDateCreationBon = false"></i>

<!-- Popover: Tooltip du document -->
<dx-popover
  target="#tooltipDocument"
  [(visible)]="showTooltipDateCreationDocument"
>
  <div *dxTemplate="let data = model; of: 'content'">
    {{message}}
  </div>
</dx-popover>

<!-- Popover: Tooltip du bon -->
<dx-popover
  target="#tooltipBon"
  [(visible)]="showTooltipDateCreationBon"
>
  <div *dxTemplate="let data = model; of: 'content'">
    {{message}}
  </div>
</dx-popover>