import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  BonDto, LexiMouvementTypeDto, LieuStockageDto,
  MouvementSens, ObjectType, PartenaireDto, PartenaireType
} from '@lexi-clients/lexi';
import { DxFormComponent } from 'devextreme-angular';
import { InternationalisationPipe } from '../../../pipes/internationalisation.pipe';
import DataSource from 'devextreme/data/data_source';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { lieuStockagePartenaireExterneDefaultCodeBo } from 'lexi-angular/src/app/models/lieu-stockage-list';

@Component({
  selector: 'app-bon-detail-lieu-stockage',
  templateUrl: './bon-detail-lieu-stockage.component.html',
  styleUrls: ['./bon-detail-lieu-stockage.component.scss'],
})
export class BonDetailLieuStockageComponent implements OnChanges {
  //#region Variables
  @Input() isCreation: boolean;
  @Input() disableAllBtn: boolean;
  @Input() isModificationEnCours: boolean;
  @Input() userIsDestination: boolean;
  @Input() userIsSource: boolean;
  @Input() bon: BonDto;
  @Input() bonSens: MouvementSens;
  @Input() currentSitePartenaireId: number;
  @Input() currentMouvementType: LexiMouvementTypeDto;
  @Input() lieuStockageDataSource: LieuStockageDto[];
  @Input() partenaireSourceDataSource: DataSource;
  @Input() partenaireDestinationDataSource: DataSource;
  @Input() canEditLieuStockage: boolean;
  @Input() canEditPartenaireSource: boolean;
  @Input() canEditPartenaireDestination: boolean;
  @Input() showNoSerieInputs: boolean;

  @Output() stockageChanged = new EventEmitter<'source' | 'destination'>();

  @ViewChild('form') form: DxFormComponent;

  get allInputsInitialized(): boolean {
    return this.isCreation != null
    && this.isModificationEnCours != null
    && this.userIsDestination != null
    && this.userIsSource != null
    && this.bon != null
    && this.currentSitePartenaireId != null
    && this.currentMouvementType != null
    && this.lieuStockageDataSource != null
    ;
  }

  /** Affichage du stockage source uniquement en cas de préparation */
  get showSourceStockage(): boolean {
    return this.userIsSource && this.bon != null && this.bon.sens != MouvementSens.inventaire;
  }

  /** Affichage du stockage destination uniquement en cas d'inventaire ou de réception */
  get showDestinationStockage(): boolean {
    return this.userIsDestination && this.bon != null;
  }

  get carteGaucheIsPartenaireSource(): boolean {
    return this.bonSens === MouvementSens.sortie || this.bonSens === MouvementSens.inventaire;
  }

  readonly ObjectType = ObjectType;
  readonly MouvementSens = MouvementSens;
  objectTypeDataSource: Array<{id: ObjectType, intitule: string}>;
  carteGauche: BonDetailCarte;
  carteDroite: BonDetailCarte;
  //#endregion

  //#region Constructeur
  constructor(
    internationalisationPipe: InternationalisationPipe,
    private readonly dxDataSourceService: DxDataSourceService
  ) {
    const siteIntitule = internationalisationPipe.transform("Site", "Site");
    this.objectTypeDataSource = [
      { id: ObjectType.site, intitule: siteIntitule },
      { id: ObjectType.caisse, intitule: "Caisse" },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Appeler à l'initialisation uniquement.
    // Remplace le ngOnInit car on veut s'assurer que tous nos inputs possède une valeur avant de charger les données
    if (this.allInputsInitialized && (this.carteGauche == null || changes.bonSens != null)) this.setCartes();
  }
  //#endregion


  //#region Méthodes principales
  setCartes() {
    if (!this.allInputsInitialized) return;
    this.setCarteGauche();
    this.setCarteDroite();
  }

  /**
   * La carte de gauche représentera TOUJOURS la position de l'utilisateur actuel
   */
  private async setCarteGauche() {
    this.carteGauche = {
      itemId: this.carteGaucheIsPartenaireSource ? this.bon.partenaireSourceId : this.bon.partenaireDestinationId,
      origine: this.carteGaucheIsPartenaireSource ? 'source' : 'destination',
      itemDataSource: this.carteGaucheIsPartenaireSource ? this.partenaireSourceDataSource : this.partenaireDestinationDataSource,
      partenaireIsReadOnly: true, // Set ultérieurement
      stockageId: null, // Set ultérieurement
      stockageDataSource: null, // Set ultérieurement
      stockageIsReadOnly: null, // Set ultérieurement
      showStockage: null, // Set ultérieurement
      cardHeaderCaption: this.getCardHeaderCaption('gauche'),
      cssClass: 'dx-card p-8 leftBloc is-current-site',
      itemIdBonDataField: this.carteGaucheIsPartenaireSource ? 'partenaireSourceId' : 'partenaireDestinationId',
      stockageIdBonDataField: this.carteGaucheIsPartenaireSource ? 'sourceStockageId' : 'destinationStockageId',
    };
    this.setCarteGaucheReadOnlyValues();
    this.setCarteGaucheStockage();
  }

  private setCarteDroite() {
    this.carteDroite = {
      itemId: this.carteGaucheIsPartenaireSource ? this.bon.partenaireDestinationId : this.bon.partenaireSourceId,
      origine: this.carteGaucheIsPartenaireSource ? 'destination' : 'source',
      itemDataSource: this.carteGaucheIsPartenaireSource ? this.partenaireDestinationDataSource : this.partenaireSourceDataSource,
      partenaireIsReadOnly: true, // Set ultérieurement
      stockageId: null, // Set ultérieurement
      stockageDataSource: null, // Set ultérieurement
      stockageIsReadOnly: null, // Set ultérieurement
      showStockage: null, // Set ultérieurement
      cardHeaderCaption: this.getCardHeaderCaption('droite'),
      cssClass: 'dx-card p-8 rightBloc' + (this.userIsDestination && this.userIsSource ? ' is-current-site' : ''),
      itemIdBonDataField: this.carteGaucheIsPartenaireSource ? 'partenaireDestinationId' : 'partenaireSourceId',
      stockageIdBonDataField: this.carteGaucheIsPartenaireSource ? 'destinationStockageId' : 'sourceStockageId'
    };
    this.setCarteDroiteReadOnlyValues();
    this.setCarteDroiteStockage();
  }
  //#endregion

  //#region Gestion des évènements
  onCarteItemIdChanged = async (e: {value: number}, emplacement: 'gauche' | 'droite') => {
    if (this.disableAllBtn) return;
    this.onPropertyChanged(e, emplacement, 'itemId');

    if (emplacement == 'gauche') {
      await this.setCarteGaucheStockage(true);
      // On recalcule le stock des lignes de marchandises
      // Inutile car traité dans setCarteGaucheStockage
      //this.stockageChanged.emit(this.carteGauche.origine);
    }
    else {
      await this.setCarteDroiteStockage(true);
      // On recalcule le stock des lignes de marchandises
      // Initule car traité dans setCarteDroiteStockage
      // this.stockageChanged.emit(this.carteDroite.origine);
    }
  }

  onPropertyChanged = (e: {value: any}, emplacement: 'gauche' | 'droite', carteDataField: string) => {
    if (this.disableAllBtn) return;
    // Set d'une propriété d'une des cartes et recalcule des conditions des champs readOnly
    if (emplacement == 'gauche') {
      this.carteGauche[carteDataField] = e.value;
      this.setCarteGaucheReadOnlyValues();
    }
    else {
      this.carteDroite[carteDataField] = e.value;
      this.setCarteDroiteReadOnlyValues();
    }

    // Set de la propriété équivalente sur le bon
    const bonProperty = emplacement == 'gauche' ? this.carteGauche[carteDataField + 'BonDataField'] : this.carteDroite[carteDataField + 'BonDataField']; // On récupère le nom de la propriété à changer sur le bon
    this.bon[bonProperty] = e.value;
  }
  //#endregion

  //#region Méthodes helpers
  private getCardHeaderCaption(emplacement: 'gauche' | 'droite'): string {
    const sens = this.isCreation ? this.currentMouvementType.sens : this.bonSens;
    //#region Cas : Création d'un bon
    if (this.isCreation) {
      if (sens == MouvementSens.entree) {
        return emplacement == 'gauche' ? 'Destination' : 'Source';
      }
      else if (sens == MouvementSens.sortie) {
        return emplacement == 'gauche' ? 'Source' : 'Destination'
      }
    }
    //#endregion

    //#region Cas : Modification d'un bon existant
    else {
      if (sens == MouvementSens.entree) {
        return emplacement == 'gauche' ? this.bon.partenaireDestinationIntitule ?? 'Destination' : this.bon.partenaireSourceIntitule ?? 'Source';
      }
      else if (sens == MouvementSens.sortie) {
        return emplacement == 'gauche' ? this.bon.partenaireSourceIntitule ?? 'Source' : this.bon.partenaireDestinationIntitule ?? 'Destination';
      }
    }
    //#endregion
  }

  private setCarteGaucheReadOnlyValues() {

    this.carteGauche.partenaireIsReadOnly = this.carteGaucheIsPartenaireSource ? !this.canEditPartenaireSource : !this.canEditPartenaireDestination;
    this.carteGauche.stockageIsReadOnly = !this.canEditLieuStockage;
  }

  private setCarteDroiteReadOnlyValues() {
    this.carteDroite.partenaireIsReadOnly = this.carteGaucheIsPartenaireSource ? !this.canEditPartenaireDestination : !this.canEditPartenaireSource;
    this.carteDroite.stockageIsReadOnly = !this.canEditLieuStockage;
  }

  private async setCarteGaucheStockage(isItemIdChanged: boolean = false) {
    const partenaire: PartenaireDto = await this.dxDataSourceService.getPartenaireById(this.carteGauche.itemId);
    let stockageId = isItemIdChanged ? null
      : this.carteGaucheIsPartenaireSource ? this.bon.sourceStockageId 
      : this.bon.destinationStockageId;
    const stockageDataSource = this.lieuStockageDataSource.filter(x =>
      x.id == stockageId
      || x.partenaireId == this.carteGauche.itemId
      || (partenaire?.type == PartenaireType.externe && x.codeBo == lieuStockagePartenaireExterneDefaultCodeBo)
    );
    if (stockageId == null && stockageDataSource?.length == 1) stockageId = stockageDataSource[0].id;
    let showStockage = this.carteGaucheIsPartenaireSource ? this.showSourceStockage : this.showDestinationStockage;
    // Si le stockage ne peut pas être défini automatiquement ou si le partenaire est null, on affiche la selectBox
    if (stockageId == null || this.carteGauche.itemId == null) showStockage = true;

    // A l'initialisation de la carte, on ne veut pas recalculer le stockage
    const stockageHasChanged: boolean = isItemIdChanged && this.carteGauche.stockageId !== stockageId;
    this.carteGauche.stockageDataSource = stockageDataSource;
    this.carteGauche.showStockage = showStockage;
    this.carteGauche.stockageId = stockageId;
    this.bon[this.carteGauche.stockageIdBonDataField] = stockageId;

    // On recalcule le stock disponible des lignes de marchandise
    if (stockageHasChanged) {
      this.stockageChanged.emit(this.carteGauche.origine);
    }
  }

  private async setCarteDroiteStockage(isItemIdChanged: boolean = false) {
    const partenaire: PartenaireDto = await this.dxDataSourceService.getPartenaireById(this.carteDroite.itemId);
    let stockageId = isItemIdChanged ? null
      : this.carteGaucheIsPartenaireSource ? this.bon.destinationStockageId 
      : this.bon.sourceStockageId;
    const stockageDataSource = this.lieuStockageDataSource.filter(x =>
      x.id == stockageId
      || x.partenaireId == this.carteDroite.itemId
      || (partenaire?.type == PartenaireType.externe && x.codeBo == lieuStockagePartenaireExterneDefaultCodeBo)
    );
    if (stockageId == null && stockageDataSource?.length == 1) stockageId = stockageDataSource[0].id;
    let showStockage = this.carteGaucheIsPartenaireSource ? this.showDestinationStockage : this.showSourceStockage;
    // Si le stockage ne peut pas être défini automatiquement ou si le partenaire est null, on affiche la selectBox
    if (stockageId == null || this.carteDroite.itemId == null) showStockage = true;

    // A l'initialisation de la carte, on ne veut pas recalculer le stockage
    const stockageHasChanged: boolean = isItemIdChanged && this.carteDroite.stockageId !== stockageId;
    this.carteDroite.stockageDataSource = stockageDataSource;
    this.carteDroite.showStockage = showStockage;
    this.carteDroite.stockageId = stockageId;
    this.bon[this.carteDroite.stockageIdBonDataField] = stockageId;

    // On recalcule le stock disponible des lignes de marchandise
    if (stockageHasChanged) {
      this.stockageChanged.emit(this.carteDroite.origine);
    }
  }

  partenaireDisplayExpr(data: PartenaireDto) {
    return data && `${data.codeBo} - ${data.intitule}`;
  }

  lieuStockageDisplayExpr(data: LieuStockageDto) {
    return data && `${data.codeBo} - ${data.intitule}`
  }
  //#endregion
}

interface BonDetailCarte {
  origine: 'source' | 'destination';
  itemId: number;
  itemDataSource: DataSource;
  partenaireIsReadOnly: boolean;
  stockageId: number;
  stockageDataSource: LieuStockageDto[];
  stockageIsReadOnly: boolean;
  showStockage: boolean;
  cardHeaderCaption: string;
  cssClass: string;
  itemIdBonDataField: 'partenaireSourceId' | 'partenaireDestinationId';
  stockageIdBonDataField: 'sourceStockageId' | 'destinationStockageId';
}
