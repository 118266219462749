import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipeDto, EquipesLexiClient, GetSiteListDto, SitesLexiClient, UpdateUserProfileDto, UtilisateursLexiClient } from '@lexi-clients/lexi';
import { LexiUser } from '@lexi/oidc-uaa/public-api';
import { UaaService } from '@lexi/oidc-uaa';
import notify from 'devextreme/ui/notify';
import { lastValueFrom, Subscription } from 'rxjs';
import { InternationalisationPipe } from '../../modules/shared/pipes/internationalisation.pipe';
import { NotificationType } from '../../modules/shared/references/references';
import { AuthService } from '../../settings/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  siteParDefautLibelle = "Site par défaut";
  storedUser: LexiUser;
  files: File[] = [];
  user: UpdateUserProfileDto = {};
  sites: GetSiteListDto[] = [];
  equipes: EquipeDto[] = [];
  connectDomain: string;
  subscriptionAuthorityServer : Subscription;

  constructor(
    private readonly userService: UtilisateursLexiClient,
    private readonly authService: AuthService,
    private readonly equipeService: EquipesLexiClient,
    private readonly siteService: SitesLexiClient,
    private readonly internationalisationPipe: InternationalisationPipe,
    private readonly uaaService: UaaService
  ) {
    this.subscriptionAuthorityServer = this.uaaService.openIdConfiguration.subscribe(
      x => this.connectDomain = x.authority
    );
  }
  ngOnDestroy(): void {
    this.subscriptionAuthorityServer.unsubscribe();
  }

  async ngOnInit() {
    this.siteParDefautLibelle = this.internationalisationPipe.transform("Site_par_defaut", "Site par défaut");
    this.storedUser = this.authService.getStoredUser();
    this.user = await lastValueFrom(this.userService.getUserProfileByUserId(this.storedUser.id));
    this.sites = await lastValueFrom(this.siteService.getPrincipaux());
    this.equipes = await lastValueFrom(this.equipeService.getAll());
  }

  async onCancel() {
    this.files = [];
    this.user = await lastValueFrom(this.userService.getUserProfileByUserId(this.storedUser.id));
  }

  async onSubmit() {
    // update infos profil
    await lastValueFrom(this.userService.updateUserProfile(this.storedUser.id, this.user));

    // update signature
    if(this.files.length > 0) {
      await lastValueFrom(this.userService.updateUserSignature(this.storedUser.id, this.files[0]));
    }

    // get user
    this.user = await lastValueFrom(this.userService.getUserProfileByUserId(this.storedUser.id));
    notify({closeOnClick: true, message: "Modifications effectuées avec succès"}, NotificationType.Success);
    this.files = [];
  }

  async onSupprimerSignature() {
    await lastValueFrom(this.userService.deleteUserSignature(this.storedUser.id));
    this.user.hasSignature = false;
    notify({closeOnClick: true, message: "Votre signature a été supprimée avec succès"}, NotificationType.Success);
  }

}
