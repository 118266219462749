import { Injectable } from '@angular/core';
import { GetConditionnementDto, UniteType } from '@lexi-clients/lexi';

@Injectable({
  providedIn: 'root'
})
export class ConditionnementService {
  convertirQuantiteConditionnee(typeConditionnement: ConditionnementType, quantite: number, rowData: ConditionnementData) {
    const coefficient = this.getCoefficient(typeConditionnement, rowData);
    return this.arrondirALa3emeDecimale(quantite / coefficient)
  }

  convertirQuantiteUB(typeConditionnement: ConditionnementType, quantite: number, rowData: ConditionnementData) {
    const coefficient = this.getCoefficient(typeConditionnement, rowData);
    return this.arrondirALa3emeDecimale(quantite * coefficient);
  }

  definirUnite(typeConditionnement: ConditionnementType, rowData: ConditionnementData) {
    if (rowData == null) return null;
    switch(typeConditionnement){
      case ConditionnementType.achat:
        return rowData.conditionnementAchat?.uniteId ?? rowData.uniteId;
      case ConditionnementType.vente:
        return rowData.conditionnementVente?.uniteId ?? rowData.uniteId;
      case ConditionnementType.base:
      default:
        return rowData.uniteId;
    }
  }

  getConditionnementTypes(): Array<{ id: ConditionnementType, intitule: string }> {
    return [
      { id: ConditionnementType.achat, intitule: 'Achat'},
      { id: ConditionnementType.vente, intitule: 'Vente'},
      { id: ConditionnementType.base, intitule: 'Base'}
    ];
  }

  getConditionnementTypeFromUniteType(uniteType: UniteType): ConditionnementType {
    switch (uniteType) {
      case UniteType.achat:
        return ConditionnementType.achat;
      case UniteType.vente:
        return ConditionnementType.vente;
      case UniteType.base:
      default:
        return ConditionnementType.base;
    }
  }

  private arrondirALa3emeDecimale(valeur: number): number {
    return Math.round(valeur * 1000) / 1000;
  }

  private getCoefficient(typeConditionnement: ConditionnementType, rowData: ConditionnementData) {
    switch(typeConditionnement){
      case ConditionnementType.achat:
        return rowData.conditionnementAchat?.coefficient ?? 1;
      case ConditionnementType.vente:
        return rowData.conditionnementVente?.coefficient ?? 1;
      case ConditionnementType.base:
        return 1;
    }
  }
}

export interface ConditionnementData {
  conditionnementAchat?: GetConditionnementDto;
  conditionnementVente?: GetConditionnementDto;
  uniteId?: number;
}

export enum ConditionnementType {
  achat = "achat",
  vente = "vente",
  base = "base"
}
