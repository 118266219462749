<div style="display: flex; align-items: center; padding: 1rem 0 0 1rem;">
  <dx-select-box
    [dataSource]="typesDatasource"
    [searchEnabled]="true"
    [showClearButton]="true"
    [searchExpr]="['intitule', 'codeBo']"
    [showDataBeforeSearch]="true"
    valueExpr="id"
    [displayExpr]="typesDisplayExpr"
    placeholder="Sélectionner un type de classification"
    (onValueChanged)="onTypeChanged($event?.value)"
    [width]="350"
  ></dx-select-box>
  <dx-button text="Ajouter une classification racine" (onClick)="onShowAddingPopup()" style="margin-left: 12px;" [disabled]="!currentClassificationTypeId"></dx-button>
</div>

<!-- TreeList -->
<dx-tree-list
  [dataSource]="classificationStore"
  [allowColumnResizing]="true"
  keyExpr="id"
  parentIdExpr="parentId"
  [rootValue]="null"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onInitNewRow)="onInitNewRow($event)"
  *ngIf="currentClassificationTypeId !== null"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setClassificationStore()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button [disabled]="!currentClassificationTypeId" icon="add" hint="Ajouter une classification de niveau 1" (onClick)="onShowAddingPopup()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-remote-operations [filtering]="true" [sorting]="true" [paging]="true" [grouping]="true"></dxo-remote-operations>
  <dxo-editing mode="form" [allowAdding]="!!currentClassificationTypeId" [allowUpdating]="true" [allowDeleting]="true">
    <dxo-form [colCount]="9">

      <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
        <dxi-item itemType="group" [colCount]="3" [colSpan]="1">
          <dxi-item dataField="codeBo" caption="Code" [colSpan]="2">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="niveau" caption="Niveau" [width]="67"></dxi-item>
        </dxi-item>

        <dxi-item dataField="intitule" caption="Intitulé" [colSpan]="2">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="1" [colSpan]="3">
        <dxi-item itemType="group" [colCount]="2" [colSpan]="1">
          <dxi-item
            dataField="codeTarifRevatua"
            [label]="{ text: 'Code tarif Revatua' }"
            [visible]="isTypeArticle"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: tarifsRevatua,
              searchEnabled: true,
              displayExpr: codeTarifRevatuaDisplayExpr,
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['code', 'intitule'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

          <dxi-item
            dataField="stockageRevatua"
            [label]="{ text: 'Stockage Revatua' }"
            [visible]="isTypeArticle"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: stockageRevatuaList,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="1">
          <dxi-item dataField="matiereDangereuse" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Matière dangereuse'}"></dxi-item>
          <dxi-item dataField="marchandiseFragile" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Fragile'}"></dxi-item>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" [colCount]="2" [colSpan]="4">
        <dxi-item
          dataField="compteCharge"
          [label]="{ text: 'Compte de charge' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteProduit"
          [label]="{ text: 'Compte Produit' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteStock"
          [label]="{ text: 'Compte Stock' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteVariationStock"
          [label]="{ text: 'Compte Variation de Stock' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteRemise"
          [label]="{ text: 'Compte Remise' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>
      </dxi-item>

    </dxo-form>
  </dxo-editing>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-scrolling mode="infinite"></dxo-scrolling>
  <dxo-column-chooser [enabled]="true" mode="select" title="Choisir les comptes"></dxo-column-chooser>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" caption="Code" sortOrder="asc" [showInColumnChooser]="false"></dxi-column>
  <dxi-column dataField="intitule" caption="Intitulé" [showInColumnChooser]="false"></dxi-column>
  <dxi-column dataField="niveau" caption="Niveau" [width]="67" [allowEditing]="false" [showInColumnChooser]="false"></dxi-column>
  <dxi-column dataField="compteCharge" caption="Compte de charge" [visible]="false"></dxi-column>
  <dxi-column dataField="compteProduit" caption="Compte Produit" [visible]="false"></dxi-column>
  <dxi-column dataField="compteStock" caption="Compte Stock" [visible]="false"></dxi-column>
  <dxi-column dataField="compteVariationStock" caption="Compte Variation de Stock" [visible]="false"></dxi-column>
  <dxi-column dataField="compteRemise" caption="Compte Remise" [visible]="false"></dxi-column>
  <dxi-column dataField="codeTarifRevatua" caption="Code tarif REVATUA" [visible]="isTypeArticle">
    <dxo-lookup [dataSource]="tarifsRevatua" [displayExpr]="codeTarifRevatuaDisplayExpr" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="stockageRevatua" caption="Stockage REVATUA" [visible]="isTypeArticle">
    <dxo-lookup [dataSource]="stockageRevatuaList" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="matiereDangereuse" caption="Matière dangereuse" [visible]="isTypeArticle" dataType="boolean"></dxi-column>
  <dxi-column dataField="marchandiseFragile" caption="Fragile" [visible]="isTypeArticle" dataType="boolean"></dxi-column>
</dx-tree-list>

<!-- Popup : Créer une classification racine -->
<dx-popup
  title = "Créer une classification racine"
  [(visible)]="showPopup"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="800"
  [height]="580"
>
  <dx-scroll-view>
    <dx-form #formNewClassificationRacine [formData]="newClassificationValueRacine" labelLocation="top">

      <dxi-item itemType="group" [colCount]="5" [colSpan]="1">
          <dxi-item dataField="codeBo" [label]="{text: 'Code'}">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          
          <dxi-item dataField="intitule" [label]="{text: 'Intitulé'}" [colSpan]="3">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="niveau" [label]="{text: 'Niveau'}" editorType="dxNumberBox" [editorOptions]="{readOnly: true, min: 1}"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Revatua" [colCount]="5" [colSpan]="1">
          <dxi-item [colSpan]="2"
            dataField="codeTarifRevatua"
            [label]="{ text: 'Code tarif' }"
            [visible]="isTypeArticle"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: tarifsRevatua,
              searchEnabled: true,
              displayExpr: codeTarifRevatuaDisplayExpr,
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['code', 'intitule'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

          <dxi-item
            dataField="stockageRevatua"
            [label]="{ text: 'Stockage' }"
            [visible]="isTypeArticle"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: stockageRevatuaList,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="matiereDangereuse" editorType="dxCheckBox" [label]="{ text: 'Matière dangereuse' }"></dxi-item>
          <dxi-item dataField="marchandiseFragile" editorType="dxCheckBox" [label]="{ text: 'Fragile' }"></dxi-item>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Comptabilité" [visible]="isTypeArticle" [colCount]="2" [colSpan]="2">
        <dxi-item
          dataField="compteCharge"
          [label]="{ text: 'Compte de charge' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteProduit"
          [label]="{ text: 'Compte Produit' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteStock"
          [label]="{ text: 'Compte Stock' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteVariationStock"
          [label]="{ text: 'Compte Variation de Stock' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          dataField="compteRemise"
          [label]="{ text: 'Compte Remise' }"
          [visible]="isTypeArticle"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: comptesGeneraux,
            searchEnabled: true,
            displayExpr: compteGeneralDisplayExpr,
            valueExpr: 'code',
            showClearButton: true,
            searchExpr: ['code', 'intitule'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
  <div style="margin-top: 12px; display: flex; justify-content: center; position: absolute; bottom: 10px;">
    <dx-button text="Annuler" type="danger" style="margin-right: 6px;" (onClick)="onCancelAddingClassificationRacine()"></dx-button>
    <dx-button text="Confirmer" type="success" (onClick)="onCreateNewClassificationRacine()"></dx-button>
  </div>
</dx-popup>