<div style="margin: 0 0 1.5em 1.5em;">
  <dx-button text="Rafraîchir" (onClick)="setReglementModes()"></dx-button>
  <dx-button *ngIf="!siteReadOnly" (click)="navigateToNewModeReglement()" style="margin-left: 12px;" text="Créer un mode de règlement {{'magasin' | internationalisation}}" ></dx-button>
</div>

<dx-data-grid
  #dataGridReglementModesMagasin
  class="dx-card wide-card"
  keyExpr="id"
  [dataSource]="reglementModesMagasin"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="false"
  [columnAutoWidth]="true"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Colonnes -->
  <dxi-column dataField="intitule" caption="Intitulé" cellTemplate="intituleTemplate">
    <div *dxTemplate="let cell of 'intituleTemplate'">
      <a routerLink="/administration/mode-de-reglement-magasin/{{cell.data.id}}">
        {{cell.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="reglementModeId" caption="Type">
    <dxo-lookup [dataSource]="reglementModes" valueExpr="id" displayExpr="description"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="codeBo" caption="Code Bo"></dxi-column>
  <dxi-column dataField="actif" caption="Actif"></dxi-column>
</dx-data-grid>