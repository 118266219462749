import { Component, Input } from '@angular/core';
import { DocumentLogistiqueEnteteDto, FluxStatut, ObjectType } from '@lexi-clients/lexi';

@Component({
  selector: 'app-document-logistique-entete',
  templateUrl: './document-logistique-entete.component.html',
  styleUrls: ['./document-logistique-entete.component.scss']
})
export class DocumentLogistiqueEnteteComponent {
  @Input() showBackButton = true;
  @Input() isCreation: boolean;
  @Input() isModificationEnCours: boolean;
  @Input() mouvementTypeIntitule: string;
  @Input() readonly fluxStatutDataSource: Array<{ id: FluxStatut; intitule: string }> = [];
  @Input() document: DocumentLogistiqueEnteteDto;

  ObjectType = ObjectType;

  get documentStatut(): string {
    if (!this.document?.fluxStatut) return "";
    return this.fluxStatutDataSource.find((x) => x.id == this.document.fluxStatut)?.intitule;
  }
}
