import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptionTelechargementPlusieursPdfs } from '@lexi-clients/lexi';

@Injectable({ providedIn: 'root' })
export class DownloadService {

    constructor() {
    }

    public getOptionsTelechargementPdfs(): {id: OptionTelechargementPlusieursPdfs, libelle: string}[] {
        return [
            { id: OptionTelechargementPlusieursPdfs.pdf, libelle: "Dans un seul PDF" },
            { id: OptionTelechargementPlusieursPdfs.zip, libelle: "Dans un fichier .zip" }
        ];
    }

    public directDownloadFile(response: HttpResponse<Blob>, extensionFichier: string) {
        const headerValue = response.headers.getAll("Content-disposition")[0];

        const index1 = headerValue.indexOf("filename=") + 9;
        const index2 = headerValue.indexOf(`${extensionFichier};`);
        const filename = headerValue.slice(index1, index2) + extensionFichier;

        const data = response.body;
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }
}
