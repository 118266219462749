import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReglementModeDto, ReglementModeMagasinDto, ReglementModeMagasinsLexiClient, ReglementModesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-gestion-mode-reglement-magasin',
  templateUrl: './gestion-mode-reglement-magasin.component.html',
  styleUrls: ['./gestion-mode-reglement-magasin.component.scss'],
})
export class GestionModeReglementMagasinComponent implements OnInit {
  @ViewChild('dataGridReglementModesMagasin', { static: false }) dataGrid: DxDataGridComponent;
  @Input() siteId: number = null;
  @Input() siteReadOnly: boolean = true;
  reglementModes: ReglementModeDto[] = [];
  reglementModesMagasin: ReglementModeMagasinDto[] = [];

  constructor(
    private readonly reglementModesLexiClient: ReglementModesLexiClient,
    private readonly reglementModeMagasinLexiClient: ReglementModeMagasinsLexiClient,
    private readonly router: Router
  ) { }

  async ngOnInit() {
    await this.setReglementModes();
  }

  async setReglementModes(){
    this.reglementModes = await lastValueFrom(this.reglementModesLexiClient.getReglementModes());
    this.reglementModesMagasin = await lastValueFrom(this.reglementModeMagasinLexiClient.getBySiteId(this.siteId));
  }

  navigateToNewModeReglement() {
    this.router.navigate(['/administration/mode-de-reglement-magasin/nouveau']);
  }

  async onKeyDown(e) {
    if (!this.siteReadOnly && e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }
}
