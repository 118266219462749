<dx-form *ngIf="allInputsInitialized && carteGauche" #form [formData]="this" colCount="7" [readOnly]="!isModificationEnCours" labelLocation="top">
  <!-- Carte de gauche -->
  <dxi-item  *ngIf="bonSens == MouvementSens.inventaire" [colSpan]="3"></dxi-item>
  <dxi-item *ngIf="bonSens != MouvementSens.inventaire" itemType="group" [caption]="carteGauche.cardHeaderCaption" [colSpan]="3" [cssClass]="carteGauche.cssClass">
    <!-- Item -->
    <dxi-item *ngIf="bon != null && !bon.documentLogistiqueId" [label]="{text: 'Partenaire'}">
      <div *dxTemplate>
        <dx-select-box
          [value]="carteGauche.itemId"
          (onValueChanged)="onCarteItemIdChanged($event, 'gauche')"
          [dataSource]="carteGauche.itemDataSource"
          [displayExpr]="partenaireDisplayExpr"
          valueExpr="id"
          [searchEnabled]="true"
          [searchExpr]="['intitule', 'codeBo']"
          [readOnly]="!isModificationEnCours || carteGauche.partenaireIsReadOnly"
          dataField="carteGauche.itemId"
          editorType="dxSelectBox"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dx-select-box>
        </div>
    </dxi-item>

    <!-- Stockage -->
    <dxi-item *ngIf="carteGauche.showStockage" [label]="{text: bon != null && !bon.documentLogistiqueId ? 'Stockage' : ' '}">
      <div *dxTemplate>
        <dx-select-box
          [value]="carteGauche.stockageId"
          (onValueChanged)="onPropertyChanged($event, 'gauche', 'stockageId')"
          [dataSource]="carteGauche.stockageDataSource"
          [displayExpr]="lieuStockageDisplayExpr"
          valueExpr="id"
          [searchEnabled]="true"
          [searchExpr]="['intitule', 'codeBo']"
          [readOnly]="!isModificationEnCours || carteGauche.stockageIsReadOnly"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dx-select-box>
      </div>
    </dxi-item>
  </dxi-item>

  <!-- Sens -->
  <dxi-item [colSpan]="1">
    <div *dxTemplate class="sensIconWrapper" [ngClass]="bon != null && !bon.documentLogistiqueId ? 'h-143' : 'h-85'">
      <span *ngIf="bonSens == MouvementSens.entree" class="dx-icon-arrowleft" style="font-size: 85px; font-weight: 800; color: green;" title="Entrée"></span>
      <span *ngIf="bonSens == MouvementSens.sortie" class="dx-icon-arrowright arrow-sortie" style="font-size: 85px; font-weight: 800; color: red;" title="Sortie"></span>
      <div *ngIf="bonSens == MouvementSens.inventaire" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <span class="dx-icon-tags" style="font-size: 70px;" title="Inventaire"></span>
        <div *ngIf="!showNoSerieInputs">Quantité uniquement.<br />Sans n° de série.</div>
        <span *ngIf="showNoSerieInputs">Quantités et n° de série</span>
      </div>
    </div>
  </dxi-item>

  <!-- Carte de droite : Paire -->
  <dxi-item *ngIf="carteDroite"
    itemType="group"
    [caption]="carteDroite.cardHeaderCaption"
    [colSpan]="3"
    [cssClass]="carteDroite.cssClass"
  >
    <!-- Item -->
    <dxi-item [label]="{text: 'Partenaire'}" *ngIf="bon != null && !bon.documentLogistiqueId">
      <div *dxTemplate>
        <dx-select-box
          [value]="carteDroite.itemId"
          (onValueChanged)="onCarteItemIdChanged($event, 'droite')"
          [dataSource]="carteDroite.itemDataSource"
          [displayExpr]="partenaireDisplayExpr"
          valueExpr="id"
          [searchEnabled]="true"
          [searchExpr]="['intitule', 'codeBo']"
          [readOnly]="!isModificationEnCours || carteDroite.partenaireIsReadOnly"
          dataField="carteDroite.itemId"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dx-select-box>
      </div>
    </dxi-item>

    <!-- Stockage -->
    <dxi-item *ngIf="carteDroite.showStockage" [label]="{text: bon != null && !bon.documentLogistiqueId ? 'Stockage' : ' '}">
      <div *dxTemplate>
        <dx-select-box
          [value]="carteDroite.stockageId"
          (onValueChanged)="onPropertyChanged($event, 'droite', 'stockageId')"
          [dataSource]="carteDroite.stockageDataSource"
          [displayExpr]="lieuStockageDisplayExpr"
          valueExpr="id"
          [searchEnabled]="true"
          [searchExpr]="['intitule', 'codeBo']"
          [readOnly]="!isModificationEnCours || carteDroite.stockageIsReadOnly"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dx-select-box>
      </div>
    </dxi-item>
  </dxi-item>
</dx-form>