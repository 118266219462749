<dx-data-grid
  #dataGrid
  *ngIf="dataSource"
  id="article-list-datagrid-main-content"
  [dataSource]="dataSource"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onRowInserting)="onRowInserting($event.data)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event.key)"
  (onKeyDown)="onKeyDown($event)"
  >
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-editing [allowUpdating]="canModifierCaracteristiques" [allowDeleting]="canModifierCaracteristiques"
               [allowAdding]="canModifierCaracteristiques" mode="row"></dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="caracteristique.id" caption="Caractéristique" [visible]="true">
    <dxo-lookup [dataSource]="caracteristiquesList" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="valeur" caption="Valeur" [visible]="true"></dxi-column>
</dx-data-grid>
