import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { IleDto } from '@lexi-clients/lexi';
import { IleListResult } from '../models/ile-list';

@Injectable({
  providedIn: 'root'
})
export class IleListService implements IGridService<IleDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<IleListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Iles/recherche-devexpress`, { params: params });
  }

  getById(ileId: number): Observable<IleDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Iles/${ileId}`);
  }

  post(valeur: IleDto): Observable<any> {
    return null;
  }

  put(valeur: IleDto): Observable<any> {
    return null;
  }

  delete(valeur: IleDto): Observable<any> {
    return null;
  }
}
