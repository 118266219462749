<dx-list [dataSource]="dataSource" [ngClass]="containerClass">
  <div *dxTemplate="let data of 'item'">
    <div class="dx-card note-card" [ngClass]="{'note-card-current-user': data.createdBy == currentUserId}">
      <div class="note-header">
        <span class="dx-form-group-caption">{{ data.createdUtilisateur }}</span>
        <span class="dx-field-item-label-text note-date">{{ data.createdOn | date:'dd/MM/yyyy HH:mm' }}</span>
      </div>
      <dx-text-area [minHeight]="100" [(value)]="data.content" [readOnly]="true" />
    </div>
  </div>
</dx-list>