export enum EtatRevatuaEnum
{
    SAISIE = 0,
    OFFICIALISE = 1,
    EMBARQUE = 2,
    EMBARQUEMENT_REFUSE = 3,
    ANNULE = 4,
    MODIFICATION = 5,
    TRANSFERT = 6,
    BROUILLON = 7,
    DEMANDE = 8,
    DEMANDE_REFUSEE = 9,
    OFFICIALISE_SOUS_RESERVE = 10,
    PRIS_EN_CHARGE = 11
}

export class EtatRevatua {
    id: number;
    libelle: string;
    color: string;
    icon:string;
    decoration: string;
    fontStyle: string;
    fontWeight: number;
}

export class EnvoiEtatRevatua {
    id: number;
    libelle: string;
}

export enum ConnaissementPaiementEnum {
    EXPEDITEUR = 0,
    AVENTURE = 1,
    DGAE = 2,
    FAD = 3
}