import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { QUERY_KEY_RETURN_URL } from './constants';
import { OidcUaaConfig } from './oidc-uaa.config';
import { UaaService } from './uaa.service';
import { Observable, filter, map, of } from 'rxjs';
import { LexiUser } from './lexi-user';

@Injectable({
  providedIn: 'root',
})
export class HasAnyRoleGuard implements CanActivate {
  constructor(private uaa: UaaService, private navCtrl: Router, private config: OidcUaaConfig) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if(this.uaa.currentUser.isRoot) {
      return of(true);
    }

    const permissionsRequises = route.data.roles as string[];
    return this.uaa.currentUser$.pipe(
      filter(x => x !== LexiUser.ANONYMOUS),
      map(x => {
        const userPermissions = Object.values(x.activites).reduce((accumulator, value) => accumulator.concat(value), []);
        const hasAnyRole = permissionsRequises.findIndex((role) => userPermissions.includes(role)) > -1;
        if (!hasAnyRole) {
          console.warn(
            ` ${this.uaa.currentUser.username} (${this.uaa.currentUser.subject}) is denied access to ${route.url}: any of ${permissionsRequises} required but has ${userPermissions}`
          );
          const s = permissionsRequises?.length > 1 ? 's' : '';
          window.alert(`Permission${s} manquante${s} : ${permissionsRequises}`);
        }
        return hasAnyRole;
      })
    );

  }
}
