<div class="dx-fieldset">
  <div class="dx-field">
    <div class="dx-field-label">Thèmes</div>
    <div class="dx-field-value">
      <dx-select-box [items]="themesList" [value]="selectedTheme" valueExpr="id" displayExpr="text" (onValueChanged)="onThemeChange($event)" ></dx-select-box>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Personnalisation grille</div>
    <div class="dx-field-value">
      <dx-button text="Supprimer les préférences de grilles" (onClick)="onSupprimerStateStorage()"></dx-button>
    </div>
  </div>
</div>
