/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum EditionTypeCode {
    financieRMagasin = 'financieR_MAGASIN',
    financieRCaissier = 'financieR_CAISSIER',
    statistiqueSVendeurs = 'statistiqueS_VENDEURS',
    badge = 'badge',
    differencECaisse = 'differencE_CAISSE',
    apportSPrelevements = 'apportS_PRELEVEMENTS',
    relevECarte = 'relevE_CARTE',
    relevECheque = 'relevE_CHEQUE',
    hisotriquEImpression = 'hisotriquE_IMPRESSION',
    ouvertureSTiroir = 'ouvertureS_TIROIR',
    remises = 'remises',
    productivitETrancheHoraire = 'productivitE_TRANCHE_HORAIRE',
    retours = 'retours',
    bonSAchat = 'bonS_ACHAT'
};

