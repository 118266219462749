export enum ComptageStatut {
    EnAttente = 0,
    EnCours = 1,
    Transmis = 2,
    Annule = 3,
    Valide = 4,
    Rejete = 5,
    Finalise = 6
}

export enum NotificationType {
    Error = 'error',
    Warning = 'warning',
    Success = 'success',
    Info = 'info'
}