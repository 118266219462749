import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.scss'],
})
export class ClassificationComponent implements OnInit {

  tabs = [
    {id: 1, title: 'Classifications', template: 'templateClassification'},
    {id: 2, title: 'Types de classifications', template: 'templateClassificationType'},
  ];

  objectTypeKey: string = null;

  constructor(
    readonly activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params['objectTypeKey']) {
        this.objectTypeKey = params['objectTypeKey'];
      }
    });
  }

  ngOnInit() {}

}
