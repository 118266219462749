<div style="margin-bottom: 6px;">
  <dx-button text="Rafraîchir" (onClick)="setTarifs()"></dx-button>
</div>

<dx-data-grid
  id="gridContainerTarifs"
  [dataSource]="tarifs"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onInitNewRow)="onInitNewRow($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onRowInserting)="onRowInserting($event.data)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event.key)"
  (onEditingStart)="onEditingStart($event.data)"
  (onSaving)="onSaving($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <i id="tarifArticleLegendeIcon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showLegende = true" (mouseleave)="showLegende = false"></i>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setTarifs()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="popup" [allowAdding]=isModificationAutorisee [allowUpdating]=isModificationAutorisee [allowDeleting]=isModificationAutorisee>
    <dxo-popup
      title="Tarif"
      [showTitle]="true"
      [width]="700"
      [height]="525"
      [showCloseButton]="true"
    ></dxo-popup>
    <dxo-form #form>
      <dxi-item
        dataField="prixId"
        [label]="{text: 'Prix'}"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: prixDataSource,
          searchEnabled: true,
          displayExpr: 'libelleLong',
          valueExpr: 'id',
          showClearButton: true,
          searchExpr: ['libelleLong', 'codeBo', 'libelleCourt'],
          showDataBeforeSearch: true
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="valeur" [label]="{text: 'Valeur'}" editorType="dxNumberBox" [disabled]="isPrixSaisiVente"></dxi-item>
      <dxi-item dataField="pmp" [label]="{text: 'Prix de revient'}" editorType="dxNumberBox">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="valeurProchaine" [label]="{text: 'Valeur prochaine'}" editorType="dxNumberBox"
      [editorOptions]="{showClearButton: true}"
      ></dxi-item>
      <dxi-item dataField="dateProchaine" [label]="{text: 'Date prochaine'}" editorType="dxDateBox"
        [editorOptions]="{displayFormat: 'dd/MM/yyyy HH:mm:ss', type: 'datetime', showClearButton: true}"
      ></dxi-item>
      <dxi-item dataField="saisiePrix" [label]="{text: ' '}" editorType="dxCheckBox"
        [editorOptions]="{text: 'Valeur saisi à la vente'}"
      ></dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="grille_fiche_article_tarifs" appStorage></dxo-state-storing>

  <!-- Colonnes -->
  <dxi-column dataField="prixCodeBo" caption="Code Prix" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="prixIntitule" caption="Libellé Prix" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="pmp" caption="Prix de revient" *ngIf="isAffichagePMPAutorise"></dxi-column>
  <dxi-column dataField="prixTtc" caption="Prix TTC" [allowEditing]="false">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column dataField="prixHt" caption="Prix HT" [allowEditing]="false">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column dataField="prixTypeTaxe" caption="Type" [allowEditing]="false">
    <dxo-lookup [dataSource]="typeTaxeDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="sommeTauxTaxes" caption="Taxes (%)" [width]="80" [visible]="false" [allowEditing]="false"></dxi-column>
  <dxi-column caption="Coefficient" [calculateCellValue]="calculateCoefficient" [width]="90" *ngIf="isAffichagePMPAutorise">
    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
  </dxi-column>
  <dxi-column caption="Marge" [calculateCellValue]="calculateMargeBrute" [width]="120" *ngIf="isAffichagePMPAutorise">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column caption="Marge (%)" [calculateCellValue]="calculateTauxMarge" [width]="90" *ngIf="isAffichagePMPAutorise">
    <dxo-format type="percent" [precision]="2"></dxo-format>
  </dxi-column>
  <dxi-column caption="Marque (%)" [calculateCellValue]="calculateTauxMarque" [width]="90" *ngIf="isAffichagePMPAutorise">
    <dxo-format type="percent" [precision]="2"></dxo-format>
  </dxi-column>
  <dxi-column dataField="prixId" caption="Prix" [visible]="false"></dxi-column>
  <dxi-column dataField="valeur" caption="Valeur" [visible]="false">
    <dxi-validation-rule type="custom" [validationCallback]="checkCanSaisirValeur"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="dateProchaine" caption="Date prochaine" [visible]="false" dataType="date" format="dd/MM/yyyy HH:mm"></dxi-column>
  <dxi-column dataField="valeurProchaine" caption="Valeur prochaine" [visible]="false"></dxi-column>
  <dxi-column dataField="saisiePrix" caption="Prix" dataType="boolean" [visible]="false" [setCellValue]="setSaisiePrixCellValue"></dxi-column>
</dx-data-grid>

<!-- Popover: Légende couleur -->
<dx-popover
  target="#tarifArticleLegendeIcon"
  [width]="300"
  [showTitle]="true"
  title="Légende :"
  [(visible)]="showLegende"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <span class="prix-de-base">Vert</span> = prix de base, définit.<br><br>
    <span class="prix-calcule">Gris</span> = prix calculé / déduit du prix de base et des taxes.<br><br>
  </div>
</dx-popover>