import { Component, Input, ViewChild } from '@angular/core';
import { ClientPrixLexiClient, ClientPrixDto, PrixLexiClient, PrixDto } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-client-prix-list',
  templateUrl: './client-prix-list.component.html',
  styleUrls: ['./client-prix-list.component.scss'],
})
export class ClientPrixListComponent {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  private _clientId: number;
  get clientId(): number {
    return this._clientId;
  }
  @Input() set clientId(value: number) {
    if (value) {
      this._clientId = value;
      this.setDataSource();
      this.setListPrix();
    }
  }

  clientPrixList: ClientPrixDto[];
  prixDataSource: PrixDto[];

  constructor(
    private readonly clientPrixLexiClient: ClientPrixLexiClient,
    private readonly prixLexiClient: PrixLexiClient
  ) { }

  async setDataSource() {
    this.clientPrixList = await lastValueFrom(this.clientPrixLexiClient.getByClientId(this.clientId));
  }

  private async setListPrix() {
    const listePrix = await lastValueFrom(this.prixLexiClient.getListePrix());
    this.prixDataSource = listePrix.filter(x => !x.bloque);
  }

  async onRowInserting(clientPrix: ClientPrixDto) {
    clientPrix.clientId = this.clientId;
    await lastValueFrom(this.clientPrixLexiClient.createOrUpdate(clientPrix));
    await this.setDataSource();
  }

  async onRowUpdating(e: any) {
    for(const field of Object.keys(e.newData)) {
      e.oldData[field] = e.newData[field];
    }
    await lastValueFrom(this.clientPrixLexiClient.createOrUpdate(e.oldData));
    await this.setDataSource();
  }

  async onRowRemoving(clientPrix: ClientPrixDto) {
    await lastValueFrom(this.clientPrixLexiClient._delete(clientPrix.id));
    await this.setDataSource();
  }

  onReorder = async (e: any) => {
    const rows = e.component.getVisibleRows();
    const fromData: ClientPrixDto = e.itemData;
    const toData: ClientPrixDto = rows[e.toIndex].data;

    const fromDataDto = this.getDtoWithChangedIndex(fromData, toData.zIndex);
    const toDataDto = this.getDtoWithChangedIndex(toData, fromData.zIndex);

    await lastValueFrom(this.clientPrixLexiClient.createOrUpdate(fromDataDto));
    await lastValueFrom(this.clientPrixLexiClient.createOrUpdate(toDataDto));
    this.setDataSource();
  }

  private getDtoWithChangedIndex(dto: ClientPrixDto, newZIndex: number): ClientPrixDto {
    const newDto: ClientPrixDto = {};
    for(const field of Object.keys(dto)) {
      newDto[field] = dto[field];
    }
    newDto.zIndex = newZIndex;
    return newDto;
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.datagrid.instance.addRow();
    }
  }

  allowUpdating(e: any) {
    return e?.row?.data != null && e.row.data.clientReferenceId == null;
  }

  allowDeleting(e: any) {
    return e?.row?.data != null && e.row.data.clientReferenceId == null;
  }
}
