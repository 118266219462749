import { Component, Input } from '@angular/core';
import { VoyageFretLocalSettings, StatutRevatua, MotifRefusDemande} from '@lexi-clients/lexi';

@Component({
  selector: 'app-voyage-fret-local-settings-form',
  templateUrl: './voyage-fret-local-settings-form.component.html',
  styleUrls: ['./voyage-fret-local-settings-form.component.scss'],
})
export class VoyageFretLocalSettingsFormComponent {
  @Input() IsFicheVoyage: boolean = false;
  @Input() colCount: number = 1;
  @Input() colSpan: number = 1;
  @Input() formIsReadOnly: boolean = false;
  @Input() voyageFretLocalSettings: VoyageFretLocalSettings = {};
  @Input() motifRefusDemande: MotifRefusDemande[];
  StatutRevatua = StatutRevatua;

  readonly statutsRevatuaAcceptation = [
    { id: null, intitule: "Manuel" },
    { id: StatutRevatua.officialise, intitule: "Officialisé" },
    { id: StatutRevatua.officialisESousReserve, intitule: "Officialisé sous réserve" },
  ];

  readonly statutsRevatuaPayeurBloque = [
    { id: null, intitule: "Manuel" },
    { id: StatutRevatua.demandERefusee, intitule: "Demande refusée" },
    { id: StatutRevatua.officialisESousReserve, intitule: "Officialisé sous réserve" },
  ];

  getDescription(propriete: string) {
    const statut: StatutRevatua = this.voyageFretLocalSettings?.[propriete];
    switch (statut) {
      case null:
        return "Un.e intervenant.e interne doit valider ou refuser manuellement la demande";
      case StatutRevatua.officialise:
        return "La demande sera intégrée automatiquement à l'état 'Officialisé' dans REVATUA";
      case StatutRevatua.officialisESousReserve:
        return "La demande sera intégrée automatiquement à l'état 'Officialisé sous réserve' dans REVATUA";
      case StatutRevatua.demandERefusee:
        return "La demande sera intégrée automatiquement à l'état 'Demande refusée' dans REVATUA";
      default:
        return "";
    }
  }
}
