import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { BonAchatListResult } from '../models/bon-achat-list';
import { IGridService } from '../models/igrid-service';
import { BonAchatTpvDto } from '@lexi-clients/lexi';

@Injectable({ providedIn: 'root' })
export class BonAchatListService implements IGridService<BonAchatTpvDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<BonAchatListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/BonsAchatsTpv/recherche-devexpress`, { params: params });
  }

  getById(bonId: number): Observable<BonAchatTpvDto> {
    return null;
  }

  post(valeur: BonAchatTpvDto): Observable<any> {
    return null;
  }

  put(valeur: BonAchatTpvDto): Observable<any> {
    return null;
  }

  delete(valeur: BonAchatTpvDto): Observable<any> {
    return null;
  }
}
