import { Component, Input, ViewChild } from '@angular/core';
import { OperationCommercialeDetailDto, MethodeDeCalculDeRemise } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { OperationCommercialeDetailListService } from 'lexi-angular/src/app/services/operation-commerciale-detail.service';

@Component({
  selector: 'app-operation-commerciale-details-seuils',
  templateUrl: './operation-commerciale-details-seuils.component.html',
  styleUrls: ['./operation-commerciale-details-seuils.component.scss'],
})
export class OperationCommercialeDetailsSeuilsComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  private _operationCommercialeDetailDto: OperationCommercialeDetailDto;
  get operationCommercialeDetailDto(): OperationCommercialeDetailDto { return this._operationCommercialeDetailDto; }
  @Input() set operationCommercialeDetailDto(value: OperationCommercialeDetailDto) {
    if (value) {
      this._operationCommercialeDetailDto = value;
      this.setDataSource();
    }
  }

  methodeDataSource = [
    { id: MethodeDeCalculDeRemise.pourcentage, intitule: 'Pourcentage'},
    { id: MethodeDeCalculDeRemise.majoration, intitule: 'Majoration'},
    { id: MethodeDeCalculDeRemise.minoration, intitule: 'Minoration'},
    { id: MethodeDeCalculDeRemise.montant, intitule: 'Montant'},
    { id: MethodeDeCalculDeRemise.coefficient, intitule: 'Coefficient'}
  ]

  dtoDataSource: OperationCommercialeDetailDto;

  constructor(
    private readonly operationCommercialeDetailListService: OperationCommercialeDetailListService,
  ) { }

  onCreateUpdateDeleteSeuil() {
    this.operationCommercialeDetailListService.put(this.dtoDataSource).subscribe(() => {
        notify({ closeOnClick: true, message: 'Paramétrages des seuils modifiés'}, 'success');
    });
  }

  private setDataSource() {
    this.dtoDataSource = this._operationCommercialeDetailDto;

    // devextreme error E1052 dataSource doit être != null
    // Paramétrage par défaut pour la dataSource de la grille
    if(this.dtoDataSource.parametrageSeuils == null)
    {
      this.dtoDataSource.parametrageSeuils = [
        {
          methode: null,
          value: null,
          seuilMontant: null,
          seuilQuantite: null
        }
      ];
    }
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }
}
