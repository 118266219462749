import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteForUpdateDto, SitesLexiClient, CumulScanSurUnArticle, SocieteToUpdateDto, MotifRefusDemande, ConnaissementsRevatuaLexiClient, TypeUtilisateurRuunui,
  PartenaireType, LieuStockageDto, PartenaireDto, Permissions, LogistiqueSettings } from '@lexi-clients/lexi';
import { DxFormComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { BureauxDouaneListService } from 'lexi-angular/src/app/services/bureaux-douane.service';
import { DeviseListService } from 'lexi-angular/src/app/services/devise.service';
import { EntrepotTypeListService } from 'lexi-angular/src/app/services/entrepot-type.service';
import { LieuStockageListService } from 'lexi-angular/src/app/services/lieu-stockage.service';
import { PrixListService } from 'lexi-angular/src/app/services/prix.service';
import { SocieteService } from 'lexi-angular/src/app/services/societe.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { lastValueFrom, filter, Subscription } from 'rxjs';
import { InternationalisationPipe } from '../../../shared/pipes/internationalisation.pipe';
import { NotificationType } from '../../../shared/references/references';
import { PartenaireListService } from 'lexi-angular/src/app/services/partenaire.service';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { ObjectUtilitiesService } from 'lexi-angular/src/app/shared/services/object-utilities.service';

@Component({
  selector: 'app-site-detail',
  templateUrl: './site-detail.component.html',
  styleUrls: ['./site-detail.component.scss'],
})
export class SiteDetailComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @ViewChild('form') form: DxFormComponent;

  deviseDataSource: DataSource;
  entrepotTypeDataSource: DataSource;
  prixDataSource: DataSource;
  bureauxDouaneDataSource: DataSource;
  lieuStockageDataSource: DataSource;
  partenaireDataSource: DataSource;

  longueurCompte: number = 7;
  messageLongueurCompteInvalide: string;
  siteId: number;
  siteReadOnly = true;
  isFromSociete = false;

  isCreation = false;
  isModificationEnCours = false;
  actions: Array<{ id: string, intitule: string }>;
  site: SiteForUpdateDto;
  societeLogistiqueSettings: LogistiqueSettings;
  CumulScanArticleEnum = {
    ForceNouvelleLigne: 1,
    AuChoix: 2,
    ParDefautNouvelleLigne: 3,
    ParDefautCumul: 4,
    properties: [
      { id: CumulScanSurUnArticle.forceNouvelleLigne, description: "Forcer sur une nouvelle ligne" },
      { id: CumulScanSurUnArticle.auChoix, description: "Au choix" },
      { id: CumulScanSurUnArticle.parDefautNouvelleLigne, description: "Par défaut : Nouvelle ligne" },
      { id: CumulScanSurUnArticle.parDefautCumul, description: "Par défaut : Cumul" }
    ]
  };
  loadingVisible = false;
  motifRefusDemande: MotifRefusDemande[] = [];
  TypeUtilisateurRuunui = TypeUtilisateurRuunui;
  currentTypeUtilisateurRuunui: TypeUtilisateurRuunui;

  canGererLieuxStockages: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly sitesLexiClient: SitesLexiClient,
    private readonly deviseService: DeviseListService,
    private readonly entrepotTypeService: EntrepotTypeListService,
    private readonly prixService: PrixListService,
    private readonly bureauxDouaneService: BureauxDouaneListService,
    private readonly router: Router,
    private readonly lieuStockageListService: LieuStockageListService,
    private readonly societeService: SocieteService,
    private readonly authService: AuthService,
    private readonly internationalisationPipe: InternationalisationPipe,
    private readonly connaissementsRevatuaLexiClient: ConnaissementsRevatuaLexiClient,
    private readonly partenaireService: PartenaireListService,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly objectUtilitiesService: ObjectUtilitiesService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.canGererLieuxStockages = this.authService.isGrantedWith(Permissions.canGererLieuStockages);
    this.activatedRoute.params.subscribe(async params => {
      const siteId = params['id'];

      // Création d'un nouveau site
      if (isNaN(Number(siteId))) {
        // Accès au site en cours par la section 'Vente", mode lecture seule
        if (this.activatedRoute.snapshot.routeConfig.path == 'site-en-cours') {
          this.subscriptions.add(
            this.authService.currentSiteId$.pipe(
              filter((s: number) => s != null && s != undefined)
            ).subscribe((siteId: number) => {
              this.siteId = siteId;
            })
          );
        }
          else if (siteId === 'nouveau'){
          this.siteReadOnly = false;
          this.isCreation = true;
          this.setNewSite();
        }
        // Mauvais paramètre, on redirige sur la page de création d'un nouveau site
        else {
          this.router.navigate(['/administration/site/nouveau']);
          return;
        }
      }
      // Modification d'un site existant
      else {
        this.siteReadOnly = false;
        this.siteId = siteId;
        await this.setSite(Number(siteId));
      }

      this.currentTypeUtilisateurRuunui = await this.authService.getStoredUserTypeRuunui();

      this.subscriptions.add(
        this.societeService.currentSociete$.pipe(
          filter((societe: SocieteToUpdateDto) => societe != null)
        ).subscribe(async (societe: SocieteToUpdateDto) => {
          this.longueurCompte = societe.parametrageComptable?.longueurComptes ?? 7;
          this.societeLogistiqueSettings = societe.logistiqueSettings;
          if (this.siteId) {
            await this.setSite(this.siteId);
          }
          this.messageLongueurCompteInvalide = `Si renseigné, le compte doit comporter ${this.longueurCompte} caractères.`
          if (this.authService.currentSociete.fretLocalActif && this.currentTypeUtilisateurRuunui == TypeUtilisateurRuunui.armateur) {
            this.motifRefusDemande = await lastValueFrom(this.connaissementsRevatuaLexiClient.getListDemandeMotifsRefus());
          }
        })
      );

      // Initialisation des DataSources des listes déroulantes (SelectBox)
      this.initDataSourcesAnnexes();

      // Set de la liste déroulante des actions
      this.setActions();
    });
  }

  private setNewSite() {
    this.site = {
      codeBo: '',
      etablissement: '',
      telephone1: '',
      adresse1: '',
      fax: '',
      tpvSettings: {},
      fretLocalSettings: {}
    };
  }

  private async setSite(siteId: number) {
    this.site = await lastValueFrom(this.sitesLexiClient.getByIdForUpdate(siteId));
    if(this.site && !this.site.tpvSettings) this.site.tpvSettings = {};
    if(this.site && !this.site.fretLocalSettings) this.site.fretLocalSettings = {};
    if (this.site && !this.site.logistiqueSettings) {
      this.site.logistiqueSettings = this.objectUtilitiesService.deepCopy(this.societeLogistiqueSettings);
      this.isFromSociete = true;
    } else {
      this.isFromSociete = false;
    }
  }

  async onCreateNewSite() {
    const isValid = this.form.instance.validate().isValid;
    if (!isValid) { return; }

    this.loadingVisible = true;
    try {
      const newSiteId = await lastValueFrom(this.sitesLexiClient.create(this.site));
      notify({closeOnClick: true, message: `Création ${this.internationalisationPipe.transform("du_site", "du site")} effectuée avec succès`}, NotificationType.Success);
      this.isCreation = false;
      this.router.navigate([`/administration/site/${newSiteId}`]);
    } finally {
      this.loadingVisible = false;
    }
  }

  async onUpdateSite(message: string = null) {
    // pas de validation si validators non valides
    const isValid = this.form.instance.validate().isValid;
    if (!isValid) { return; }

    try {
      // On empêche la modification pendant l'appel au serveur
      this.isModificationEnCours = false;
      this.compareLogistiqueSettingsSiteEtSociete();
      await lastValueFrom(this.sitesLexiClient.updateSite(this.site.id, this.site));
    }
    catch {
      // Si erreur, on permet de revenir sur les changements
      this.isModificationEnCours = true;
    }
    this.setActions();
    if (!message) {
      message = `Modification ${this.internationalisationPipe.transform("du_site", "du site")} effectuée avec succès`;
    }
    notify({closeOnClick: true, message: message}, NotificationType.Success);
    await this.setSite(this.site.id);
  }

  async onCancelUpdate() {
    this.isModificationEnCours = false;
    await this.setSite(this.site.id);
  }

  private async activerSite() {
    this.site.actif = true;
    this.onUpdateSite(`Activation ${this.internationalisationPipe.transform("du_site", "du site")} effectuée avec succès`);
  }

  private async desactiverSite() {
    this.site.actif = false;
    this.onUpdateSite(`Désactivation ${this.internationalisationPipe.transform("du_site", "du site")} effectuée avec succès`);
  }

  private setActions() {
    if (this.site?.actif) {
      this.actions = [
        { id: 'edit', intitule: 'Modifier' },
        { id: 'desactiverSite', intitule: `Désactiver ${this.internationalisationPipe.transform("le_site", "le site")}` },
      ];
    }
    else {
      this.actions = [
        { id: 'edit', intitule: 'Modifier' },
        { id: 'activerSite', intitule: `Activer ${this.internationalisationPipe.transform("le_site", "le site")}` },
      ];
    }
  }

  private compareLogistiqueSettingsSiteEtSociete() {
    if (this.objectUtilitiesService.isDeepEqual(this.site.logistiqueSettings, this.societeLogistiqueSettings)) {
      this.site.logistiqueSettings = null;
    }
  }

  onActionClick(e: any) {
    if( e.itemData.id == "edit") {
      this.isModificationEnCours = true;
    }

    if( e.itemData.id == "activerSite") {
      this.activerSite();
    }

    if( e.itemData.id == "desactiverSite") {
      this.desactiverSite();
    }
  }

  lieuStockageDisplayExpr(data: LieuStockageDto) {
    return data && `${data.codeBo} - ${data.intitule}`
  }

  partenaireDisplayExpr(data: PartenaireDto) {
    return data && `${data.codeBo} - ${data.intitule}`
  }

  // Nécessaire car le composant SelectBox freeze si on ne pagine pas
  private initDataSourcesAnnexes() {
    this.entrepotTypeDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.entrepotTypeService);
    this.prixDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.prixService);
    this.bureauxDouaneDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.bureauxDouaneService);
    this.deviseDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.deviseService);

    const lieuStockageParams = new Map();
    if (this.siteId != null) lieuStockageParams.set("siteId", this.siteId);
    this.lieuStockageDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.lieuStockageListService, lieuStockageParams);
    const partenaireAdditionalParams = new Map().set("partenaireType", PartenaireType.interne);
    this.partenaireDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.partenaireService, partenaireAdditionalParams);
  }
}
