import { LieuStockageDto } from '@lexi-clients/lexi';
import { IGridResult } from './igrid-service';

export interface LieuStockageListResult
  extends IGridResult<LieuStockageDto> {
  data: LieuStockageDto[];
  totalCount: number;
  groupCount: number;
  summary: any;
}
export const lieuStockagePartenaireExterneDefaultCodeBo = "EXTPART";
export const lieuStockageFlottantDefaultCodeBo = "FLOTDEF";
export const lieuStockageReguleCodeBo = "REGULDEF";