import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConditionnementsLexiClient, GetConditionnementDto, UniteDouaneDto, UniteDto, UnitesDouanesLexiClient, UnitesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-conditionnement-list',
  templateUrl: './conditionnement-list.component.html',
  styleUrls: ['./conditionnement-list.component.scss']
})
export class ConditionnementListComponent implements OnInit {
  @Input() articleId: number;
  @Input() uniteBaseId: number;
  conditionnements: GetConditionnementDto[];
  uniteDataSource: UniteDto[];
  uniteDouaneDataSource: UniteDouaneDto[];
  uniteBase: UniteDto;

  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  constructor(
    private readonly conditionnementsLexiClient: ConditionnementsLexiClient,
    private readonly uniteService: UnitesLexiClient,
    private readonly uniteDouaneService: UnitesDouanesLexiClient
  ) {}

  async ngOnInit() {
    await this.setConditionnements();
    await this.setUnites();
  }

  async onRowInserting(conditionnement: GetConditionnementDto) {
    // synchronized est obligatoire alors on force une valeur dans le cas où
    // synchronized n'est pas défini
    if (!conditionnement._synchronized) {
      conditionnement._synchronized = false;
    }
    if (!conditionnement.poidsNet) {
      conditionnement.poidsNet = 0;
    }

    conditionnement.articleId = this.articleId;
    await lastValueFrom(this.conditionnementsLexiClient.createOrUpdate(conditionnement));
    await this.setConditionnements();
  }

  async onRowUpdating(e: any) {
    for (const field of Object.keys(e.newData)) {
      e.oldData[field] = e.newData[field];
    }
    await lastValueFrom(this.conditionnementsLexiClient.createOrUpdate(e.oldData));
    await this.setConditionnements();
  }

  async onRowRemoving(conditionnement: GetConditionnementDto) {
    await lastValueFrom(this.conditionnementsLexiClient._delete(conditionnement.id));
    await this.setConditionnements();
  }

  async setConditionnements() {
    this.conditionnements = await lastValueFrom(this.conditionnementsLexiClient.getByArticleId(this.articleId));
  }

  private async setUnites() {
    this.uniteDataSource = await lastValueFrom(this.uniteService.getListeUnites());
    this.uniteBase = this.uniteDataSource.find(u => u.id === this.uniteBaseId);
    this.uniteDouaneDataSource = await lastValueFrom(this.uniteDouaneService.getListeUnitesDouanes());
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.datagrid.instance.addRow();
    }
  }
}
