import { Component, OnInit, } from '@angular/core';
import notify from 'devextreme/ui/notify';
import themes from 'devextreme/ui/themes';
import { NotificationType } from '../../modules/shared/references/references';
import { lastValueFrom } from 'rxjs';
import { PersonnalisationGrillesLexiClient } from '@lexi-clients/lexi';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  selectBoxOptions: any;
  themesList: any;
  selectedTheme: any;

  constructor(private readonly personnalisationGrilles: PersonnalisationGrillesLexiClient) {
     this.themesList = [
      { id: 'material.blue.light.compact', text: 'Blue Light Compact', type: 'material' },
      { id: 'material.blue.dark.compact', text: 'Blue Dark Compact', type: 'material' },
      { id: 'material.lime.light.compact', text: 'Lime Light Compact', type: 'material' },
      { id: 'material.lime.dark.compact', text: 'Lime Dark Compact', type: 'material' },
      { id: 'material.orange.light.compact', text: 'Orange Light Compact', type: 'material' },
      { id: 'material.orange.dark.compact', text: 'Orange Dark Compact', type: 'material' },
      { id: 'material.purple.light.compact', text: 'Purple Light Compact', type: 'material' },
      { id: 'material.purple.dark.compact', text: 'Purple Dark Compact', type: 'material' },
      { id: 'material.teal.light.compact', text: 'Teal Light Compact', type: 'material' },
      { id: 'material.teal.dark.compact', text: 'Teal Dark Compact', type: 'material' },

      { id: 'generic.light', text: 'Light' },
      { id: 'generic.light.compact', text: 'Light Compact' },
      { id: 'generic.dark', text: 'Dark' },
      { id: 'generic.dark.compact', text: 'Dark Compact' },
      { id: 'generic.carmine', text: 'Carmine' },
      { id: 'generic.carmine.compact', text: 'Camine Compact' },
      { id: 'generic.softblue', text: 'Softblue' },
      { id: 'generic.softblue.compact', text: 'Soft Blue Compact' },
      { id: 'generic.darkmoon', text: 'Darkmoon' },
      { id: 'generic.darkmoon.compact', text: 'Dark Moon Compact' },
      { id: 'generic.darkviolet', text: 'Darkviolet' },
      { id: 'generic.darkviolet.compact', text: 'Dark Violet Compact' },
      { id: 'generic.greenmist', text: 'Greenmist' },
      { id: 'generic.greenmist.compact', text: 'Green mist Compact' },
      { id: 'generic.contrast', text: 'Contrast' },
      { id: 'generic.contrast.compact', text: 'Contrast Compact' },
    ];
   }

  ngOnInit() {
    this.selectedTheme = window.localStorage.getItem('dx-theme');
    const selectedTheme = this.themesList.find(t => t.id === this.selectedTheme);
    if (selectedTheme === undefined) {
      this.selectedTheme = 'material.orange.light.compact';
    }
  }

  onThemeChange(event) {
    if(event.value) {
      this.setTheme(event.value);
    }
  }

  setTheme(themeId: any) {
    if (themeId) {
      const newTheme = this.themesList.find(t => t.id === themeId);
      const previousTheme = this.themesList.find(t => t.id === this.selectedTheme);
      window.localStorage.setItem('dx-theme', themeId);
      if(newTheme?.type !== previousTheme?.type) {
        window.location.reload();
      } else {
        themes.current(themeId);
        this.selectedTheme = themeId;
      }
    }
  }

  async onSupprimerStateStorage() {
    await lastValueFrom(this.personnalisationGrilles.resetAllForCurrentUser());

    const keywords = ['grille', 'DataGrid', 'treeList'];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && keywords.some(keyword => key.includes(keyword))) {
        localStorage.removeItem(key);
      }
    }

    notify({closeOnClick: true, message: "Les préférences de grilles ont été supprimées avec succès"}, NotificationType.Success);
  }
}
