import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectType, TrackingItemDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { TrackingItemGridResult } from '../models/tracking';

@Injectable({ providedIn: 'root' })
export class TrackingItemService implements IGridService<TrackingItemDto> {
  baseUrl: string;
  previousGetParams: HttpParams;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<TrackingItemGridResult> {
    this.previousGetParams = params;
    return this.http.get<any>(`${this.baseUrl}/api/Tracking/search`, { params: params });
  }

  post(valeur: TrackingItemDto): Observable<any> {
    return null;
  }

  put(valeur: TrackingItemDto): Observable<any> {
    return null;
  }

  delete(valeur: TrackingItemDto): Observable<any> {
    return null;
  }
}


const urlPaths = [
  { [ObjectType.article]: 'article' }
];

/**
 * Map de préfixe d'URL par ObjectType
 * Exemple: { article: 'http://localhost:5000/article' }
 * Le composant utilisant cette map devra compléter l'URL avec l'ID de l'objet
 */
export const objectNavigations = urlPaths.reduce((acc, next) => {
  acc[Object.keys(next)[0]] = location.origin + '/' + Object.values(next)[0];
  return acc;
}, {});
