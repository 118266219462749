<div class="dx-theme-text-color-as-background-color">
  <div class="menu-container">
    <dx-tree-view
      class="dx-theme-background-color-as-text-color"
      [items]="items"
      keyExpr="path"
      selectionMode="single"
      [focusStateEnabled]="false"
      [hoverStateEnabled]="true"
      expandEvent="click"
      (onItemClick)="onItemClick($event)"
      (onContentReady)="onContentReady($event)"
      width="100%"
    ></dx-tree-view>
  </div>

  <div class="footer dx-theme-text-color-as-background-color dx-theme-background-color-as-text-color">
    <div class="content">
      <app-footer>
        <a *ngIf="isPartenaire" href="/conditions/cg.pdf" download>Conditions générales</a>
        <br/>
        Copyright © 2022-{{appInfo.currentYear}} {{appInfo.title}} Inc.
        <br/>
        Client v{{appInfo.lexiVersionAngular}} - API v{{appInfo.lexiVersionAPI }} -
        <a routerLink="/roadmap">Roadmap</a>
        <br/>
        All trademarks or registered trademarks are property of their respective owners.
        <br />
        <a href="https://www.flaticon.com/free-icons/box" title="box icons">Box icons created by Vectors Market - Flaticon</a>
      </app-footer>
    </div>
  </div>
</div>