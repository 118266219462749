<dx-drop-down-button
    text="Éditions"
    [items]="editionsPossible"
    displayExpr="intitule"
    keyExpr="id"
    (onItemClick)="onDownloadEdition($event)"
    [dropDownOptions]="{
      width: 200
    }"
    [visible]="isDropDownButton"
  ></dx-drop-down-button>

<dx-select-box
  [dataSource]="editionsPossible"
  displayExpr="intitule"
  [searchEnabled]="true"
  [showClearButton]="true"
  [searchExpr]="['intitule']"
  [showDataBeforeSearch]="true"
  valueExpr="id"
  placeholder="Sélectionner le type d'étiquette"
  [width]="350"
  [visible]="!isDropDownButton"
  (onValueChanged)="handleEditionTemplateChange($event)"
></dx-select-box>
