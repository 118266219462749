import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FactureDto, FacturesLexiClient, OptionTelechargementPlusieursPdfs, PiecesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';
import { DownloadService } from '../../services/download.service';

@Component({
  selector: 'app-facture-list',
  templateUrl: './facture-list.component.html',
  styleUrls: ['./facture-list.component.scss'],
})
export class FactureListComponent implements OnInit {
  @Input() clientId: number;

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  listeOptionsDeTelechargement: any;
  anyRowSelected = false;
  factures: FactureDto[];

  constructor(
    private readonly downloadService: DownloadService,
    private readonly piecesLexiClient: PiecesLexiClient,
    private readonly facturesClient: FacturesLexiClient,
  ) { }

  async ngOnInit() {
    this.listeOptionsDeTelechargement = this.downloadService.getOptionsTelechargementPdfs();
    await this.setFactures();
  }

  async setFactures() {
    this.factures = await lastValueFrom(this.facturesClient.getByClient(this.clientId));
  }

  onDownloadPdfList(event: any) {
    const option: {id: OptionTelechargementPlusieursPdfs, libelle: string} = event.itemData;
    const selectedRowsData: FactureDto[] = this.dataGrid.instance.getSelectedRowsData();
    const factureIds: number[] = [];

    for(const data of selectedRowsData) {
      if(data.id) {
        factureIds.push(data.id);
      }
    }

    this.piecesLexiClient.getPdfOrZipFile(factureIds, option.id, 'response').subscribe( response => {
      this.downloadService.directDownloadFile(response, option.id === OptionTelechargementPlusieursPdfs.pdf ? '.pdf' : '.zip');
    });
  }

  checkIsAnyRowSelected() {
    const selectedRowsData = this.dataGrid.instance.getSelectedRowsData();
    this.anyRowSelected = selectedRowsData.length > 0;
  }

  onDownloadPdf = (element: any) =>  {
    element.event.preventDefault(); // empêche la redirection
    const facture = element.row.data;

    this.piecesLexiClient.getPdf(facture.id, 'response')
      .subscribe(response => this.downloadService.directDownloadFile(response, '.pdf'));
  }

  getTexteLignesSelectionees() {
    const selectedRows = this.dataGrid?.instance.getSelectedRowsData();
    const s = selectedRows?.length > 1 ? 's' : '';
    return `${selectedRows?.length} ligne${s} sélectionnée${s}`
  }

}
