
import { Component, Input, OnInit } from '@angular/core';
import { ConnaissementDetailDto, ConnaissementDetailsLexiClient, ConnaissementLexiResultDto, StockageConnaissement, UnitePoidsConnaissement, UniteVolumeConnaissement } from '@lexi-clients/lexi';



@Component({
  selector: 'app-connaissement-info',
  templateUrl: './connaissement-info.component.html',
  styleUrls: ['./connaissement-info.component.scss'],
})
export class ConnaissementInfoComponent implements OnInit {

  _selectedConnaissementDetail: ConnaissementLexiResultDto;

  @Input() set selectedConnaissementDetail(selectedConnaissementDetail: ConnaissementLexiResultDto) {
    if (selectedConnaissementDetail != undefined) {
      this._selectedConnaissementDetail = selectedConnaissementDetail;
    
      this.connaissementDetailClient.getByConnaissementId(selectedConnaissementDetail.id).subscribe(data => this.connaissementDetails = data);
    }
   
  }

  connaissementDetails: ConnaissementDetailDto[];
  columns: any[];

  columnsChargeur = [
    { dataField: 'nombreColis', caption: 'Quantité' },
    { dataField: 'designation', caption: 'Désignation', width: "270" },
    { dataField: 'volume', caption: 'Volume', cellTemplate: "volumeTemplate"},
    { dataField: 'poids', caption: 'Poids', cellTemplate: "poidTemplate" },
    { dataField: 'stockage', caption: 'Stockage', cellTemplate: "stockageTemplate" },
    { dataField: 'montantDeclare', caption: 'Montant Déclaré' },
    { dataField: 'montantTotalHT', caption: 'Montant Total HT' },
    { dataField: 'codeSH', caption: 'Nomenclature' },
    { dataField: 'articleTransporteCode', caption: 'Article code', visible: false },
    { dataField: 'articleTransporteIntitule', caption: 'Article intitulé', visible: false },
    { dataField: 'tarifIntitule', caption: 'Tarif intitulé',  visible: false },
    { dataField: 'codeTarif', caption: 'Code Tarif',visible: false },
  ];

  constructor(
    private connaissementDetailClient: ConnaissementDetailsLexiClient
  ) { }

  ngOnInit() {}

  convertUnitePoidsToString(unitePoid: UnitePoidsConnaissement, poids: number) {
    switch(unitePoid) {
      case UnitePoidsConnaissement.kilo:
        return 'kg';
      case UnitePoidsConnaissement.tonne:
        return poids <= 1 ? 'tonne' : 'tonnes';
    }
  }

  convertUniteVolumeToString(uniteVolume: UniteVolumeConnaissement, volumes: number) {
    switch(uniteVolume) {
      case UniteVolumeConnaissement.litre:
        return volumes == 1 ? 'litre' : 'litres';
      default:
        return uniteVolume;
    }
  }

  convertUniteStockageToString(stockage: StockageConnaissement) {
    switch(stockage) {
      case StockageConnaissement.cale:
        return 'Cale';
      case StockageConnaissement.congele:
        return 'Congelé';
      case StockageConnaissement.refrigere:
        return 'Réfrigéré';
    }
  }
  
}
