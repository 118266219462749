import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { GetConditionnementDto } from '@lexi-clients/lexi';
import { ConditionnementListResult } from '../models/conditionnement-list';

@Injectable({
  providedIn: 'root'
})
export class ConditionnementListService implements IGridService<GetConditionnementDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<ConditionnementListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Conditionnements/recherche-devexpress`, { params: params });
  }

  getById(conditionnementId: number): Observable<GetConditionnementDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Conditionnements/${conditionnementId}`);
  }

  post(valeur: GetConditionnementDto): Observable<any> {
    return null;
  }

  put(valeur: GetConditionnementDto): Observable<any> {
    return null;
  }

  delete(valeur: GetConditionnementDto): Observable<any> {
    return null;
  }
}
