import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { ClassificationTypeDto } from '@lexi-clients/lexi';
import { ClassificationTypeListResult } from '../models/classification-type-list';

@Injectable({
  providedIn: 'root'
})
export class ClassificationTypeService implements IGridService<ClassificationTypeDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<ClassificationTypeListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/ClassificationTypes/recherche-devexpress`, { params: params });
  }

  getById(id: number): Observable<ClassificationTypeDto> {
    return this.http.get<any>(`${this.baseUrl}/api/ClassificationTypes/${id}`);
  }

  post(valeur: ClassificationTypeDto): Observable<any> {
    return null;
  }

  put(valeur: ClassificationTypeDto): Observable<any> {
    return null;
  }

  delete(valeur: ClassificationTypeDto): Observable<any> {
    return null;
  }
}
