<dx-box direction="row" width="100%" crossAlign="center" class="dx-card" style="padding-right: 12px; background-color: rgb(153 193 191);">
  <!-- Bouton retour arrière -->
  <dxi-item [ratio]="2">
    <div *dxTemplate style="display: flex; flex-direction: row;">
      <a routerLink="/visualisation-des-bons" style="margin-right: 6px;">
        <dx-button icon="back" [elementAttr]="{ style: 'background-color: rgb(153 193 191);' }"></dx-button>
      </a>
      <div *ngIf="!isCreation" style="display: flex; align-items: center; font-size: 16px;">
        <app-flux-statut-icon [statut]="bon?.fluxStatut" [title]="bonStatut"></app-flux-statut-icon>
        <app-flux-etape-icon *ngIf="bon?.fluxStatut" [objectType]="ObjectType.bon" [evenementTypeId]="bon?.evenementTypeId" [title]="bon?.evenementIntitule"></app-flux-etape-icon>
        <div style="margin-left: 6px; height: 38px; line-height: 18px; margin-top:2px">Bon <strong>{{ bon?.id }}</strong> <br/>
          <span style="font-size: 12px">{{ bon?.codeBo }}</span></div>
        <app-tooltip-date-creation [dateCreation]="bon?.datePremiereEtape" [hasDateCreationBon]="bon?.datePremiereEtape != null" style="margin-left: 3px;"></app-tooltip-date-creation>
      </div>
    </div>
  </dxi-item>

  <!-- Titre -->
  <dxi-item [ratio]="4">
    <div style="display: flex; text-align: center; align-items: center; justify-content: center;">
      <span style="font-size: 24px; margin-right: 1rem;" *ngIf="!isModificationEnCours && bon != null && bon.intitule != null"> {{bon?.intitule}} </span>
      <span style="font-size: 24px; margin-right: 1rem;" *ngIf="!isModificationEnCours && bon != null && bon.intitule == null"> {{mouvementTypeIntitule}} </span>
      <dx-text-box style="font-size: 24px; margin-right: 1rem;" *ngIf="isModificationEnCours && bon != null" [(value)]="bon.intitule"></dx-text-box>
    </div>
  </dxi-item>

  <!-- Dates -->
  <dxi-item [ratio]="2">
    <div *dxTemplate style="display: flex; align-items: flex-end;">
      <div *ngIf="!isModificationEnCours && bon?.dateOperation">Date opération : {{bon?.dateOperation | date: 'dd/MM/yyyy à HH:mm'}}</div>
      <dx-form [formData]="bon" *ngIf="!isCreation && isModificationEnCours">
        <dxi-item dataField="dateOperation" [label]="{ text: 'Date opération' }" editorType="dxDateBox"
          [editorOptions]="{displayFormat: 'dd/MM/yyyy HH:mm', width: '170px', type: 'datetime', readOnly: true}"
        ></dxi-item>
      </dx-form>
    </div>
  </dxi-item>
</dx-box>