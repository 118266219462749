import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { PartenaireListService } from 'lexi-angular/src/app/services/partenaire.service';
import { CaisseTypeAuthentification, CumulScanSurUnArticle, TypeRemiseDto, TypeRemisesLexiClient,
  ElevationPrivilegeAuthentificationType, PartenaireDto, OptionPrelevementClotureCaisse, LieuStockageDto,
  LieuStockagesLexiClient} from '@lexi-clients/lexi';
import { TpvSettings } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tpv-settings-form',
  templateUrl: './tpv-settings-form.component.html',
  styleUrls: ['./tpv-settings-form.component.scss'],
})
export class TpvSettingsFormComponent implements OnInit {
  @Input() colCount: number = 1;
  @Input() colSpan: number = 1;
  @Input() tpvSettings: TpvSettings = {};
  @Input() formIsReadOnly: boolean = false;
  lieuStockageDataSource: LieuStockageDto[] = [];

  private _siteId: number;
  get siteId(): number {
    return this._siteId;
  }
  @Input() set siteId(value: number) {
    if (value != null && value != undefined) {
      this._siteId = value;
      this.setLieuStockageDataSource();
    }
  }
  
  private _isLieuxStockageForSite: boolean;
  get isLieuxStockageForSite(): boolean {
    return this._isLieuxStockageForSite;
  }
  @Input() set isLieuxStockageForSite(value: boolean) {
    if (value != null) {
      this._isLieuxStockageForSite = value;
      if (value == false) this.setLieuStockageDataSource();
    }
  }

  readonly caisseTypeAuthentification = [
    { id: CaisseTypeAuthentification.domainWindows, intitule: "Domaine Windows" },
    { id: CaisseTypeAuthentification.localLexi, intitule: "Lexi local" },
    { id: CaisseTypeAuthentification.sessionWindows, intitule: "Session Windows" },
  ];

  readonly elevationPrivilegeAuthentificationType = [
    { id: ElevationPrivilegeAuthentificationType.badgeOnly, intitule: "Badge uniquement" },
    { id: ElevationPrivilegeAuthentificationType.badgeAndPassword, intitule: "Badge et code PIN" },
    { id: ElevationPrivilegeAuthentificationType.domainWindows, intitule: "Domaine Windows" },
  ];

  readonly cumulScanSurUnArticleDataSource = [
    { id: CumulScanSurUnArticle.forceNouvelleLigne, intitule: "Forcer sur une nouvelle ligne"},
    { id: CumulScanSurUnArticle.auChoix, intitule: "Au choix"},
    { id: CumulScanSurUnArticle.parDefautNouvelleLigne, intitule: "Par défaut: Nouvelle ligne"},
    { id: CumulScanSurUnArticle.parDefautCumul, intitule: "Par défaut : Cumul"},
  ];

  readonly optionPrelevementClotureCaisseDataSource = [
    { id: OptionPrelevementClotureCaisse.none, intitule: "Prélèvement libre"},
    { id: OptionPrelevementClotureCaisse.vider, intitule: "Vider la caisse"},
    { id: OptionPrelevementClotureCaisse.fondReference, intitule: "Atteindre le fond de référence"},
  ];

  partenaireDataSource: DataSource;
  typeRemises: TypeRemiseDto[];

  constructor(
    private readonly partenaireService: PartenaireListService,
    private readonly typeRemisesLexiClient: TypeRemisesLexiClient,
    private readonly lieuStockageLexiClient: LieuStockagesLexiClient
  ) { }

  async ngOnInit() {
    const additionalParams = new Map().set("estClientOnly", true);
    this.partenaireDataSource = new DataSource({
      key: 'id',
      sort: [
        { selector: "Intitule", desc: false }
      ],
      paginate: true,
      pageSize: 10,
      store: new CustomStoreService(this.partenaireService).getSelectBoxCustomStore(additionalParams)
    });
    this.typeRemises = await lastValueFrom(this.typeRemisesLexiClient.getAll());
  }

  partenaireDisplayExpr(data: PartenaireDto) {
    return data && `${data.codeBo} - ${data.intitule}`
  }

  async setLieuStockageDataSource() {
    if (this.isLieuxStockageForSite && this.siteId == null) return;
    this.lieuStockageDataSource = await lastValueFrom(this.lieuStockageLexiClient.getIfFonds(this.siteId, this.isLieuxStockageForSite));
  }
}