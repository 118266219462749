import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { Subscription, filter } from 'rxjs';
import { ConfigFiltreMouvement } from '../mouvement-stock-datagrid/mouvement-stock-datagrid.component';

@Component({
  selector: 'app-mouvement-stock-view',
  templateUrl: './mouvement-stock-view.component.html',
  styleUrls: ['./mouvement-stock-view.component.scss'],
})
export class MouvementStockViewComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly FILTRES_STORAGE_KEY = "MOUVEMENT_STOCK_VIEW_FILTRES"
  currentSiteId: number;
  currentPartenaireId: number;
  filtres: { };
  configFiltre = <ConfigFiltreMouvement>({
    isByPartenaire: true,
    isBySociete: false,
    isByBon: false,
    isByDocumentLogistique: false,
    isByArticle : false,
  });
  
  constructor(
    private readonly authService: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.setFiltresOnInit();
    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe((siteId: number) => {
        this.currentSiteId = siteId
        this.currentPartenaireId = this.authService.currentSite.partenaireId;
      })
    );
  }

  private setFiltresOnInit() {
    const storedFiltresString = localStorage.getItem(this.FILTRES_STORAGE_KEY);
    this.filtres = storedFiltresString ? JSON.parse(storedFiltresString) : { };
  }

  onValueChanged = () => {
    localStorage.setItem(this.FILTRES_STORAGE_KEY, JSON.stringify(this.filtres));
  }

}
