import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConditionnementsLexiClient, CodeBarresLexiClient, UniteDto, CodeBarreACreerDto, CodeBarreValidite, GetConditionnementDto, CodeBarreDto, ModifierCodeBarreDto, UnitesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-code-barre-article-detail',
  templateUrl: './code-barre-article-detail.component.html',
  styleUrls: ['./code-barre-article-detail.component.scss']
})
export class CodeBarreArticleDetailComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @Input() articleId: number;
  @Input() uniteBaseId: number;
  codesBarres: CodeBarreDto[] = [];
  conditionnements: GetConditionnementDto[] = [];
  uniteBase: UniteDto;

  constructor(
    private readonly conditionnementsLexiClient: ConditionnementsLexiClient,
    private readonly codeBarresLexiClient: CodeBarresLexiClient,
    private readonly uniteLexiClient: UnitesLexiClient,
  ) {}

  ngOnInit(): void {
    this.load();
  }

  async onRowInserting(e) {
    const { code, conditionnementId } = e.data;
    const conditionnement = conditionnementId === 0 ? null : this.conditionnements.find(c => c.id === conditionnementId);

    const dto: CodeBarreACreerDto = {
      articleId: this.articleId,
      code,
      coefficient: conditionnement?.coefficient,
      uniteId: conditionnement?.uniteId,
      valide: CodeBarreValidite.valide
    };

    await lastValueFrom(this.codeBarresLexiClient.enregistrerCodesBarres([dto]));
    await this.load();
  }

  async onRowUpdating(e) {
    const { id, code, conditionnementId } = {...e.oldData, ...e.newData} as CodeBarreDto;

    const dto: ModifierCodeBarreDto = {
      id,
      code,
      conditionnementId: conditionnementId === 0 ? null : conditionnementId
    };

    await lastValueFrom(this.codeBarresLexiClient.modifierCodeBarres(dto));
    await this.load();
  }

  async onRowRemoving(e) {
    await lastValueFrom(this.codeBarresLexiClient.supprimerCodeBarres([e.data.id]));
    await this.load();
  }

  async load() {
    if (!this.articleId) return;

    this.codesBarres = (await lastValueFrom(this.codeBarresLexiClient.getByArticleId(this.articleId))).map(c => {
      c.conditionnementId = c.conditionnementId === null ? 0 : c.conditionnementId;
      return c;
    });

    this.conditionnements = await lastValueFrom(this.conditionnementsLexiClient.getByArticleId(this.articleId));
    this.conditionnements = [{ id: 0, libelle: 'Unité de base', coefficient: 1 }, ...this.conditionnements];
    if (this.uniteBaseId) {
      this.uniteBase = await lastValueFrom(this.uniteLexiClient.getById(this.uniteBaseId));
    }
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }
}
