import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, EMPTY, Observable, throwError } from "rxjs";
import notify from 'devextreme/ui/notify';
import { AuthService } from "../settings/auth.service";

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(catchError(err => {
      const toastOptions = {
        closeOnClick: true,
        message: null
      };

      if (err.status == 401) {
        this.authService.logout();
        toastOptions.message = "Votre connexion a expiré. Merci de vous reconnecter pour continuer.";
        notify(toastOptions, "error", 20000);
        return EMPTY;
      }

      if (err.status != null) {
        let message = err.error ?? "erreur inconnue";

        if (message instanceof Blob) {
          message.text().then(text => {
            toastOptions.message = "Une erreur est survenue sur le serveur : " + text;
            notify(toastOptions, "error", 20000);
          });

          return EMPTY;
        }

        if (!(typeof message === 'string' || message instanceof String)){
          message = JSON.stringify(message);
        }

        if (err.error == null && err.status == 403) {
          message = "Vous n'avez pas la permission pour faire cette action.";
        }

        toastOptions.message = "Une erreur est survenue sur le serveur : " + message;
        notify(toastOptions, "error", 20000);
        return EMPTY;

      }

      return throwError(() => new Error(err))

    }));
  }
}
