import { Injectable } from '@angular/core';

@Injectable()
export class CalculInfoPrixService {

  constructor() { }

  /** Marge Brute HT = Prix de vente HT – PMP */
  calculateMargeBrute(montantHt: number, pmp: number) {
    return montantHt - pmp;
  }

  /** Taux de marge = Marge brute HT / PMP */
  calculateTauxMarge(montantHt: number, pmp: number) {
    const margeBrute = this.calculateMargeBrute(montantHt, pmp);
    return (pmp == 0 ? null : margeBrute / pmp);
  }

  /** Taux de marque = Marge brute HT / Prix de vente HT */
  calculateTauxMarque(montantHt: number, pmp: number) {
    const margeBrute = this.calculateMargeBrute(montantHt, pmp);
    return (pmp == 0 ? null : margeBrute / montantHt);
  }

  /** Coefficient = Prix de vente HT / PMP */
  calculateCoefficient(montantHt: number, pmp: number) {
    return pmp == 0 ? null : montantHt / pmp;
  }
}
