import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { BureauxDouaneListResult } from '../models/bureaux-douane-list';
import { BureauxDouaneDto } from '@lexi-clients/lexi';

@Injectable({
  providedIn: 'root'
})
export class BureauxDouaneListService implements IGridService<BureauxDouaneDto> {
  baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    readonly envService: EnvironmentService
  ) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<BureauxDouaneListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/BureauxDouane/rechercher`, { params: params });
  }

  getById(id: number): Observable<BureauxDouaneDto> {
    return this.http.get<any>(`${this.baseUrl}/api/BureauxDouane/${id}`);
  }

  post(valeur: BureauxDouaneDto): Observable<any> {
    return null;
  }

  put(valeur: BureauxDouaneDto): Observable<any> {
    return null;
  }

  delete(valeur: BureauxDouaneDto): Observable<any> {
    return null;
  }
}
