import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegistreNumeroSerieDto } from '@lexi-clients/lexi';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { IGridResult } from '../models/igrid-service';

export interface RegistreNumeroSerieListResult extends IGridResult<RegistreNumeroSerieDto> {
  data: RegistreNumeroSerieDto[];
  totalCount: number;
  groupCount: number;
  summary: any;
}

@Injectable({
  providedIn: 'root'
})
export class RegistreNumeroSerieService implements IGridService<RegistreNumeroSerieDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<RegistreNumeroSerieListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/RegistresNumerosSerie/recherche-devexpress`, { params: params });
  }

  getById(id: number): Observable<RegistreNumeroSerieDto> {
    return this.http.get<any>(`${this.baseUrl}/api/RegistresNumerosSerie/${id}`);
  }

  post(valeur: RegistreNumeroSerieDto): Observable<any> {
    return null;
  }

  put(valeur: RegistreNumeroSerieDto): Observable<any> {
    return null;
  }

  delete(valeur: RegistreNumeroSerieDto): Observable<any> {
    return null;
  }
}
