<dx-form style="margin: 10px 0 0 10px;" labelLocation="left" [formData]="this">
    <dxi-item itemType="group" colCount="4">
        <!-- Date début -->
        <dxi-item dataField="dateDebut"
            [label]="{ text: 'Début' }"
            editorType="dxDateBox"
            [editorOptions]="{
                showClearButton: true
            }"
        ></dxi-item>

        <!-- Date fin -->
        <dxi-item dataField="dateFin"
            [label]="{ text: 'Fin' }"
            editorType="dxDateBox"
            [editorOptions]="{
                showClearButton: true
            }"
        ></dxi-item>

        <!-- Rechercher -->
        <dxi-item editorType="dxButton" [label]="{ text: ' ' }" [editorOptions]="{text: 'Rechercher', onClick: setBonsAchat}"></dxi-item>

        <!-- En cours de validité -->
        <dxi-item dataField="onlyValid"
            editorType="dxCheckBox"
            [label]="{ text: ' ' }"
            [editorOptions]="{
                text: 'En cours de validité',
                onValueChanged: setBonsAchat
            }"
        ></dxi-item>
    </dxi-item>
</dx-form>

<!-- Tableau -->
<dx-data-grid
    #datagrid id="datagrid"
    [dataSource]="bonsAchatDataSource"
    [showBorders]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [filterValue]="filterValue"
    (onRowInserting)="onRowInserting($event.data)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event.data)"
    >
    <!-- Toolbar -->
    <dxo-toolbar>
        <dxi-item>
            <div *dxTemplate>
                <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setBonsAchat()"></dx-button>
            </div>
        </dxi-item>
        <dxi-item name="exportButton"></dxi-item>
        <dxi-item name="addRowButton"></dxi-item>
    </dxo-toolbar>

    <!-- Options -->
    <dxo-editing mode="row" [allowAdding]="canAdministrerBonAchat" [allowUpdating]="canAdministrerBonAchat" [allowDeleting]="canAdministrerBonAchat"></dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true" [grouping]="true" [groupPaging]="true"></dxo-remote-operations>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-export [enabled]="true" fileName="bon achat" [allowExportSelectedData]="true"></dxo-export>
    <dxo-selection mode="multiple"></dxo-selection>

    <!-- Colonnes -->
    <dxi-column dataField="id" caption="Id" [allowEditing]="false" [width]="70" [visible]="false"></dxi-column>
    <dxi-column dataField="numero" caption="Numéro" [width]="110">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="montant" format="fixedPoint" caption="Montant" [width]="120"></dxi-column>
    <dxi-column dataField="deviseId" caption="Devise" [width]="80">
        <dxo-lookup [dataSource]="deviseDataSource" valueExpr="id" displayExpr="codeISO"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="statut" caption="Statut" [width]="100">
        <dxo-lookup [dataSource]="statutDataSource" valueExpr="id" displayExpr="libelle"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="type" caption="Type" [width]="100">
        <dxo-lookup [dataSource]="typeDataSource" valueExpr="id" displayExpr="libelle"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="dateValidite" caption="Date de validité" dataType="date" format="dd/MM/yyyy" width="150"></dxi-column>

    <dxi-column dataField="noPiece" caption="Pièce" [width]="120" [allowEditing]="false" cellTemplate="noPieceCellTemplate">
        <div *dxTemplate="let data of 'noPieceCellTemplate'">
            <a [routerLink]="'/vente/pieces/' + data?.data?.pieceId" title="Voir la pièce">
                {{data?.value}}
            </a>
        </div>
    </dxi-column>

    <dxi-column dataField="nomClientPiece" caption="Client" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="articleId" caption="Article" [visible]="false"></dxi-column>

    <dxi-column dataField="articleLibelle" caption="Article" [width]="200" [allowEditing]="false" [visible]="articleId == null" cellTemplate="articleCellTemplate">
        <div *dxTemplate="let data of 'articleCellTemplate'">
            <a [routerLink]="'/article/' + data?.data?.articleId" title="Voir l'article">
                {{data?.value}}
            </a>
        </div>
    </dxi-column>
</dx-data-grid>