import { ReferencesConnaissementService } from './modules/fret-local/connaissements/references/references-connaissement.service';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule, Configuration as ConfigurationLexiClient } from '@lexi-clients/lexi';
import { OidcUaaModule } from '@lexi/oidc-uaa';
import {
  DxButtonModule, DxDataGridModule, DxDateBoxModule, DxDropDownButtonModule,
  DxFileUploaderModule, DxFormModule, DxListModule, DxLoadIndicatorModule,
  DxMenuModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule,
  DxTemplateModule, DxTextAreaModule, DxTextBoxModule, DxToolbarModule,
  DxBoxModule, DxTreeListModule, DxPopoverModule, DxCheckBoxModule, DxRadioGroupModule, DxSwitchModule, DxNumberBoxModule
} from 'devextreme-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { PartenaireDetailComponent } from './components/partenaire-detail/partenaire-detail.component';
import { PartenaireListComponent } from './components/partenaire-list/partenaire-list.component';
import { CreationSocieteComponent } from './components/creation-societe/creation-societe.component';
import { EvenementTypeListComponent } from './components/evenement-type-list/evenement-type-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ScopeHeaderHttpInterceptor } from './interceptors/scope-header-http-interceptor';
import { LexiStorage } from './settings/lexi-storage';
import { locale, loadMessages } from 'devextreme/localization';
import { ScreenService, AppInfoService } from './shared/services';
import { SideNavOuterToolbarModule } from './layouts';
import { ModuleService } from './shared/services/module.service';
import { ErrorHttpInterceptor } from './interceptors/error-http-interceptor';
import { AuthHttpInterceptor } from './interceptors/auth-http-interceptor';
import { AppInitService } from './app-init.service';
import { SharedModule } from './modules/shared/shared.module';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ConnaissementInfoComponent } from './components/connaissement-info/connaissement-info.component';
import { Router, RouterModule } from '@angular/router';
import { TransporteurListComponent } from './components/transporteur-list/transporteur-list.component';
import { FactureListComponent } from './components/facture-list/facture-list.component';
import { ConnaissementListComponent } from './components/connaissement-list/connaissement-list.component';
import { ConditionnementListComponent } from './components/conditionnement-list/conditionnement-list.component';
import { TarifArticleListComponent } from './components/tarif-article-list/tarif-article-list.component';
import { environment } from '../environments/environment';
import { ClassificationDatagridComponent } from './components/classification-datagrid/classification-datagrid.component';
import { ClientPrixListComponent } from './components/client-prix-list/client-prix-list.component';
import { ObjectTypeService } from './shared/services/object-type.service';
import { CalculInfoPrixService } from './shared/services/calcul-info-prix.service';
import { ObjectUtilitiesService } from './shared/services/object-utilities.service';
import * as Sentry from "@sentry/angular";
import { TraceService } from '@sentry/angular';
import { DxDataSourceService } from './shared/services/dx-data-source.service';

registerLocaleData(localeFr);

declare var require: any;
const frMessages = require('devextreme/localization/messages/fr.json');

loadMessages(frMessages);
locale('fr');

@NgModule({
    declarations: [
        AppComponent,
        ArticleListComponent,
        ArticleDetailComponent,
        PartenaireListComponent,
        EvenementTypeListComponent,
        PartenaireDetailComponent,
        CreationSocieteComponent,
        SettingsComponent,
        UserProfileComponent,
        ConnaissementInfoComponent,
        TransporteurListComponent,
        FactureListComponent,
        ConnaissementListComponent,
        ConditionnementListComponent,
        TarifArticleListComponent,
        ClassificationDatagridComponent,
        ClientPrixListComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        OidcUaaModule.forRoot({
            baseServiceUrl: environment.baseServiceUrl,
            authWellKnownEndpoints: null,
            openIDConfiguration: null,
            shouldInitializeConfig: null,
        }),
        // <DevExpress>
        DxDataGridModule, DxButtonModule, DxCheckBoxModule, DxTemplateModule, DxPopupModule, DxFormModule,
        DxLoadIndicatorModule, DxToolbarModule, DxMenuModule, DxSelectBoxModule, DxDropDownButtonModule,
        DxScrollViewModule, DxDateBoxModule, DxFileUploaderModule, DxScrollViewModule, DxTextAreaModule,
        DxTextBoxModule, DxListModule, DxBoxModule, DxTreeListModule, DxPopoverModule, DxRadioGroupModule,
        DxSwitchModule, DxNumberBoxModule,
        // </DevExpress>
        HttpClientModule, ApiModule,
        FormsModule, ReactiveFormsModule,
        SideNavOuterToolbarModule,
        SharedModule,
        RouterModule
    ],
    providers: [
        DatePipe,
        {
            // Initialisation de l'application : chargement de la configuration
            // Permet d'avoir un seul build avec environnement dynamique (application multi-tenant)
            provide: APP_INITIALIZER,
            useFactory: (appInit: AppInitService) => () => {
                console.info('APP INITIALIZATION');
                return appInit.loadCrispConfiguration();
            },
            deps: [AppInitService],
            multi: true
        },
        {
            // Initialisation de Sentry
            // Chargement de la configuration depuis le fichier appsettings.json de l'API
            // Initialisation du sdk conditionnelle
            provide: APP_INITIALIZER,
            useFactory: (appInit: AppInitService) => () => {
                console.info('APP INITIALIZATION');
                return appInit.loadSentryConfigurationAndInitSdk();
            },
            deps: [AppInitService],
            multi: true
        },
        {
            // Utilise par défaut le localStorage, à l'avenir on pourrait surchager ça.
            provide: LexiStorage,
            useValue: localStorage,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ScopeHeaderHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        // Déclaration des Token Configuration des @lexi-clients
        // Utilisé pour définir le basePath des requêtes HTTP.
        // Le basePath est affecté dans AppInitService
        {
            provide: ConfigurationLexiClient,
            useValue: new ConfigurationLexiClient()
        },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        ScreenService, AppInfoService, ModuleService, ReferencesConnaissementService,
        ObjectTypeService, DxDataSourceService,
        CalculInfoPrixService, ObjectUtilitiesService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
