import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { FamilleDto } from '@lexi-clients/lexi';
import { FamilleListResult } from '../models/famille-list';

@Injectable({
  providedIn: 'root'
})
export class FamilleListService implements IGridService<FamilleDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<FamilleListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Familles/recherche-devexpress`, { params: params });
  }

  getById(familleId: number): Observable<FamilleDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Familles/${familleId}`);
  }

  post(valeur: FamilleDto): Observable<any> {
    return null;
  }

  put(valeur: FamilleDto): Observable<any> {
    return null;
  }

  delete(valeur: FamilleDto): Observable<any> {
    return null;
  }
}
