<div *ngIf="isFromSociete" style="height: 24px; background-color: #FFF3CD;">
  <span style="color: grey;">La configuration logistique ci-dessous provient de la société en cours</span>
</div>
<dx-form [formData]="logistiqueSettings" [colCount]="colCount" [readOnly]="formIsReadOnly">
  
  <dxi-item [colCount]="2" [colSpan]="2" itemType="group" caption="Édition">
    <dxi-item [colSpan]="colSpan" dataField="edition.useBackOffice" editorType="dxCheckBox" [label]="{ text: 'Utiliser le back office' }"></dxi-item>
    <dxi-item [colSpan]="1" type="empty"></dxi-item>
  </dxi-item>
  <dxi-item [colSpan]="2" type="empty"></dxi-item>
  
  <dxi-item [colCount]="1" [colSpan]="2" itemType="group" caption="Imprimantes">
    <dxi-item [colSpan]="1">
      <div *dxTemplate>
        <!-- Tableau -->
        <dx-data-grid
          *ngIf="logistiqueSettings?.edition"
          [dataSource]="logistiqueSettings.edition.imprimantes"
          [showBorders]="true"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          (onKeyDown)="onKeyDown($event)"
          >
          <!-- Toolbar -->
          <dxo-toolbar>
            <dxi-item>
              <div *dxTemplate>
                <dx-button icon="refresh" hint="Rafraîchir"></dx-button>
              </div>
            </dxi-item>
            <dxi-item name="addRowButton"></dxi-item>
          </dxo-toolbar>
        
          <!-- Options -->
          <dxo-editing mode="row" [allowAdding]="!formIsReadOnly" [allowUpdating]="!formIsReadOnly" [allowDeleting]="!formIsReadOnly" [useIcons]="true"></dxo-editing>
        
          <dxi-column dataField="intitule" caption="Intitule"></dxi-column>
          <dxi-column dataField="adresseIp" caption="Adresse IP"></dxi-column>
          <dxi-column dataField="port" caption="Port" alignment="left"></dxi-column>
          <dxi-column dataField="cheminReseau" caption="Chemin Réseau"></dxi-column>
        </dx-data-grid>
      </div>
    </dxi-item>
  </dxi-item>
  <dxi-item [colCount]="2" [colSpan]="2" itemType="group" caption="Réception">
    <dxi-item [colSpan]="1" dataField="reception.activerIntegrationBackOffice" editorType="dxCheckBox" [label]="{ text: 'Intégration back office' }"></dxi-item>
    <dxi-item [colSpan]="1" dataField="reception.defaultMouvementTypeCodeBo" [label]="{ text: 'Code mouvement type par défaut' }"></dxi-item>
  </dxi-item>

  <dxi-item [colCount]="2" [colSpan]="2" itemType="group" caption="Inventaire">
    <dxi-item [colSpan]="colSpan" dataField="inventaire.codeMouvementInventaireGeneral" [label]="{ text: 'Code mouvement inventaire général' }"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="inventaire.codeMouvementInventaireTournant" [label]="{ text: 'Code mouvement inventaire tournant' }"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="inventaire.targetFolderPath" [label]="{ text: 'Chemin' }"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="inventaire.useDirectFTP" editorType="dxCheckBox" [label]="{ text: 'Utiliser FTP' }"></dxi-item>
  </dxi-item>
  <dxi-item [colCount]="2" [colSpan]="2" itemType="group" caption="Préparation">
    <dxi-item [colSpan]="colSpan" dataField="preparation.unitesDePoids" editorType="dxTagBox" [label]="{ text: 'Unité de poids' }" [editorOptions]="{acceptCustomValue: 'true'}"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="preparation.prixConventionneCodeBo" [label]="{ text: 'Code prix conventionné' }"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="preparation.defaultMouvementTypeCodeBo" [label]="{ text: 'Code mouvement type par défaut' }"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="preparation.prixDutyCodeBo" [label]="{ text: 'Code prix duty' }"></dxi-item>
    <dxi-item [colSpan]="colSpan" dataField="preparation.zonePreparationsAvecDetailsPoids" editorType="dxTagBox" [label]="{ text: 'Zone de préparation avec détail de poids' }" [editorOptions]="{acceptCustomValue: 'true'}"></dxi-item>
  </dxi-item>
  
</dx-form>