/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PieceNature {
    aucune = 'aucune',
    facture = 'facture',
    avoir = 'avoir',
    devis = 'devis',
    bonDePreparation = 'bonDePreparation',
    commande = 'commande',
    factureFournisseur = 'factureFournisseur',
    consommationInterne = 'consommationInterne',
    reversementInterne = 'reversementInterne',
    bonDeLivraison = 'bonDeLivraison'
};

