import { Directive } from '@angular/core';
import { DxoStateStoringComponent } from 'devextreme-angular/ui/nested';
import { StorageService } from 'lexi-angular/src/app/services/storage.service';

@Directive({
  selector: '[appNoFilterStorage]'
})
export class NoFilterStorageDirective {

  constructor(
    private dxoStateStoring: DxoStateStoringComponent,
    private readonly storageService: StorageService
  ) {
    dxoStateStoring.type = "custom";
    dxoStateStoring.customLoad = () => storageService.loadDxDataGridStateWithoutFilterValue(dxoStateStoring.storageKey);
    dxoStateStoring.customSave = (state: any) => storageService.saveDxDataGridState(state, dxoStateStoring.storageKey);
  }
}
