import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FluxEtapeDto, FluxLexiClient, FluxUpdateDto, ObjectType } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-flux-etape-possible-list',
  templateUrl: './flux-etape-possible-list.component.html',
  styleUrls: ['./flux-etape-possible-list.component.scss'],
})
export class FluxEtapePossibleListComponent {
  private _currentSocieteId: number;
  get currentSocieteId(): number { return this._currentSocieteId; }
  @Input() set currentSocieteId(value: number) {
    if (value) {
      this._currentSocieteId = value;
      this.setFluxEtapesPossibles();
    }
  }

  private _currentObjectType: ObjectType;
  get currentObjectType(): ObjectType { return this._currentObjectType; }
  @Input() set currentObjectType(value: ObjectType) {
    if (value) {
      this._currentObjectType = value;
      this.setFluxEtapesPossibles();
    }
  }

  private _currentObjectId: number | string;
  get currentObjectId(): number | string { return this._currentObjectId; }
  @Input() set currentObjectId(value: number | string) {
    if (value) {
      this._currentObjectId = value;
      this.setFluxEtapesPossibles();
    }
  }

  private _currentFluxId: number;
  get currentFluxId(): number { return this._currentFluxId; }
  @Input() set currentFluxId(value: number) {
    if (value) {
      this._currentFluxId = value;
      this.setFluxEtapesPossibles();
    }
  }

  private _currentEvenementTypeId: number;
  get currentEvenementTypeId(): number { return this._currentEvenementTypeId; }
  @Input() set currentEvenementTypeId(value: number) {
    if (value) {
      this._currentEvenementTypeId = value;
      this.setFluxEtapesPossibles();
    }
  }

  @Input() interfaceActive: boolean = true;
  @Input() motifInterfaceInactive: string = '';
  @Input() currentUserId: number;
  @Input() currentFluxEtapeId: number;

  @Output() etapeClicked = new EventEmitter<FluxEtapeDto>();

  fluxEtapesPossibles: FluxEtapeDto[];
  disableButtons: boolean = false;

  constructor(private readonly fluxLexiClient: FluxLexiClient) { }

  /** OnInit */
  async setFluxEtapesPossibles() {
    if (!this.currentSocieteId || !this.currentObjectType || !this.currentObjectId
      || !this.currentFluxId || !this.currentEvenementTypeId
    ){
      return;
    }
    const etapes = await lastValueFrom(this.fluxLexiClient.getEtapesDisponibles(
      this.currentFluxId, this.currentFluxEtapeId, this.currentEvenementTypeId,
      this.currentObjectId?.toString(), this.currentObjectType
    ));
    etapes.sort((a, b) => a.zIndex - b.zIndex);
    this.fluxEtapesPossibles = etapes;
  }

  /**
   * Annonce au composant parent le clic de l'étape.
   * Lui permet d'exécuter de la logique avant d'appeler la
   * méthode updateFluxEtape()
   */
  async onClickEtape(etape: FluxEtapeDto) {
    this.etapeClicked.emit(etape);
  }

  getFluxUpdateDto(etapeId: number): FluxUpdateDto {
    return {
      societeId: this.currentSocieteId,
      objectType: this.currentObjectType,
      objectId: this.currentObjectId,
      fluxEtapeId: etapeId,
      userId: this.currentUserId,
      dateEtape: new Date().toISOString()
    };
  }

  /** Méthode à utiliser par le parent pour changer d'étape. */
  async updateFluxEtape(etape: FluxEtapeDto) {
    let success = false;
    try {
      this.disableButtons = true;
      const dto: FluxUpdateDto = this.getFluxUpdateDto(etape.id);
      await lastValueFrom(this.fluxLexiClient.updateFlux(dto));
      await this.setFluxEtapesPossibles();
      success = true;
    }
    finally {
      this.disableButtons = false;
      return success;
    }
  }

}
