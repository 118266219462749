<!-- Liste des boutons des étapes possibles -->
<div *ngIf="fluxEtapesPossibles" style="display: flex; flex-wrap: wrap; align-items: center;" [title]="motifInterfaceInactive">
  <ng-container *ngFor="let etape of fluxEtapesPossibles">
    <!-- Étape en cours -->
    <!-- <div *ngIf="etape.isCurrentEtape" class="isCurrentEtape">
      <i class="dx-icon-chevrondoubleright" style="font-size: 20px;"></i>
      <span>{{ etape.evenementTypeIntitule | uppercase }}</span>
      <i class="dx-icon-chevrondoubleleft" style="font-size: 20px;"></i>
    </div> -->

    <dx-button *ngIf="!etape.isCurrentEtape"
      style="margin-right: 12px;"
      [ngClass]="{'hasAction' : etape.action}"
      [text]="etape.evenementTypeNomAction"
      [disabled]="disableButtons || !interfaceActive"
      (onClick)="onClickEtape(etape)"
    ></dx-button>
  </ng-container>
</div>