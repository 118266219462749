<ng-container *ngIf="isAuthenticated; else unauthenticated">

  <div class="center" *ngIf="!isAuthorized">
    <h5>Vous êtes connecté en tant que : {{ authService.user?.username }}</h5>
    <h5>Malheureusement, vous n'avez pas accès à la plateforme.</h5>
    <dx-button text="Actualiser" (onClick)="refreshPage()" style="margin-right: 10px;"></dx-button>
    <dx-button text="Se déconnecter" (onClick)="authService.logout();"></dx-button>
  </div>

  <app-side-nav-outer-toolbar title="{{appInfo.title}}" *ngIf="isAuthorized">
    <router-outlet></router-outlet>
  </app-side-nav-outer-toolbar>
</ng-container>

<ng-template #unauthenticated>
  <div class="center">
    <img *ngIf="isLoading" height="183" width="198" src="assets/images/logo_anime.gif" alt="Logo Lexi" />
    <ng-container *ngIf="!isLoading">
      <div style="margin-bottom: 12px;">
        <img src="assets/images/logo_lexi_simple.png" alt="Logo Lexi"/>
      </div>
      <dx-button text="Se connecter" (onClick)="authService.login()"></dx-button>
    </ng-container>
  </div>
</ng-template>