/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum EvenementConnaissement1 {
    saisie = 'SAISIE',
    officialise = 'OFFICIALISE',
    embarque = 'EMBARQUE',
    embarquementRefuse = 'EMBARQUEMENT_REFUSE',
    annule = 'ANNULE',
    modification = 'MODIFICATION',
    transfert = 'TRANSFERT',
    brouillon = 'BROUILLON',
    demande = 'DEMANDE',
    demandeRefusee = 'DEMANDE_REFUSEE',
    officialiseSousReserve = 'OFFICIALISE_SOUS_RESERVE',
    prisEnCharge = 'PRIS_EN_CHARGE'
};

