import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { LotStockageMarchandiseDto, Permissions } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { LotStockageMarchandiseListService } from 'lexi-angular/src/app/services/lot-stockage-marchandise.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';

export class ConfigFiltreLot {
  isByPartenaire: boolean;
}

@Component({
  selector: 'app-lot-stockage-datagrid',
  templateUrl: './lot-stockage-datagrid.component.html',
  styleUrls: ['./lot-stockage-datagrid.component.scss'],
})
export class LotStockageDatagridComponent {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    this.cd.detectChanges();
  }
  
  private _initialized: boolean = false;
  get initialized(): boolean {
    return this._initialized;
  }

  @Input() set initialized(value: boolean) {
    if (value != null && value != undefined) {
      if(value) {
        this.setDataSource();
      }
    }
  }

  private _configFiltre: ConfigFiltreLot;
  get configFiltre(): ConfigFiltreLot {
    return this._configFiltre;
  }
  @Input() set configFiltre(value: ConfigFiltreLot) {
    if (value != null && value != undefined) {
      this._configFiltre = value;
    }
  }

  private _isInFicheArticle: boolean = false;
  get isInFicheArticle(): boolean {
    return this._isInFicheArticle;
  }
  @Input() set isInFicheArticle(value: boolean) {
    if (value != null && value != undefined) {
      this._isInFicheArticle = value;
    }
  }

  private _currentSiteId: number;
  get currentSiteId(): number {
    return this._currentSiteId;
  }
  @Input() set currentSiteId(value: number) {
    if (value != null && value != undefined) {
      this._currentSiteId = value;
    }
  }

  @Input() canAfficherPMP: boolean = false;

  isAffichageStockAutorise: boolean = false;
  private _articleId: number;
  get articleId(): number {
    return this._articleId;
  }
  @Input() set articleId(value: number) {
    if (value != null && value != undefined) {
      this._articleId = value;
      this.dataGridStorageKey = "grille_lot_stockage_fiche_article";
    }
  }

  @Input() dataGridMarginTop: string;

  private _prixId: number;
  get prixId(): number {
    return this._prixId;
  }
  @Input() set prixId(value: number) {
    this._prixId = value;
  }

  lotStockageStore: CustomStore;
  dataGridStorageKey = "grille_lot_stockage_globale";

  constructor(
    private readonly lotStockageMarchandiseListService: LotStockageMarchandiseListService,
    private readonly authService: AuthService,
    private readonly cd: ChangeDetectorRef,
  ) { }


  setDataSource() {
    const additionalParams = new Map().set("siteId", this.currentSiteId);
    if (this.articleId != null) {
      additionalParams.set("articleId", this.articleId);
    }
    if (this.prixId != null) {
      additionalParams.set("prixId", this.prixId);
    }
    if (this.isInFicheArticle) {
      additionalParams.set("isByPartenaire", this.configFiltre?.isByPartenaire ?? false);
    }
    const listService = this.lotStockageMarchandiseListService;
    this.isAffichageStockAutorise = this.authService.isGrantedWith(Permissions.accesVueConsultationStock);
    if (this.isAffichageStockAutorise) {
      this.lotStockageStore = new CustomStoreService(listService).getCustomStore(additionalParams);
    }
    this._initialized = true;
  }

  getLinkToArticleDetail(e: LotStockageMarchandiseDto) {
    return `/article/${e.articleId}`;
  }

  setDataSourceByPartenaire() {
    if (this.configFiltre == null) return;
    this._configFiltre.isByPartenaire = true;
    sessionStorage.setItem("grille_lot_stockage_fiche_article_by_partenaire", String(true));
    this.setDataSource();
  }

  setDataSourceBySociete() {
    if (this.configFiltre == null) return;
    this._configFiltre.isByPartenaire = false;
    sessionStorage.setItem("grille_lot_stockage_fiche_article_by_partenaire", String(false));
    this.setDataSource();
  }

  calculateQuantitePrixVente(rowData) {
    return rowData.quantite * rowData.prixVente;
  }
}
