import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ObjectType, SecurityLexiClient, TypeUtilisateur, PartenaireDto,
  SocietesLexiClient, SocieteAvecPermissionsPortailClientDto, GetSocietePourUnUtilisateurDto,
  TypeUtilisateurRuunui, ParametrageRevatuaLexiClient, Permissions
} from '@lexi-clients/lexi';
import { LexiUser, QUERY_KEY_RETURN_URL, UaaService } from '@lexi/oidc-uaa';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Site } from '../domain/site';
import { Societe } from '../domain/societe';
import { LexiStorage } from './lexi-storage';
import { Crisp } from "crisp-sdk-web";
import { AppInitService } from '../app-init.service';
import { LoginResponse } from 'angular-auth-oidc-client';

const STORE_KEY_USER = 'LX_user';
const STORE_KEY_USER_TYPE_RUUNUI = 'LX_user_type_ruunui';
const STORE_KEY_SOCIETE = 'LX_societe';
const STORE_KEY_SITE = 'LX_site';
const STORE_KEY_PARTENAIRE = 'LX_partenaire';
export const STORE_KEY_RETURN_URL = 'LX_returnUrl';
export const STORE_KEY_USER_SITES = 'LX_user_sites';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /** Ajoute un nombre pour chaque requête faite au domaine d'authentification (openIdConfiguration.authority) */
  private authRequetes: number[] = [];
  private authRequetes$: BehaviorSubject<number> = new BehaviorSubject(null);
  private societeId$: BehaviorSubject<number> = new BehaviorSubject(null);
  private currentClient$: BehaviorSubject<PartenaireDto> = new BehaviorSubject(null);
  private storedUserTypeRuunui: TypeUtilisateurRuunui = null;
  private storedUser: LexiUser = LexiUser.ANONYMOUS;
  societes: Societe[] = [];

  get currentSociete(): Societe {
    return this.societes.find(s => s.id === this.currentSocieteId);
  }

  getAuthRequetes$(): Observable<number> {
    return this.authRequetes$.asObservable();
  }

  addAuthRequetes() {
    const maxId = this.authRequetes.length ? Math.max(...this.authRequetes) : 0;
    const id = maxId + 1;
    this.authRequetes.push(id);
    this.authRequetes$.next(this.authRequetes.length);
    return id;
  }

  removeAuthRequetes(id: number) {
    this.authRequetes = this.authRequetes.filter(x => x != id);
    this.authRequetes$.next(this.authRequetes.length);
  }

  getCurClient$(): Observable<PartenaireDto> {
    return this.currentClient$.asObservable();
  }

  setCurClientObs(curClient: PartenaireDto) {
    this.currentClient$.next(curClient);
  }

  get user(): LexiUser {
    return this.uaa?.currentUser;
  }

  get currentUser$(): Observable<LexiUser> {
    return this.uaa?.currentUser$;
  }

  get checkAuthResponse$(): Observable<LoginResponse> {
    return this.uaa?.checkAuthResponse$;
  }

  get token(): Observable<string> {
    return this.uaa?.token;
  }

  get currentSocieteId(): number {
    return this.societeId$?.value;
  }

  get currentSocieteId$(): Observable<number> {
    return this.societeId$.asObservable();
  }

  private siteId$: BehaviorSubject<number> = new BehaviorSubject(null);
  sites: Site[] = [];

  get currentSite(): Site {
    return this.sites.find(s => s.id === this.currentSiteId);
  }

  get currentSiteId(): number {
    return this.siteId$?.value;
  }

  get currentSiteId$(): Observable<number> {
    return this.siteId$;
  }

  get currentPartenaireId(): number {
    return this.partenaireId ?? this.user?.partenaireId;
  }

  constructor(
    private readonly router: Router,
    private readonly uaa: UaaService,
    private readonly securityHttpClient: SecurityLexiClient,
    private readonly storage: LexiStorage,
    private readonly route: ActivatedRoute,
    private readonly societeHttpClient: SocietesLexiClient,
    private readonly appInit: AppInitService,
    private readonly parametrageRevatuaLexiClient: ParametrageRevatuaLexiClient,
  ) { }

  init(): void {
    this.initUaa();
  }

  public getStoredUser(): LexiUser {
    if (this.storedUser !== LexiUser.ANONYMOUS) {
      return this.storedUser;
    }
    try {
      const u = this.storage.getItem(STORE_KEY_USER);
      if (u) {
        const userData = JSON.parse(u);
        this.storedUser = Object.assign(new LexiUser(null, null, null, null, false, null, null, null, null, {}, null), userData);
        return this.storedUser;
      }
    } catch (e) {
      console.warn(`Erreur de récupération d'utilisateur depuis le Storage`);
    }
    return LexiUser.ANONYMOUS;
  }

  public async getStoredUserTypeRuunui(): Promise<TypeUtilisateurRuunui> {
    try {
      const u = this.storage.getItem(STORE_KEY_USER_TYPE_RUUNUI);
      if (u) {
        this.storedUserTypeRuunui = JSON.parse(u) as TypeUtilisateurRuunui;
      }
    } catch (e) {
      await this.setStoredUserTypeRuunui();
      console.warn(`Erreur de récupération d'utilisateurType Ruunui depuis le Storage`);
    }

    if (this.storedUserTypeRuunui == null) {
      await this.setStoredUserTypeRuunui();
    }
    return this.storedUserTypeRuunui;
  }

  private async initUaa() {
    const user = this.getStoredUser();
    if (user) {
      await this.uaa.init([{ id: this.getStoredSociete()?.id.toString(), type: ObjectType.societe }, {id: this.getStoredSite()?.id.toString(), type: ObjectType.site}], this.getStoredUser());
    } else {
      await this.uaa.init([]);
    }
    this.uaa.currentUser$.subscribe((u) => this.userChanged(u));
  }

  async setSocieteId(societeId: number, store = true) {
    const soc = this.societes.find(s => s.id === societeId);
    if (store) {
      if (soc == null) {
        this.storage.removeItem(STORE_KEY_SOCIETE);
      } else {
        this.storage.setItem(STORE_KEY_SOCIETE, JSON.stringify(soc));
      }
    }
    if (this.user.isAuthenticated && this.currentSocieteId !== null && this.currentSocieteId !== societeId) {
      // Recharge la page quand la Société a changé
      location.reload();
      /**
       * Alternative plus complexe...:
       * Récupérer les droits de l'utilisateur connecté quand la Société a changé
       * this.uaa.setDids([this.userService.currentSociete.did]);
       * Ensuite il faudrait que chaque composant de Page écoute l'événement pour recharger les données de la nouvelle Société
       */
    }
    this.societeId$.next(societeId);
  }

  refreshAvailableSocietesFromServer(userId: number): Promise<void> {
    if (userId == null) return;
    return lastValueFrom(this.securityHttpClient.getSocietesPourUnUtilisateur(userId)
      .pipe(
        map(dtos => {
          this.societes = dtos.map(dto => new Societe(dto));

          // L'utilisateur est relié à au moins une société
          if (this.societes.length) {
            // Récupère la Société du localStorage et la sélectionne si l'utilisateur y a accès.
            const storedSociete = this.getStoredSociete();
            if (storedSociete) {
              const societe = this.societes.find(s => s.id === storedSociete.id);
              if (societe) {
                this.setSocieteId(societe.id);
                return;
              }
            }

            // Sélectionne la 1ère Société par défaut
            this.setSocieteId(this.societes[0].id);
          }

          // L'utilisateur n'est lié à aucune société directement
          else {
            if (this.currentPartenaireId) {
              // Si l'utilisateur est lié à un partenaire, on set currentSocieteId à la société lié au partenaire
              this.societeHttpClient.getByPartenaireId(this.currentPartenaireId)
                .subscribe((societe: SocieteAvecPermissionsPortailClientDto) => {
                  if (societe) {
                    const s: GetSocietePourUnUtilisateurDto = {
                      id: societe.id,
                      intitule: societe.intitule,
                      enabled: true
                    };
                    this.societes = [new Societe(s)];
                    this.setSocieteId(societe.id);
                  }

                  else {
                    this.setSocieteId(null);
                  }
                });
            }
            else {
              this.setSocieteId(null);
            }
          }
        })
      ));
  }

  getStoredSociete(): Societe {
    const s: string = this.storage.getItem(STORE_KEY_SOCIETE);
    try {
      return s ? JSON.parse(s) : null;
    } catch (e) {
      console.warn(`Erreur de récupération de la Société depuis le Storage`);
      return null;
    }
  }

  setSiteId(siteId: number, store = true) {
    const site = this.sites.find(s => s.id === siteId);
    if (store) {
      if (site == null) {
        this.storage.removeItem(STORE_KEY_SITE);
      } else {
        this.storage.setItem(STORE_KEY_SITE, JSON.stringify(site));
      }
    }

    if (this.user.isAuthenticated() && this.currentSiteId !== null && this.currentSiteId !== siteId) {
      // Recharge la page quand le Site a changé
      location.reload();
    }

    this.siteId$.next(siteId);
  }

  get partenaireId() {
    const storedPartenaire = this.getStoredPartenaire();
    return storedPartenaire;
  }

  set partenaireId (partenaireId: number) {
    let partenaire = this.user.partenaireIds.find(p => p === partenaireId);

    if (partenaire == null) {
      this.storage.removeItem(STORE_KEY_PARTENAIRE);
    } else {
      this.storage.setItem(STORE_KEY_PARTENAIRE, JSON.stringify(partenaire));
    }
  }

  setPartenaireId(partenaireId: number, store = true) {

    if(this.partenaireId !== partenaireId) {
      this.partenaireId = partenaireId;
      location.reload();
    }

  }

  async refreshAvailableSitesFromServer(userId: number): Promise<void> {
    if (userId == null) return;
    return lastValueFrom(this.securityHttpClient.getSitesPourUnUtilisateur(userId)
      .pipe(map(dtos => {
        this.sites = (dtos.map(dto => new Site(dto))).filter(f => f.societeId == this.currentSocieteId);
        if (this.sites.length) {
          this.storage.setItem(STORE_KEY_USER_SITES, JSON.stringify(this.sites));
          const storedSite = this.getStoredSite();
          const storedUser = this.getStoredUser();
          if (storedSite) {
            const site = this.sites.find(s => s.id === storedSite.id);
            if (site) {
              // Sélectionne le Site dans le storage puis clear le storage si user possède un site par défaut,
              // permettant de redonner la priorité au Site par défaut
              this.setSiteId(site.id, false);
              if (storedUser && storedUser.siteId) {
                this.storage.removeItem(STORE_KEY_SITE);
              }
              return;
            }
          }
          if (storedUser && storedUser.siteId) {
            // Si le Site par défaut appartient aux sites de la Société actuelle
            if (this.sites.find(s => s.id === storedUser.siteId)) {
              // Sélectionne le Site par défaut de l'utilisateur
              this.setSiteId(storedUser.siteId);
              return;
            }
          }
          if (storedUser && storedUser.siteId) {
            // Sélectionne le Site par défaut de l'utilisateur
            this.setSiteId(storedUser.siteId);
            return;
          }
          if (this.user && this.user.siteId !== null) {
            // Sélectionne le Site par défaut de l'utilisateur qui vient de se connecter
            this.setSiteId(this.user.siteId);
            return;
          }
          // Sélectionne le 1er Site par défaut si l'utilisateur ou son site par défaut n'est pas trouvé
          this.setSiteId(this.sites[0].id);
          return;
        } else {
          this.setSiteId(null);
        }

      })));
  }

  public getStoredUserSites(): Site[] {
    const sites: string = this.storage.getItem(STORE_KEY_USER_SITES);
    try {
      return sites ? JSON.parse(sites) : null;
    } catch (e) {
      console.warn(`Erreur de récupération des sites utilisateur depuis le Storage`);
      return null;
    }
  }

  getStoredSite(): Site {
    const s: string = this.storage.getItem(STORE_KEY_SITE);
    try {
      return s ? JSON.parse(s) : null;
    } catch (e) {
      console.warn(`Erreur de récupération du Site depuis le Storage`);
      return null;
    }
  }

  getStoredPartenaire(): number {
    const s: string = this.storage.getItem(STORE_KEY_PARTENAIRE);
    try {
      return s ? JSON.parse(s) : null;
    } catch (e) {
      console.warn(`Erreur de récupération du Partenaire depuis le Storage`);
      return null;
    }
  }

  logout() {
    this.uaa.logout();
    this.storage.clear();
    this.router.navigate(['/']);
  }

  login() {
    // On note le return URL dans le session storage pour le récupérer après le retour
    // d'Identity Server.
    const returnUrl = this.route.snapshot.queryParamMap.get(QUERY_KEY_RETURN_URL);
    if (returnUrl != null) {
      this.storage.setItem(STORE_KEY_RETURN_URL, returnUrl);
    }

    this.uaa.authenticate([]);
    if (this.user?.id != null) {
      this.refreshAvailableSocietesFromServer(this.user.id);
      this.refreshAvailableSitesFromServer(this.user.id);
    }
  }

  async userChanged(user: LexiUser) {
     console.log("userChanged", user);
    // Si l'utilisateur est connecté
    // Et que l'utilisateur a changé ou que les Sociétés et Sites n'ont pas été chargées
    if (user?.id && !this.currentSociete && !this.currentSite) {
      // Récupère ses Sociétés et Sites affectés et sélectionne soit celle du Storage, soit la 1ère de la liste
      await this.refreshAvailableSocietesFromServer(user.id);
      await this.refreshAvailableSitesFromServer(user.id);
      const societeId = this.currentSociete?.id;
      const siteId = this.currentSite?.id;
      if( this.appInit.isCrispEnabled) {
        const utilisateur = await lastValueFrom(this.securityHttpClient.getLexiUser(user.id));
        Crisp.configure(this.appInit.crispKey);
        Crisp.chat.hide();
        Crisp.user.setNickname(utilisateur.intitule);
        if(utilisateur?.email) {
          Crisp.user.setEmail(utilisateur?.email);
        }
        if(user?.typeUtilisateur === TypeUtilisateur.partenaire) {

        } else if (societeId) {
          const societe = await lastValueFrom(this.societeHttpClient.getById(societeId));
          const site = this.sites.find(s => s.id === siteId);
          Crisp.user.setCompany(societe.intitule, {
            url: societe.siteWeb,
            description: societe.raisonSociale,
            //employment: user
            geolocation: [ societe.adresse1, societe.adresse2, societe.adresse3 ],
          });
        }
      }

      // Si l'id de la Société et/ou du Site n'est pas dans la liste des droits de l'utilisateur, récupère à nouveau ses droits
      if (societeId && siteId) {
        this.uaa.setObjects([{ id: societeId.toString(), type: ObjectType.societe }, { id: siteId.toString(), type: ObjectType.site }]);
      }
      else if (societeId) {
        this.uaa.setObjects([{ id: societeId.toString(), type: ObjectType.societe }]);
      }
      if (!societeId && user.isRoot) {
        this.router.navigate(['creation-societe']);
      } else {
        // Dans le cas ou on vient d'Identity Server, c'est le storage qui défini le return URL
        // Sinon c'est dans le query, défini par IsAuthenticatedGuard et HasAnyRoleGuard
        const paramsReturnUrl = this.route.snapshot.queryParams[QUERY_KEY_RETURN_URL];
        const storedReturnUrl = this.storage.getItem(STORE_KEY_RETURN_URL);

        let returnUrl: string;

        if (paramsReturnUrl != null) {
          returnUrl = paramsReturnUrl;
        }

        else if (storedReturnUrl != null && storedReturnUrl != "/") {
          returnUrl = storedReturnUrl;
        }

        else if(user?.typeUtilisateur == TypeUtilisateur.partenaire.toLowerCase() && !this.router.url.includes("/portail-client")){
          returnUrl = '/portail-client';
        }

        this.storage.removeItem(STORE_KEY_RETURN_URL);

        if (returnUrl != null) {
          this.router.navigate([returnUrl]);
        }
      }
    }
    this.storage.setItem(STORE_KEY_USER, JSON.stringify(user));
    this.storedUser = user;

    await this.setStoredUserTypeRuunui();
  }

  private async setStoredUserTypeRuunui() {
    this.storedUserTypeRuunui = await lastValueFrom(this.parametrageRevatuaLexiClient.recuperationUserType());
    this.storage.setItem(STORE_KEY_USER_TYPE_RUUNUI, JSON.stringify(this.storedUserTypeRuunui));
  }

  isGrantedWith(permission: Permissions) {
    return this.user.isRoot || this.user.isGrantedWith(permission);
  }
}