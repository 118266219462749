import { Component, Input, ViewChild } from '@angular/core';
import { BonAchatTpvDto, BonsAchatsTpvLexiClient, DeviseDto, DevisesLexiClient, StatutBonAchat, TypeBon, Permissions } from '@lexi-clients/lexi';
import CustomStore from 'devextreme/data/custom_store';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { BonAchatListService } from '../../../services/bon-achat.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { lastValueFrom } from 'rxjs';
import { NotificationType } from '../references/references';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-bon-achat-datagrid',
  templateUrl: './bon-achat-datagrid.component.html',
  styleUrls: ['./bon-achat-datagrid.component.scss']
})
export class BonAchatDatagridComponent {
  @ViewChild('datagrid') datagrid: DxDataGridComponent;

  @Input() articleId: number;

  bonsAchatDataSource: CustomStore;
  deviseDataSource: DeviseDto[] = [];
  statut = StatutBonAchat;
  type = TypeBon;
  dateFin: Date = null;
  dateDebut: Date = null;
  filterValue = null;

  onlyValid: boolean = false;
  canAdministrerBonAchat: boolean = false;

  statutDataSource = [
    {id: this.statut.annule, libelle: "Annulé"},
    {id: this.statut.perime, libelle: "Périmé"},
    {id: this.statut.utilise, libelle: "Utilisé"},
    {id: this.statut.valide, libelle: "Validé"}
  ]

  typeDataSource = [
    {id: this.type.achat, libelle: "Achat"},
    {id: this.type.fidelite, libelle: "Fidélité"}
  ]

  constructor(
    private readonly bonsAchatsTpvLexiClient: BonsAchatsTpvLexiClient,
    private readonly devisesLexiClient: DevisesLexiClient,
    private readonly bonAchatListService: BonAchatListService,
    private readonly authService: AuthService
  ) { }

  async ngOnInit() {
    this.canAdministrerBonAchat = this.authService.isGrantedWith(Permissions.administrationBonsAchat);

    this.setBonsAchat();
    await this.setDevise();
  }

  setBonsAchat = () => {
    const params = new Map().set("onlyValid", this.onlyValid);
    if (this.dateDebut != null) params.set("debut", this.dateDebut.toISOString());
    if (this.dateFin != null) params.set("fin", this.dateFin.toISOString());

    this.initFilters();
    this.bonsAchatDataSource = new CustomStoreService(this.bonAchatListService).getCustomStore(params);
  }

  private initFilters() {
    const filters = [];
    if (this.articleId) {
      filters.push(['articleId', '=', this.articleId]);
    }

    if (filters.length) {
      this.filterValue = filters;
    }
  }

  async setDevise() {
    this.deviseDataSource = await lastValueFrom(this.devisesLexiClient.getAll());
  }

  async onRowInserting(e) {
    const bonAchat: BonAchatTpvDto = {
      deviseId: e.deviseId,
      numero: e.numero,
      type: e.type,
      statut: e.statut,
      dateValidite: e.dateValidite,
      articleId: this.articleId ?? null
    };
    await lastValueFrom(this.bonsAchatsTpvLexiClient.create(bonAchat));
    this.setBonsAchat();
    notify({closeOnClick: true, message: "Insertion effectuée avec succès"}, NotificationType.Success);
  }

  async onRowUpdating(e) {
    const bonAchat: BonAchatTpvDto = { ...e.oldData, ...e.newData };
    await lastValueFrom(this.bonsAchatsTpvLexiClient.update(bonAchat));
    this.setBonsAchat();
    notify({closeOnClick: true, message: "Modifications effectuées avec succès"}, NotificationType.Success);
  }

  async onRowRemoving(e) {
    await lastValueFrom(this.bonsAchatsTpvLexiClient._delete(e.id));
    this.setBonsAchat();
    notify({closeOnClick: true, message: "Suppression effectuée avec succès"}, NotificationType.Success);
  }
}
