import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CreateOrUpdateDetailDeviseDto, DeviseDto, DevisesLexiClient, GetDetailDeviseDto } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { lastValueFrom } from 'rxjs';
import { NotificationType } from '../../shared/references/references';

@Component({
  selector: 'app-gestion-devises',
  templateUrl: './gestion-devises.component.html',
  styleUrls: ['./gestion-devises.component.scss'],
})
export class GestionDevisesComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  @Input() siteId: number = null;
  @Input() siteReadOnly: boolean = true;
  detailDevises: GetDetailDeviseDto[] = [];
  devises: DeviseDto[] = [];

  constructor(
    private readonly devisesService: DevisesLexiClient
  ) { }

  async ngOnInit() {
    await this.setDevisesAndDetailDevises();
  }

  async setDevisesAndDetailDevises() {
    this.devises = await lastValueFrom(this.devisesService.getAll());
    await this.setDetailDevises();
  }

  private async setDetailDevises() {
    this.detailDevises = await lastValueFrom(this.devisesService.getDetailDevises(this.siteId));
  }
  
  async onRowInserting(data: GetDetailDeviseDto) {
      const detailDevise: CreateOrUpdateDetailDeviseDto = {
        deviseId: data.deviseId,
        controleCaisse: data.controleCaisse,
        tauxChange: data.tauxChange
      };
      await lastValueFrom(this.devisesService.createOrUpdateDeviseDetail([detailDevise], this.siteId));
      await this.setDetailDevises();
      notify({closeOnClick: true, message: "Insertion effectuée avec succès"}, NotificationType.Success);
    }

  async onRowUpdating(e) {
    const detailDevise: CreateOrUpdateDetailDeviseDto = { ...e.oldData, ...e.newData };
    await lastValueFrom(this.devisesService.createOrUpdateDeviseDetail([detailDevise], this.siteId));
    await this.setDetailDevises();
    notify({closeOnClick: true, message: "Modifications effectuées avec succès"}, NotificationType.Success);
  }

  async onRowRemoving(data: GetDetailDeviseDto) {
    await lastValueFrom(this.devisesService.deleteDetailDevise(data.id));
    await this.setDetailDevises();
    notify({closeOnClick: true, message: "Suppression effectuée avec succès"}, NotificationType.Success);
  }

  async onKeyDown(e) {
    if (!this.siteReadOnly && e.event.key === "Insert") {
        this.datagrid.instance.addRow();
    }
  }
}
