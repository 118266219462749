/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ObjectType {
    societe = 'societe',
    article = 'article',
    partenaire = 'partenaire',
    site = 'site',
    materiel = 'materiel',
    activite = 'activite',
    interventionTemplate = 'interventionTemplate',
    dossier = 'dossier',
    documentLogistique = 'documentLogistique',
    facture = 'facture',
    avoir = 'avoir',
    bonCommande = 'bonCommande',
    reservationStock = 'reservationStock',
    devis = 'devis',
    utilisateur = 'utilisateur',
    reglement = 'reglement',
    declarationDouaniere = 'declarationDouaniere',
    prixRevient = 'prixRevient',
    inventaire = 'inventaire',
    ficheNavette = 'ficheNavette',
    comptaMatiere = 'comptaMatiere',
    ventesChr = 'ventesCHR',
    demande = 'demande',
    portefeuille = 'portefeuille',
    classificationValeur = 'classificationValeur',
    connaissement = 'connaissement',
    caisse = 'caisse',
    bon = 'bon',
    mouvementType = 'mouvementType',
    reglementMode = 'reglementMode',
    comptage = 'comptage',
    tarification = 'tarification',
    lieuStockage = 'lieuStockage',
    typeRemise = 'typeRemise',
    piece = 'piece',
    affranchissement = 'affranchissement',
    bonAchat = 'bonAchat',
    documentComptage = 'documentComptage',
    autre = 'autre',
    root = 'root'
};

