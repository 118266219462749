import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocietesLexiClient, SocieteToCreateDto } from '@lexi-clients/lexi';


@Component({
  selector: 'app-creation-societe',
  templateUrl: './creation-societe.component.html',
  styleUrls: ['./creation-societe.component.scss'],
})
export class CreationSocieteComponent implements OnInit {

  societeToCreate: SocieteToCreateDto;
  
  
  constructor(private societeService : SocietesLexiClient,
    private router: Router) {
    this.societeToCreate = { codeBo:'code de la société', intitule:'nom de la société'}
   }

  ngOnInit() {}

  creer() {
    this.societeService.create(this.societeToCreate)
    .subscribe(x => this.router.navigate(['']) );
  }

}
