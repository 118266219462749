import { HttpParams } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { IGridService } from '../models/igrid-service';
import { lastValueFrom } from 'rxjs';

export class CustomStoreService<T> {
  private gridService: IGridService<T>;

  constructor(gridService: IGridService<T>) {
    this.gridService = gridService;
  }

  getCustomStore(
    additionalParameters: Map<string, string> = null,
    customFilters: string[] = [],
    customStoreOptionsToOverride = {}
  ): CustomStore {
    const gridService = this.gridService;
    return new CustomStore({
      update: function (key, value): Promise<any> {
        for (const field of Object.keys(value)) {
          key[field] = value[field];
        }
        return lastValueFrom(gridService.put(key))
          .catch((error) => {
            throw new Error('Impossible de modifier cet objet');
          });
      },

      insert: function (values): Promise<any> {
        if (additionalParameters) {
          additionalParameters.forEach((v, k) => {
            values[k] = v;
          });
        }

        return lastValueFrom(gridService.post(values))
          .catch((error) => {
            throw new Error('Impossible d\'insérer cet objet');
          });
      },

      remove: function (key): Promise<void> {
        return lastValueFrom(gridService.delete(key))
          .catch((error) => {
            throw new Error('Impossible de supprimer cet objet');
          });
      },

      load: (loadOptions: any) => {
        let httpParams = new HttpParams();
        let customFilterValues: any[] = [];

        [
          'filter',
          'group',
          'groupSummary',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',
          'take',
          'totalSummary',
          'userData'
        ].forEach(function (i) {

          if (i in loadOptions && loadOptions[i] != null && loadOptions[i] != '') {

            if (customFilters?.length && i == 'filter') {
              customFilterValues = CustomStoreService.extractFilterValuesFromLoadOptions(customFilters, loadOptions);
            }

            httpParams = httpParams.set(i, JSON.stringify(loadOptions[i]));
          }
        });

        if (additionalParameters) {
          additionalParameters.forEach((v, k) => {
            httpParams = httpParams.set(k, v);
          });
        }

        return lastValueFrom(gridService.get(httpParams, ...customFilterValues))
        .then(response => {
            return {
                data: response.data,
                totalCount: response.totalCount,
                summary: response.summary,
                groupCount: response.groupCount
            };
        })
        .catch(() => { throw 'Erreur lors de la récupération des données' });
      },
      byKey: async (id: any) => {
        return lastValueFrom(gridService.getById(id))
        .then(response => {
          return response;
        })
        .catch(() => { throw 'Data loading error (By Key)' });

      },
      ...customStoreOptionsToOverride
    });
  }

  getTreeListCustomStore(additionalParameters: Map<string, string> = null): CustomStore {
    const gridService = this.gridService;
    return this.getCustomStore(additionalParameters, [], {
      key: 'id',
      update: function (key, values): Promise<any> {
        return lastValueFrom(gridService.patch(key, values))
          .catch((error) => {
            throw new Error('Impossible de modifier cet objet');
          });
      },
    });
  }

  getSelectBoxCustomStore(additionalParameters: Map<string, string> = null): CustomStore {
    const gridService = this.gridService;
    return this.getCustomStore(null, [], {
      load: async (loadOptions: any) => {
        let httpParams = new HttpParams();

        // Recherche depuis un dxSelectBox
        if(loadOptions && !loadOptions.filter && loadOptions.searchValue && loadOptions.searchExpr && loadOptions.searchOperation) {
          const filterArray = [];
          const length = loadOptions.searchExpr.length;

          for (let i=0; i < length; i++) {
            const property = loadOptions.searchExpr[i];
            filterArray.push([property, loadOptions.searchOperation, loadOptions.searchValue, {filterValue: loadOptions.searchValue}]);
            if((i+1) != length) {
              filterArray.push("or");
            }
          }

          loadOptions.filter = filterArray;
        }

        [
          'filter',
          'group',
          'groupSummary',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',
          'take',
          'totalSummary',
          'userData'
        ].forEach(function (i) {

          if (i in loadOptions && loadOptions[i] != null && loadOptions[i] != '') {
            httpParams = httpParams.set(i, JSON.stringify(loadOptions[i]));
          }
        });

        if (additionalParameters) {
          additionalParameters.forEach((v, k) => {
            httpParams = httpParams.set(k, v);
          });
        }

        return lastValueFrom(gridService.get(httpParams))
        .then(response => {
            return {
                data: response.data
            };
        })
        .catch(() => { throw 'Data loading error' });
      },
    });
  }

  stringifyDatesInFilter(crit: any) {
    crit.forEach((v, k) => {
      if (Array.isArray(v)) {
        this.stringifyDatesInFilter(v);
      } else if (Object.prototype.toString.call(v) === '[object Date]') {
        crit[k] = this.serializeDate(v);
      }
    });
  }

  zpad(text, len) {
    text = String(text);
    while (text.length < len) {
      text = "0" + text;
    }
    return text;
  }

  serializeDate(date: any) {
    const builder = [
        1 + date.getMonth(),
        "/",
        date.getDate(),
        "/",
        date.getFullYear(),
      ],
      h = date.getHours(),
      m = date.getMinutes(),
      s = date.getSeconds(),
      f = date.getMilliseconds();

    if (h + m + s + f > 0) {
      builder.push(
        " ",
        this.zpad(h, 2),
        ":",
        this.zpad(m, 2),
        ":",
        this.zpad(s, 2),
        ".",
        this.zpad(f, 3)
      );
    }

    return builder.join('');
  }

  /**
   * Extrait les filtres customs de LoadOptions et supprime le filtre de loadOptions
   * Exemple, si 'y' est dans customFilters :
   * - Filtre simple, loadOptions.filter = ['y', '=', '2']
   * => []
   * - Filtre combiné, loadOptions.filter = [ ['x', 'contains', 'abc'], 'and', ['y', '=', '2'] ]
   *  => [['x', 'contains', 'abc'], 'and']
   * - Filtre combiné++, loadOptions.filter = [ [['x', 'contains', 'abc'], 'and', ['y', '=', '2']], 'and', [['z', '=', 'e1'], 'or', ['z', '=', 'e2']] ]
   *  => [ [['x', 'contains', 'abc']], 'and', [['z', '=', 'e1'], 'or', ['z', '=', 'e2']] ]
   * @param customFilters
   * @param loadOptions
   */
  static extractFilterValuesFromLoadOptions(customFilters: string[], loadOptions: any): any[] {
    const filterValues = [];
    for (const customFilter of customFilters) {
      const value = CustomStoreService.extractFilterValueFromLoadOptions(loadOptions.filter, customFilter, loadOptions);
      // value est undefined si le filtre n'est pas dans les loadOptions.
      // on pousse tout de même la valeur undefined dans le tableau car il faut que chaque customFilters ait une valeur.
      filterValues.push(value);
    }

    return filterValues;
  }

  static extractFilterValueFromLoadOptions(filterArray: any[], customFilter: string, loadOptions: any) {
    if (filterArray.includes(customFilter)) {
      const value = filterArray[2];
      filterArray.splice(0, filterArray.length);
      return value;
    }
    else {
      let index = 0;
      for (const element of filterArray) {
        // Vérifie que la fonction includes existe sur l'element (element doit être un string ou un tableau)
        if (typeof(element?.includes) === 'function' && element.includes(customFilter)) {
          const value = element[2];
          filterArray.splice(index, 1);
          if (filterArray[index] == 'and' || filterArray[index] == 'or') {
            filterArray.splice(index, 1);
          }
          return value;
        } else if (Array.isArray(element)) {
          const value = CustomStoreService.extractFilterValueFromLoadOptions(element, customFilter, loadOptions);
          if (value != undefined) {
            for (let i=0; i < filterArray.length; i++) {
              // S'il reste la valeur [] dans le filtre, alors on retire cette valeur ainsi que le and/or suivant
              if (Array.isArray(filterArray[i]) && filterArray[i].length == 0) {
                filterArray.splice(i, 1);
                if (filterArray[i] == 'and' || filterArray[i] == 'or') {
                  filterArray.splice(i, 1);
                }
              }
            }
            return value;
          }
        }
        index++;
      }
    }
  }
}
