<div style="margin-bottom: 6px;" *ngIf="isAffichageMouvementsAutorise">
  <dx-button text="Site" (onClick)="setDataSourceByPartenaire()" [visible]="isInBonOrDocument === false && configFiltre?.isByPiece == false" [disabled]="configFiltre.isByPartenaire"></dx-button>
  <dx-button text="Société" (onClick)="setDataSourceBySociete()" [visible]="isInBonOrDocument === false && configFiltre?.isByPiece == false" [disabled]="configFiltre.isBySociete"></dx-button>
</div>

<!-- Filter builder -->
<dx-popup
  [(visible)]="isFilterPopupVisible"
  [title]="'Filtres'"
  [width]="600"
  [height]="400">

  <!-- Contenu du popup, ici on place le FilterBuilder -->
  <dx-filter-builder [fields]="fields" [(value)]="filter"></dx-filter-builder>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{
        text: 'Appliquer',
        type: 'success',
        onClick: applyFilter
    }"
  ></dxi-toolbar-item>

  <dxi-toolbar-item
        widget="dxButton"
        location="after"
        toolbar="bottom"
        [options]="{
            text: 'Annuler',
            onClick: closeFilterPopup
        }"
  ></dxi-toolbar-item>

  <!-- <dx-button text="Appliquer" (onClick)="applyFilter()"></dx-button>
  <dx-button text="Annuler" (onClick)="closeFilterPopup()"></dx-button> -->
</dx-popup>

<div *ngIf="!isAffichageMouvementsAutorise" style="padding-left: 1rem;">
  Vous n'avez pas la permission pour consulter les mouvements de stock
</div>

<dx-data-grid
  *ngIf="isAffichageMouvementsAutorise"
  [dataSource]="mouvementStockStore"
  [filterValue]="gridFilterValue"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [height]="dataGridHeight"
  (onCellPrepared)="onCellPrepared($event)"
  (onExporting)="onExporting($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="find" hint="Appliquer des filtres" (onClick)="openFilterPopup()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate location="after">
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-export [enabled]="true" fileName="mouvements-stock"></dxo-export>
  <dxo-state-storing  [enabled]="true"
    [storageKey]="dataGridStorageKey"
    appStorage
  ></dxo-state-storing>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-remote-operations [sorting]="true" [grouping]="true" [filtering]="true" [paging]="true" [groupPaging]="true"></dxo-remote-operations>

  <!-- Colonnes -->
  <dxi-column [allowGrouping]="false" dataField="id" caption="Id" [visible]="false"></dxi-column>

  <dxi-column [allowGrouping]="false" dataField="lotSourceArticleCodeBo" caption="Code article" cellTemplate="codeArticleCellTemplate" [width]="115">
    <div *dxTemplate="let data of 'codeArticleCellTemplate'">
      <a routerLink="{{getLinkToArticleDetail(data.data)}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>

  <dxi-column [allowGrouping]="true" dataField="lotSourceArticleIntitule" caption="Article" [visible]="articleId == null"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="dateOperation" caption="Date d'opération" sortOrder="desc" dataType="date" [width]="150" format="dd/MM/yyyy HH:mm:ss"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="date" caption="Date de création" [visible]="false"></dxi-column>

  <dxi-column [allowGrouping]="false" dataField="partenaireSourceIntitule" caption="Partenaire Source" [visible]="false"></dxi-column>

  <dxi-column [allowGrouping]="true" dataField="lieuStockageSourceIntitule" caption="Source" [visible]="isSourceVisible" [showInColumnChooser]="isSourceVisible" cellTemplate="lieuSourceTemplate">
    <div *dxTemplate="let data of 'lieuSourceTemplate'">
      {{ prefixeSource(data) }}{{ data.value }} ({{ data.data.quantiteSourceAvant }} <span>&#8594;</span> {{ data.data.quantiteSourceApres }})
    </div>
  </dxi-column>

  <dxi-column [allowGrouping]="false" dataField="quantite" caption="Quantité" class="qte" [width]="120"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="uniteIntitule" caption="Unité" [width]="120"></dxi-column>

  <dxi-column [allowGrouping]="false" dataField="partenaireDestinationIntitule" caption="Partenaire Destination" [visible]="false"></dxi-column>
  <dxi-column [allowGrouping]="true" dataField="lieuStockageDestinationIntitule" caption="Destination" [visible]="isDestinationVisible" [showInColumnChooser]="isDestinationVisible" cellTemplate="lieuDestinationTemplate">
    <div *dxTemplate="let data of 'lieuDestinationTemplate'">
      {{ prefixeDestination(data) }}{{ data.value }} ({{ data.data.quantiteDestinationAvant }} <span>&#8594;</span> {{ data.data.quantiteDestinationApres }})
    </div>
  </dxi-column>

  <dxi-column [allowGrouping]="false" dataField="typeCodeBo" caption="Code Mvt Type" [visible]="false"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="typeIntitule" caption="Mvt Type" [visible]="true"></dxi-column>

  <dxi-column [allowGrouping]="false" dataField="lotNumeroSerie" caption="N° série" [visible]="false"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pmpDestinationAvant" caption="Pmp lot avant" [visible]="false" *ngIf="isAffichagePMPAutorise" ></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pmpDestinationApres" caption="Pmp lot après" [visible]="false" *ngIf="isAffichagePMPAutorise"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pmpGlobal" caption="Pmp global" [visible]="false" *ngIf="isAffichagePMPAutorise"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pmpGlobalApres" caption="Nouveau pmp global" [visible]="false" *ngIf="isAffichagePMPAutorise"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="pmpSource" caption="Prix unitaire" [visible]="false" *ngIf="isAffichagePMPAutorise"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="documentType" caption="Type document" [visible]="false"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="documentReference" caption="Réf. document" [visible]="false"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="lotSourceId" caption="N° du lot" [visible]="false"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="quantiteSourceAvant" caption="Qte. Source. Avant" [visible]="false" [showInColumnChooser]="isSourceVisible"></dxi-column>
  <dxi-column [allowGrouping]="false" dataField="quantiteDestinationAvant" caption="Qte. Dest. Avant" [visible]="false" [showInColumnChooser]="isDestinationVisible"></dxi-column>

</dx-data-grid>