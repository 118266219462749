<dx-box direction="row" width="100%" crossAlign="center" class="dx-card" style="padding-right: 12px; background-color: rgb(182, 235, 182);">
  <!-- Bouton retour arrière -->
  <dxi-item [ratio]="2">
    <div *dxTemplate style="display: flex; flex-direction: row;">
      <a *ngIf="showBackButton" routerLink="/logistique/documents">
        <dx-button icon="back" [elementAttr]="{style: 'background-color: rgb(182, 235, 182);'}"></dx-button>
      </a>
      <div *ngIf="!isCreation" style="display: flex; align-items: center; margin-left: 6px;">
        <app-flux-statut-icon [statut]="document?.fluxStatut" [title]="documentStatut"></app-flux-statut-icon>
        <app-flux-etape-icon *ngIf="document?.fluxStatut" [objectType]="ObjectType.documentLogistique" [evenementTypeId]="document?.evenementTypeId" [title]="document?.evenementIntitule"></app-flux-etape-icon>
        <div style="font-size: 16px; margin-left: 6px; height: 38px; line-height: 18px; margin-top: 2px">{{ mouvementTypeIntitule }} <strong>{{ document?.id }}</strong> <br/>
          <span style="font-size: 12px;">{{document?.codeBo}}</span></div>
        <app-tooltip-date-creation [dateCreation]="document?.createdOn" [hasDateCreationDocument]="document?.createdOn != null" style="margin-left: 3px;"></app-tooltip-date-creation>
      </div>
    </div>
  </dxi-item>

  <!-- Titre -->
  <dxi-item [ratio]="4">
    <div style="display: flex; text-align: center; align-items: center; justify-content: center;">
      <span style="font-size: 24px; margin-right: 1rem;" *ngIf="!isModificationEnCours"> {{document?.intitule}} </span>
      <dx-text-box style="font-size: 24px; margin-right: 1rem;" *ngIf="isModificationEnCours && document != null" [(value)]="document.intitule"></dx-text-box>
    </div>
  </dxi-item>

  <!-- Autres champs à définir -->
  <dxi-item [ratio]="2">
    <div *dxTemplate>
      <div style="text-align: right; font-size: 1rem;" [ngStyle]="{'display': (document?.referenceExterne == null || isModificationEnCours) ? 'none' : 'block'}">
        Réf. Externe : {{ document?.referenceExterne }}
      </div>
      <div style="display: flex; justify-content: right; text-align: right;">
          <dx-form id="FormRefExterne"
            [formData]="document"
            [readOnly]="!isModificationEnCours"
            [visible]="isModificationEnCours"
            width=180
          >
              <dxi-item dataField="referenceExterne" caption="Référence externe"></dxi-item>
          </dx-form>
      </div>
    </div>
  </dxi-item>
</dx-box>