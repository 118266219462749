<div style="padding: 12px;">
  <div style="display: flex; align-items: center;">
    <h2 class="content-block">Visualisation des mouvements du stock</h2>
  </div>

  <div>
    <app-mouvement-stock-datagrid
      [configFiltre]="configFiltre"
      [currentPartenaireId]="currentPartenaireId"
      [dataGridHeight]="750"
    ></app-mouvement-stock-datagrid>
  </div>
</div>
