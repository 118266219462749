/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ProcessusEvent {
    vente = 'vente',
    annulationDeVente = 'annulationDeVente',
    facture = 'facture',
    devis = 'devis',
    bonAchat = 'bonAchat',
    specific1 = 'specific1',
    specific2 = 'specific2',
    chque = 'chèque',
    duplicataTicket = 'duplicataTicket',
    facturette = 'facturette',
    sansPrixTicket = 'sansPrixTicket',
    reglementClient = 'reglementClient'
};

