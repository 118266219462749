import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateOrUpdateOperationCommercialeDetailDto, OperationCommercialeDetailDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { OperationCommercialeDetailListResult } from '../models/operation-commerciale-detail-list';

@Injectable({ providedIn: 'root' })
export class OperationCommercialeDetailListService implements IGridService<OperationCommercialeDetailDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<OperationCommercialeDetailListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/OperationsCommercialesDetails/recherche-devexpress`, { params: params });
  }

  getById(operationCommercialeDetailId: number): Observable<OperationCommercialeDetailDto> {
    return this.http.get<any>(`${this.baseUrl}/api/OperationsCommercialesDetails/${operationCommercialeDetailId}`);
  }

  post(valeur: OperationCommercialeDetailDto): Observable<any> {
    const newOperationCommerciale: CreateOrUpdateOperationCommercialeDetailDto = {
      codeBo: valeur.codeBo,
      operationCommercialeId: valeur.operationCommercialeId,
      classificationArticleId: valeur.classificationArticleId,
      articleId: valeur.articleId,
      classificationClientId: valeur.classificationClientId,
      clientId: valeur.clientId,
      zIndex: valeur.zIndex,
      methode: valeur.methode,
      value: valeur.value,
      seuilMontant: valeur.seuilMontant,
      seuilQuantite: valeur.seuilQuantite,
      parametrageSeuils: valeur.parametrageSeuils,
    };
    return this.http.post<any>(`${this.baseUrl}/api/OperationsCommercialesDetails`, newOperationCommerciale);
  }

  put(valeur: OperationCommercialeDetailDto): Observable<any> {
    const dto: CreateOrUpdateOperationCommercialeDetailDto = {
      id: valeur.id,
      codeBo: valeur.codeBo,
      societeId: valeur.societeId,
      operationCommercialeId: valeur.operationCommercialeId,
      classificationArticleId: valeur.classificationArticleId,
      articleId: valeur.articleId,
      classificationClientId: valeur.classificationClientId,
      clientId: valeur.clientId,
      zIndex: valeur.zIndex,
      methode: valeur.methode,
      value: valeur.value,
      seuilMontant: valeur.seuilMontant,
      seuilQuantite: valeur.seuilQuantite,
      parametrageSeuils: valeur.parametrageSeuils,
    };
    return this.http.put<any>(`${this.baseUrl}/api/OperationsCommercialesDetails/${valeur.id}`, dto);
  }

  delete(valeur: OperationCommercialeDetailDto): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/api/OperationsCommercialesDetails/${valeur.id}`);
  }
}
