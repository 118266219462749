<h2 class="content-block">Votre profil</h2>

<div style="margin: 20px;">

  <!-- Update profile infos -->
  <dx-form [formData]="user" colCount="2">
    <dxi-item dataField="intitule" [label]="{text: 'Intitule'}"></dxi-item>
    <dxi-item editorType="dxTextBox" [label]="{text: 'Email'}" [editorOptions]="{ readOnly: true, value: storedUser?.email }"></dxi-item>
    <dxi-item dataField="numeroPoste" [label]="{text: 'Numéro de poste'}"></dxi-item>
    <dxi-item dataField="fonction"></dxi-item>
    <dxi-item dataField="telephoneBureau" [label]="{text: 'Téléphone bureau'}"></dxi-item>
    <dxi-item dataField="telephoneDomicile" [label]="{text: 'Téléphone domicile'}"></dxi-item>
    <dxi-item dataField="telephoneMobile" [label]="{text: 'Téléphone mobile'}"></dxi-item>
    <dxi-item dataField="defaultSiteId" [label]="{text: siteParDefautLibelle}" editorType="dxSelectBox"
      [editorOptions]="{items: sites, displayExpr: 'intitule', valueExpr: 'id', showClearButton: true}">
    </dxi-item>
    <dxi-item dataField="defaultEquipeId" [label]="{text: 'Équipe par défaut'}" editorType="dxSelectBox"
      [editorOptions]="{items: equipes, displayExpr: 'intitule', valueExpr: 'id', showClearButton: true}">
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <!-- Modifier le mot de passe -->
        <a href="{{connectDomain}}/Identity/Account/manage/changepassword" target="_blank" style="margin-right: 10px;">
          <dx-button text="Modifier mon mot de passe"></dx-button>
        </a>

        <!-- Manage connect account -->
        <a href="{{connectDomain}}/Identity/Account/manage" target="_blank">
          <dx-button text="Gérer mon compte connect"></dx-button>
        </a>
      </div>
    </dxi-item>
  </dx-form>

  <!-- Get Signature -->
  <div style="margin-top: 20px;">
    <span style="margin-right: 10px;">Supprimer votre signature électronique :</span>
    <dx-button [disabled]="!user.hasSignature" text="Supprimer ma signature" (onClick)="onSupprimerSignature()"></dx-button>
  </div>

  <!-- Update Signature -->
  <div style="margin-top: 30px;">Modifier votre signature électronique (.png, .jpeg, .jpg) :</div>
  <div style="margin-left: -8px;">
    <dx-file-uploader
      [multiple]="false"
      [(value)]="files"
      name="files[]"
      [allowedFileExtensions]="['.png', '.jpeg', '.jpg']"
      uploadMode="useForm"
      selectButtonText="Téléverser signature"
      labelText="ou Glisser/Déposer l'image"
      readyToUploadMessage=""
    ></dx-file-uploader>
  </div>

</div>

<div>
  <dx-button text="Annuler" style="margin: 0 10px 0 20px;" (onClick)="onCancel()"></dx-button>
  <dx-button text="Enregistrer" class="custom-save-btn" (onClick)="onSubmit()"></dx-button>
</div>