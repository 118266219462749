import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BonListService } from 'lexi-angular/src/app/services/bon.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';

@Component({
  selector: 'app-article-detail-bon-list',
  templateUrl: './article-detail-bon-list.component.html',
  styleUrls: ['./article-detail-bon-list.component.scss'],
})
export class ArticleDetailBonListComponent {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  
  @Input() articleId: number;
  @Input() currentPartenaireId: number;
  @Input() height: string = 'calc(100vh - 200px)';
  dataSource: CustomStore;

  constructor(
    private readonly bonListService: BonListService,
  ) { }

  async loadComponentData() {
    await this.setDataSource();
  }

  async setDataSource() {
    const additionalParams = new Map().set("articleId", this.articleId);
    this.dataSource = new CustomStoreService(this.bonListService).getCustomStore(additionalParams);
  }

  refreshDataGrid() {
    this.dataGrid?.instance.refresh();
  }

  clearDatagridFilters() {
    this.dataGrid.instance.clearFilter();
  }
}
