import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MouvementSens, FluxStatut, FluxEtapeDto, EvenementTypesLexiClient, EvenementTypeDto } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BonListService } from 'lexi-angular/src/app/services/bon.service';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { lastValueFrom } from 'rxjs';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { formatDate } from '@angular/common';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-bon-list-globale-datagrid',
  templateUrl: './bon-list-globale-datagrid.component.html',
  styleUrls: ['./bon-list-globale-datagrid.component.scss'],
})
export class BonListGlobaleDatagridComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  @Input() fluxEtapeDataSource: FluxEtapeDto[];
  @Input() societeIsFondAutorise = false;
  @Input() currentPartenaireId: number;
  _includeInfosCalculees: boolean = false;
  get includeInfosCalculees() { return this._includeInfosCalculees; }
  @Input() set includeInfosCalculees(value: boolean) {
    this._includeInfosCalculees = value;
    if (value != null) {
      this.setDataSource();
    }
  }

  showMarchandisesLegende = false;
  showreferencesLegende = false;

  bonStore: CustomStore;
  MouvementSens = MouvementSens;
  evenementTypes: EvenementTypeDto[];
  fluxStatutDataSource = [
    { id: FluxStatut.new, intitule: "Brouillon" },
    { id: FluxStatut.paused, intitule: "En attente" },
    { id: FluxStatut.opened, intitule: "En cours" },
    { id: FluxStatut.closed, intitule: "Finalisé" },
  ];

  constructor(
    private readonly bonListService: BonListService,
    private readonly evenementTypeLexiClient: EvenementTypesLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
  ) {
    this.setDataSource();
  }

  setDataSource() {
    const params = new Map().set("includeInfosCalculees", this.includeInfosCalculees);
    this.bonStore = this.dxDataSourceService.getCustomStore(this.bonListService, params);
  }

  async ngOnInit() {
    await this.setEvenementTypes();
  }

  async setEvenementTypes() {
    const evenementTypes = await lastValueFrom(this.evenementTypeLexiClient.getAll());
    this.evenementTypes = evenementTypes.sort((a, b) => a.intitule && b.intitule ? a.intitule.localeCompare(b.intitule) : 0);
  }

  refreshDataGrid() {
    this.dataGrid?.instance.refresh();
  }

  clearDatagridFilters() {
    this.dataGrid.instance.clearFilter();
  }

  filterColumnHeader(column: string, values: any) {
    this.dataGrid.instance.columnOption(column, 'filterType', 'include');
    this.dataGrid.instance.columnOption(column, 'filterValues', values);
    this.refreshDataGrid();
  }

  resetFilterColumnHeader(column: string) {
    this.dataGrid.instance.columnOption(column, 'selectedFilterOperation', null);
    this.dataGrid.instance.columnOption(column, 'filterValues', null);
    this.refreshDataGrid();
  }

  filterColumn(column: string, filterOperation: string, value: any) {
    this.dataGrid?.instance.columnOption(column, 'selectedFilterOperation', filterOperation);
    this.dataGrid?.instance.columnOption(column, 'filterValue', value);
    this.refreshDataGrid();
  }

  customizeText(cellInfo: { valueText: string }) {
    return cellInfo?.valueText ? cellInfo.valueText : "—";
  }

  getEtapeLogo(evenementTypeId: number) {
    return evenementTypeId
      ? 'data:image/png;base64,' + this.fluxEtapeDataSource.find(x => x.evenementTypeId == evenementTypeId)?.evenementTypeLogo
      : "assets/images/Discount.png";
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Bons');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {

          // Marchandises
          if (gridCell.column.caption === 'Marchandises' && gridCell.value == null) {
            excelCell.value = `${gridCell.data.nombreMarchandisesEncours ?? 0} / ${gridCell.data.nombreMarchandisesMouvementees ?? 0} / ${gridCell.data.nombreMarchandises ?? 0}`;
          }

          // Références
          if (gridCell.column.caption === 'Réf. mouvementées' && gridCell.value == null) {
            excelCell.value = `${gridCell.data.nombreReferencesMouvementees ?? 0} / ${gridCell.data.nombreReferences ?? 0}`;
          }

          // Source
          if (gridCell.column.caption === 'Source' && gridCell.value == null) {
            excelCell.value = this.currentPartenaireId == gridCell.data.partenaireSourceId
              ? gridCell.data.sourceStockageIntitule
              : gridCell.data.partenaireSourceIntitule;
          }

          // Destination
          if (gridCell.column.caption === 'Destination' && gridCell.value == null) {
            excelCell.value = this.currentPartenaireId == gridCell.data.partenaireDestinationId
              ? gridCell.data.destinationStockageIntitule
              : gridCell.data.partenaireDestinationIntitule;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-liste-des-bons.xlsx`);
      });
    });
  }
}
