import { Component, Input, OnDestroy } from '@angular/core';
import { GetNoteResult, ListSortDirection, NotesLexiClient, ObjectType } from '@lexi-clients/lexi';
import { LexiUser } from '@lexi/oidc-uaa';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { Subscription, filter, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnDestroy {
  private subscriptions = new Subscription();

  private _objectType: ObjectType;
  get objectType(): ObjectType { return this._objectType; }
  @Input() set objectType(value: ObjectType) {
      this._objectType = value;
      this.loadDataSource();
  }

  private _objectId: string;
  get objectId(): string { return this._objectId; }
  @Input() set objectId(value: string) {
      this._objectId = value;
      this.loadDataSource();
  }

  dataSource: GetNoteResult[];
  currentUserId: number;
  cardClass: string = "dx-card custom-card";
  containerClass: string = "notes-container";

  constructor(
    private readonly notesLexiClient: NotesLexiClient,
    private readonly authService: AuthService,
  ) {
    this.setCurrentUserId();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadDataSource() {
    if (this.objectType == null || this.objectId == null) return;
    this.dataSource = await lastValueFrom(
      this.notesLexiClient.getNotes(this.objectType, this.objectId, ListSortDirection.ascending)
    );
  }

  private setCurrentUserId() {
    this.subscriptions.add(
      this.authService.currentUser$
        .pipe(filter((user: LexiUser) => user != null))
        .subscribe((user: LexiUser) => (this.currentUserId = user.id))
    );
  }
}
