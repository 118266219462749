import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../settings/auth.service';
import { Permissions } from '@lexi-clients/lexi';
import { OidcUaaConfig, QUERY_KEY_RETURN_URL } from '@lexi/oidc-uaa';

@Injectable({
  providedIn: 'root'
})
export class CanAccessModuleVenteGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router, 
    private config: OidcUaaConfig
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isGrantedWith(Permissions.canAccederModuleVente)) {
      return true
    }

    this.router.navigate([this.config.openIDConfiguration.forbiddenRoute], { queryParams: { [QUERY_KEY_RETURN_URL]: state.url }});
    return false;
  }
  
}
